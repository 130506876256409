import React from "react";
import { Space, Tag } from "antd";

interface ESGScoreProps {
    score: number;
}

const scoreColorInterval = [
    {
        max: 101,
        min: 80,
        color: "success",
    },
    {
        max: 80,
        min: 60,
        color: "warning",
    },
    {
        max: 60,
        min: 0,
        color: "error",
    },
];

const getScoreColor = (score: number) => {
    return scoreColorInterval.find((item) => item.max > score && item.min <= score)?.color;
};

export const ESGScore: React.FC<ESGScoreProps> = ({ score }) => {
    const color = getScoreColor(score);

    return (
        <Tag color={color} data-testid="esg-score">
            <Space size={2}>
                <span>{score}</span>
                <span>/</span>
                <span>100</span>
            </Space>
        </Tag>
    );
};
