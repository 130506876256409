import {
    message,
    Card,
    Badge,
    Input,
    Button,
    Row,
    Col,
    Skeleton,
    Form,
    Checkbox,
    DatePicker,
    InputNumber,
    Spin,
    Space,
    Select,
} from "antd";
import React, { useState, useEffect } from "react";
import OrderFormLayout from "./OrderFormLayout";
import { DATE_FORMAT, momentDate } from "../date/DateOp";
import { useParams } from "react-router-dom";
import { AuditOutlined, MessageOutlined } from "@ant-design/icons";
import { getOrderForm, updateOrderForm } from "../../common/api/OrderFormClient";
import { DealRating, OrderForm, OrderFormStatus } from "../../types/OrderFormTypes";
import { ResourceType } from "../../types/ResourceType";
import { TagPanel } from "../tag/TagPanel";
import { canWrite } from "../../types/AccessControlTypes";
import NumberFormat from "react-number-format";
import { VendorInput } from "../vendor/VendorInput";
import { CommentsPanel } from "../comments/CommentsPanel";
import { handleError } from "../../common/ErrorHandling";
import { OrderFormStatusPanel } from "./OrderFormStatusPanel";
import { shallowEqual } from "../../common/Objects";
import { NofiticationResourceSettingsPanel } from "../notifications/NofiticationResourceSettingsPanel";
import SkuPanel from "./components/SkuPanel";
import UserLinePanel from "../user/components/UserLinePanel";
import { User } from "../../types/UserGroup";
import { OrderFormGroups } from "./OrderFormGroups";
import { getPdfFile } from "./../../common/api/OrderFormClient";

const OrderFormDetailsView: React.FC = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [orderForm, setOrderForm] = useState({} as OrderForm);
    const [orderFormWorkingCopy, setOrderFormWorkingCopy] = useState({} as OrderForm);
    const [orderFormId] = useState(Number(params.orderFormId) || 0);
    const [saveLoading, setSaveLoading] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const [, setStatusLocked] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [reloadNotifications, setReloadNotification] = useState(0);

    useEffect(() => {
        setLoading(true);
        getOrderForm(orderFormId)
            .then((order) => {
                const _readOnly = !canWrite(order.access);
                setReadOnly(_readOnly);
                setOrderFormWorkingCopy(JSON.parse(JSON.stringify(order.orderForm)));
                setOrderForm(order.orderForm);
            })
            .catch((error) => {
                handleError(error);
                setHasError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const _statusLocked =
            orderForm.status === OrderFormStatus.RENEWED ||
            orderForm.status === OrderFormStatus.WILL_NOT_RENEW;
        setStatusLocked(_statusLocked);
        setDisabled(readOnly || _statusLocked);
    }, [orderForm]);

    if (hasError) {
        //TODO, do something when there is an error
        return <></>;
    }

    const updateAttribute = (
        attribute: string,
        value: string | number | boolean | Date | undefined
    ) => {
        orderFormWorkingCopy[attribute] = value;
        setOrderFormWorkingCopy({ ...orderFormWorkingCopy });
    };

    const changeMarkers = (attribute: string, label: string) => {
        let dot = orderFormWorkingCopy[attribute] !== orderForm[attribute];
        if (
            orderFormWorkingCopy[attribute] instanceof Date &&
            orderForm[attribute] instanceof Date
        ) {
            dot = orderFormWorkingCopy[attribute].getTime() === orderForm[attribute].getTime();
        }
        return <Badge dot={dot}>{label}</Badge>;
    };

    const messagesDrawer = (
        <>
            <CommentsPanel
                resourceId={orderFormId}
                visible={showMessages}
                resourceType={ResourceType.OrderForm}
                onClose={() => setShowMessages(false)}
            />
        </>
    );

    const onClickForm = async () => {
        // const uri = `/app/orderform-document/${orderFormId}`;
        const res: any = await getPdfFile(orderFormId);
        if (res && "url" in res) {
            window.open(res.url, "_blank");
        }
    };

    const toolbox = (
        <>
            <Space size={10}>
                <Button size={"small"} onClick={onClickForm}>
                    <AuditOutlined />
                </Button>

                <Button
                    size={"small"}
                    onClick={() => {
                        setShowMessages(true);
                    }}
                >
                    <MessageOutlined />
                </Button>
            </Space>
        </>
    );

    const toolPanel = (
        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
            <Row justify="start" align="middle">
                <Col span={24}>
                    <TagPanel
                        resourceId={orderFormId}
                        resourceType={ResourceType.OrderForm}
                        readOnly={disabled}
                    />
                </Col>
            </Row>
        </div>
    );

    const hasChanges = !shallowEqual(orderForm, orderFormWorkingCopy);

    const handleUpdate = () => {
        setSaveLoading(true);
        updateOrderForm(orderFormWorkingCopy)
            .then((updateOrderForm) => {
                setOrderForm({ ...JSON.parse(JSON.stringify(updateOrderForm)) });
                setOrderFormWorkingCopy({ ...JSON.parse(JSON.stringify(updateOrderForm)) });
                setReloadNotification(reloadNotifications + 1);
            })
            .catch((error) => {
                if (error.isILError) {
                    if (error.code === 700) {
                        message.warning(error.misc);
                    } else {
                        message.error(error.message);
                    }
                } else {
                    message.error(JSON.stringify(error));
                }
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    const handleStatusUpdate = (ofStatus: OrderFormStatus) => {
        setSaveLoading(true);

        const nOF = JSON.parse(JSON.stringify(orderForm)) as OrderForm;
        nOF.status = ofStatus;

        updateOrderForm(nOF)
            .then(() => {
                orderForm.status = ofStatus;
                orderFormWorkingCopy.status = ofStatus;
                setOrderForm({ ...orderFormWorkingCopy });
                setOrderFormWorkingCopy({ ...orderFormWorkingCopy });
            })
            .catch((error) => {
                if (error.isILError) {
                    if (error.code === 700) {
                        message.warning(error.misc);
                    } else {
                        message.error(error.message);
                    }
                } else {
                    message.error(JSON.stringify(error));
                }
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    const documentPanel = (
        <Card>
            <Spin spinning={saveLoading}>
                <Form>
                    <Row key="bodyPanel0"></Row>
                    <Row key="bodyPanel1">
                        <Col span={11}>
                            <Form.Item label={changeMarkers("name", "Name")}>
                                <Input
                                    value={orderFormWorkingCopy.name}
                                    onChange={(e) => {
                                        updateAttribute("name", e.target.value);
                                    }}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <OrderFormStatusPanel
                                disabled={readOnly}
                                order={orderFormWorkingCopy}
                                onUpdate={(status) => {
                                    handleStatusUpdate(status);
                                }}
                            />
                        </Col>
                        {/* <Col span={8}></Col> */}
                    </Row>
                    <Row key="bodyPanel2">
                        <Col span={11}>
                            <Form.Item label={changeMarkers("expirationDate", "Expiration Date")}>
                                <DatePicker
                                    value={
                                        orderFormWorkingCopy.expirationDate
                                            ? momentDate(orderFormWorkingCopy.expirationDate)
                                            : undefined
                                    }
                                    disabled={disabled}
                                    allowClear={false}
                                    onChange={(date) => {
                                        updateAttribute("expirationDate", date?.toDate());
                                    }}
                                    format={DATE_FORMAT}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                label={changeMarkers("businessOwnerEmail", "Business Owner email")}
                            >
                                <Input
                                    style={{ width: "100%" }}
                                    value={orderFormWorkingCopy.businessOwnerEmail}
                                    onChange={(e) => {
                                        updateAttribute("businessOwnerEmail", e.target.value);
                                    }}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row key="bodyPanel3">
                        <Col span={11}>
                            <Form.Item label={changeMarkers("vendorKey", "Vendor")}>
                                <VendorInput
                                    value={orderFormWorkingCopy.vendorKey}
                                    disabled={disabled}
                                    onSelect={(value) => {
                                        updateAttribute("vendorKey", value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item label={changeMarkers("ownerId", "Owner")}>
                                <UserLinePanel
                                    userId={orderFormWorkingCopy.ownerId}
                                    enableClickSelect={true}
                                    onSelect={(user: User) => {
                                        updateAttribute("ownerId", user.id);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row key="bodyPanel4">
                        <Col span={11}>
                            <Form.Item label={changeMarkers("value", "Value")}>
                                <NumberFormat
                                    className={"ant-input"}
                                    value={orderFormWorkingCopy.value}
                                    disabled={disabled}
                                    thousandSeparator={true}
                                    displayType={"input"}
                                    prefix={"$"}
                                    onValueChange={(value) => {
                                        updateAttribute("value", value.floatValue);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Checkbox
                                checked={Boolean(orderFormWorkingCopy.autoRenewal)}
                                disabled={disabled}
                                onChange={(e) => {
                                    updateAttribute("autoRenewal", e.target.checked);
                                }}
                            >
                                {changeMarkers("autoRenewal", "Auto Renews")}
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row key="bodyPanel4">
                        <Col span={11}>
                            <Form.Item label={changeMarkers("orderStartDate", "Order Term")}>
                                <Input.Group compact>
                                    <DatePicker.RangePicker
                                        // style={{ width: '70%' }}
                                        disabled={disabled}
                                        allowEmpty={[false, false]}
                                        allowClear={false}
                                        value={
                                            orderFormWorkingCopy.orderStartDate
                                                ? [
                                                      momentDate(
                                                          orderFormWorkingCopy.orderStartDate
                                                      ),
                                                      momentDate(
                                                          orderFormWorkingCopy.orderEndDate ||
                                                              undefined
                                                      ),
                                                  ]
                                                : ([] as any)
                                        }
                                        format={DATE_FORMAT}
                                        onCalendarChange={(dates) => {
                                            if (dates !== undefined && dates!.length > 1) {
                                                updateAttribute(
                                                    "orderStartDate",
                                                    dates?.[0]?.toDate()
                                                );
                                                updateAttribute(
                                                    "orderEndDate",
                                                    dates?.[1]?.toDate()
                                                );
                                            }
                                        }}
                                    />
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                label={changeMarkers("migrationLength", "Migration Length in days")}
                            >
                                <InputNumber
                                    value={orderFormWorkingCopy.migrationLength}
                                    disabled={disabled}
                                    onChange={(value) => {
                                        updateAttribute("migrationLength", value as number);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row key="bodyPanel5">
                        <Col span={11}>
                            <Form.Item label={changeMarkers("category", "Category")}>
                                <Input
                                    value={orderFormWorkingCopy.category}
                                    onChange={(e) => {
                                        updateAttribute("category", e.target.value);
                                    }}
                                    disabled={disabled}
                                />
                                <Input.Group compact></Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item label={changeMarkers("dealRating", "Deal rating (score)")}>
                                <Select
                                    value={orderFormWorkingCopy.dealRating}
                                    onSelect={(v: any) => {
                                        updateAttribute("dealRating", v);
                                    }}
                                >
                                    {Object.values(DealRating)
                                        .filter((dr) => !isNaN(parseInt(dr.toString())))
                                        .map((dr) => {
                                            return (
                                                <Select.Option value={dr}>
                                                    {DealRating[dr as number]}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row key="buttonPanelAction">
                        <Col span={24}>
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    danger
                                    disabled={!hasChanges}
                                    onClick={() => {
                                        setOrderFormWorkingCopy({
                                            ...JSON.parse(JSON.stringify(orderForm)),
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                                &nbsp;
                                <Button
                                    type="primary"
                                    disabled={!hasChanges}
                                    onClick={() => {
                                        handleUpdate();
                                    }}
                                >
                                    Update
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Card>
    );

    const content = (
        <>
            {messagesDrawer}
            <Row>
                <Col span={24}>{toolPanel}</Col>
            </Row>

            <Row gutter={[8, 10]}>
                <Col span={17}>
                    <Row>
                        <Col span={24}>
                            <Skeleton loading={loading}>{documentPanel}</Skeleton>
                        </Col>
                    </Row>
                </Col>
                <Col span={7}>
                    <Skeleton loading={loading}>
                        <NofiticationResourceSettingsPanel
                            resourceId={orderFormId}
                            resourceType={ResourceType.OrderForm}
                            disabled={disabled}
                        />
                    </Skeleton>
                </Col>
            </Row>
            <Row gutter={[8, 10]}>
                <Col span={24}>{/* <Divider orientation={"left"}>Notifications</Divider> */}</Col>
                <Col span={24}>{/* <Divider orientation={"left"}>Notifications</Divider> */}</Col>
            </Row>
            <Row gutter={[8, 10]}>
                <Col span={17}>
                    <SkuPanel orderFormId={orderFormId} />
                </Col>
                <Col span={7}>
                    <OrderFormGroups orderFormId={orderFormId} disabled={disabled} />
                </Col>
            </Row>
        </>
    );

    return (
        <OrderFormLayout
            currentPath={[
                {
                    name: "Order Form",
                    path: "/app/order/new",
                },
                {
                    name: `${orderForm.name || ""}`,
                    path: `/app/order/${orderForm.id}`,
                },
            ]}
            contentPanel={content}
            toolbox={toolbox}
        />
    );
};

export default OrderFormDetailsView;
