import React from "react";
import { SupplierBusinessDriver } from "../../../types/SupplierTypes";

interface BusinessDriversProps {
    drivers: SupplierBusinessDriver[];
}

export const BusinessDrivers: React.FC<BusinessDriversProps> = ({ drivers }) => {
    return (
        <ul style={{ marginTop: 8, color: "#666", paddingLeft: "1rem" }}>
            {drivers.map((item) => (
                <li key={item.id}>
                    <span style={{ fontWeight: 500 }}>{item.name}: </span>
                    {item.description}
                </li>
            ))}
        </ul>
    );
};
