import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "antd";
import NumberFormat from "react-number-format";
import { Budget } from "../../../types/DashboardTypes";

interface BudgetSettingModalProps {
    open: boolean;
    budget: Budget;
    onCancel?: () => void;
    onOk?: (params: Budget) => void;
}

export const BudgetSettingModal: React.FC<BudgetSettingModalProps> = ({
    budget,
    open,
    onCancel,
    onOk,
}) => {
    const [form, setForm] = useState({} as Budget);

    useEffect(() => {
        setForm(budget);
    }, [budget]);

    return (
        <Modal
            title="Budget Setting"
            open={open}
            onCancel={onCancel}
            footer={
                <>
                    <Button onClick={() => onCancel?.()}>Cancel</Button>
                    <Button onClick={() => onOk?.(form)} type="primary">
                        Confirm
                    </Button>
                </>
            }
        >
            <Form>
                <Form.Item label={budget.lastYearLabel}>
                    <NumberFormat
                        className={"ant-input"}
                        value={form.lastYearBudget}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={"$"}
                        onValueChange={(value) => {
                            const budget = value.floatValue || 0;
                            setForm({ ...form, lastYearBudget: budget });
                        }}
                    />
                </Form.Item>
                <Form.Item label={budget.currentYearLabel}>
                    <NumberFormat
                        className={"ant-input"}
                        value={form.currentYearBudget}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={"$"}
                        onValueChange={(value) => {
                            const budget = value.floatValue || 0;
                            setForm({ ...form, currentYearBudget: budget });
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
