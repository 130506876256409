import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from "antd";
import MainLayout from "../navigation/MainLayout";
import {
    SupplierFullCompetitor,
    SupplierInsight,
    SupplierWithEmployee,
} from "../../types/SupplierTypes";
import {
    getSupplierById,
    getSupplierCompetitors,
    getSupplierInsights,
} from "../../common/api/SupplierClient";
import { getFinancialsBySymbol, getInsightsBySymbol } from "../../common/api/FinanceClient";
import { formatDateString } from "../date/DateOp";
import { SupplierDetailTopPanel } from "./SupplierDetailTopPanel";
import {
    FinancialOverviewOption,
    FinancialOverviewData,
    SupplierDetailLeftPanel,
} from "./SupplierDetailLeftPanel";
import { SupplierDetailRightPanel } from "./SupplierDetailRightPanel";
import { FinancialNewsCardProps } from "./components/FinancialNewsCard";
import { usePDF } from "react-to-pdf";
import { handleError } from "../../common/ErrorHandling";
import { FinancialsIncomeStatement } from "../../types/FinanceTypes";
import { PdfPage } from "../../common/components/PdfPage";

const getAnnuallyFinancialHistory = (history?: FinancialsIncomeStatement[]) => {
    if (!history?.length) return [];

    return [...history].sort((a, b) => Number(a.endDate?.raw) - Number(b.endDate?.raw)).slice(1);
};

const getQuarterlyFinancialHistory = (
    history?: FinancialsIncomeStatement[],
    previousYearData?: FinancialsIncomeStatement
) => {
    if (!history?.length) return [];

    const sorted = [...history].sort((a, b) => Number(a.endDate?.raw) - Number(b.endDate?.raw));

    if (previousYearData) {
        sorted.push(previousYearData);
    }
    return sorted;
};

export const SupplierDetailView: React.FC = () => {
    const params = useParams();
    const [supplierId] = useState(Number(params.id));
    const [supplierProfie, setSupplierProfile] = useState({} as SupplierWithEmployee);
    const [supplierCompetitors, setSupplierCompetitors] = useState([] as SupplierFullCompetitor[]);
    const [supplierInsights, setSupplierInsights] = useState([] as SupplierInsight[]);
    const [finanacialOverviewOption, setFinancialOverviewOption] = useState(
        FinancialOverviewOption.Annually
    );
    const [supplierFinancials, setSupplierFinancials] = useState({} as FinancialOverviewData);
    const [supplierNews, setSupplierNews] = useState({} as FinancialNewsCardProps);
    const [creating, setCreating] = useState(false);
    const { toPDF, targetRef } = usePDF({});

    const createPdf = async () => {
        setCreating(true);
        try {
            await toPDF();
        } catch (error) {
            handleError(error);
        } finally {
            setCreating(false);
        }
    };

    const refreshSupplier = async (id: number) => {
        const [profile, competitors, insights] = await Promise.all([
            getSupplierById(id),
            getSupplierCompetitors(id),
            getSupplierInsights(id),
        ]);
        setSupplierProfile(profile);
        setSupplierCompetitors(competitors);
        setSupplierInsights(insights);

        if (profile.symbol) {
            try {
                const [financials, news] = await Promise.all([
                    getFinancialsBySymbol(profile.symbol),
                    getInsightsBySymbol(profile.symbol),
                ]);
                setSupplierFinancials({
                    annually: getAnnuallyFinancialHistory(
                        financials.incomeStatementHistory.incomeStatementHistory
                    ),
                    quarterly: getQuarterlyFinancialHistory(
                        financials.incomeStatementHistoryQuarterly.incomeStatementHistory,
                        financials.incomeStatementHistory.incomeStatementHistory[0]
                    ),
                });

                setSupplierNews({
                    price: financials.price.regularMarketPrice.raw || 0,
                    changePercentage: financials.price.regularMarketChangePercent.raw || 0,
                    news: news.finance.result.secReports?.slice(0, 5).map((item) => ({
                        title: item.title,
                        date: formatDateString(new Date(item.filingDate)),
                        url: item.snapshotUrl,
                    })),
                });
            } catch (error) {
                handleError(error);
            }
        }
    };

    useEffect(() => {
        refreshSupplier(supplierId);
    }, [supplierId]);

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <Card>
                <SupplierDetailTopPanel
                    supplierName={supplierProfie.name}
                    supplierId={supplierId}
                />
                <div ref={targetRef} style={{ position: "fixed", left: "-1000rem" }}>
                    <PdfPage page={1} total={2}>
                        <SupplierDetailLeftPanel
                            profile={supplierProfie}
                            financials={supplierFinancials}
                            competitors={supplierCompetitors}
                            showOverviewToggle={false}
                            overviewOption={finanacialOverviewOption}
                        />
                    </PdfPage>
                    <PdfPage page={2} total={2}>
                        <SupplierDetailRightPanel
                            insights={supplierInsights}
                            financialNewsOptions={supplierNews}
                            onCreate={createPdf}
                            loading={creating}
                            showPdfButton={false}
                        />
                    </PdfPage>
                </div>
                <Row gutter={16} style={{ marginTop: 10 }}>
                    <Col span={18}>
                        <SupplierDetailLeftPanel
                            profile={supplierProfie}
                            financials={supplierFinancials}
                            competitors={supplierCompetitors}
                            overviewOption={finanacialOverviewOption}
                            onOverviewChange={setFinancialOverviewOption}
                        />
                    </Col>
                    <Col span={6}>
                        <SupplierDetailRightPanel
                            insights={supplierInsights}
                            financialNewsOptions={supplierNews}
                            onCreate={createPdf}
                            loading={creating}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"SupplierSheets"} collapsed={true} />;
};
