import { AutoComplete, Button, Form, Modal, Spin } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useState } from "react";
import { loadUserGroupSuggestion } from "../../common/api/UserClient";
import Avatar from "../../common/components/Avatar";
import { Group, User, UserRole } from "../../types/UserGroup";

interface UserGroupSelectionModalArgument {
    onSelect: (selection: User | Group) => void;
    type?: "u" | "g" | "ug";
    blackList?: any[];
    roleFilter?: UserRole[];
    onCancel?: () => void;
}

export const UserGroupSelectionModal: React.FC<UserGroupSelectionModalArgument> = ({
    onSelect,
    blackList,
    onCancel,
    type = "ug",
    roleFilter,
}) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(new Map<string, any>());
    const bl = blackList || [];

    const renderUserGroup = (v: any) => {
        if (v.email) {
            return (
                <>
                    <Avatar email={v.email} />
                    <span style={{ paddingLeft: 10 }}>
                        {v.name} ({v.email})
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <Avatar email={v.name} />
                    <span style={{ paddingLeft: 10 }}>
                        {v.name} ({v.id})
                    </span>
                </>
            );
        }
    };

    const renderKey = (v: any) => {
        return v.email ? `${v.name} (${v.email})` : `${v.name} (${v.id})`;
    };

    const searchUserGroup = (query: string) => {
        loadUserGroupSuggestion(query, type).then((suggestions: any[]) => {
            setOptions(
                new Map<string, any>(
                    suggestions
                        .filter((v) => {
                            return (
                                bl.filter((u) => u.id === v.id && u.type === v.type).length === 0 &&
                                (roleFilter !== undefined
                                    ? (v as User).roles.filter((r) => roleFilter.indexOf(r) !== -1)
                                          .length > 0
                                    : true)
                            );
                        })
                        .map((v) => [renderKey(v), { type: v.email ? "user" : "group", value: v }])
                )
            );
        });
    };

    const modal = (
        <Modal
            title={
                type === "ug"
                    ? "Select User or Group"
                    : type === "u"
                    ? "Select User"
                    : "Select Group"
            }
            visible={true}
            footer={[
                <Button key="Cancel" onClick={onCancel}>
                    Return
                </Button>,
            ]}
            onCancel={() => {
                if (onCancel !== undefined && !loading) {
                    onCancel();
                }
            }}
        >
            <Spin spinning={loading}>
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    size={"small" as SizeType}
                >
                    <Form.Item
                        label={type === "u" ? "User" : type === "g" ? "Group" : "User or Group"}
                    >
                        <AutoComplete
                            disabled={loading}
                            size={"small"}
                            options={Array.from(options.values()).map((v) => {
                                return {
                                    value: renderKey(v.value),
                                    label: renderUserGroup(v.value),
                                };
                            })}
                            onSelect={(value: any) => {
                                setLoading(true);
                                const selected = options.get(value);
                                onSelect(selected.value);
                            }}
                            onSearch={(e) => {
                                searchUserGroup(e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Escape") {
                                } else if (e.key === "Enter") {
                                }
                            }}
                            placeholder={"Search"}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
    return modal;
};
