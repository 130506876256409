import React, { CSSProperties, useEffect, useState } from "react";
import { Col, Row, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { getTopSuppliers } from "../../common/api/DashboardClientV2";
import { getFourQuarters, TimeQuarter } from "../../types/TimeTypes";
import { TopSupplier } from "../../types/DashboardTypes";
import { TopSuppliresTable } from "./components/TopSuppliersTable";

interface TopSuppliersPanelProps {
    style?: CSSProperties;
}

export const TopSuppliersPanel: React.FC<TopSuppliersPanelProps> = ({ style }) => {
    const [selectedQuarter, setSelectedQuarter] = useState<TimeQuarter>();
    const quarters = getFourQuarters(new Date());
    const options = ["All", ...quarters.map((q) => `${q.year} Q${q.quarter}`)];
    const [reload, setReload] = useState(0);
    const [data, setData] = useState([] as TopSupplier[]);

    const refeshData = async () => {
        const data = await getTopSuppliers(selectedQuarter);
        setData(data);
    };

    const handleQuarterChange = (q: SegmentedValue) => {
        const idx = options.indexOf(q as string);
        if (idx > 0) {
            const quarter = quarters[idx - 1];
            setSelectedQuarter(quarter);
        } else {
            setSelectedQuarter(undefined);
        }
        setReload(reload + 1);
    };

    useEffect(() => {
        refeshData();
    }, [reload]);

    return (
        <div style={style}>
            <Row align="middle">
                <Col flex={1}>
                    <div className="sectionTitle">Top 5 supplier risks</div>
                    <div className="sectionSubtitle">
                        View and mitigate potential budget spend increase risk.
                    </div>
                </Col>
                <Col>
                    <Segmented
                        options={options}
                        style={{ marginLeft: 12 }}
                        onChange={handleQuarterChange}
                    />
                </Col>
            </Row>
            <TopSuppliresTable data={data} />
        </div>
    );
};
