import React, { CSSProperties } from "react";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib/icons";
import { ColorType, getTypeColor } from "../utils/Color";

interface OrderScoreHeaderProps {
    title: string;
    description: string;
    score: number;
    grade: string;
    surpass: number;
    type?: ColorType;
    showButton?: boolean;
    onButtonClick?: () => void;
    style?: CSSProperties;
}

export const OrderScoreHeader: React.FC<OrderScoreHeaderProps> = ({
    title,
    score,
    grade,
    surpass,
    description,
    type = "error",
    showButton,
    onButtonClick,
    style,
}) => {
    const typeColor = getTypeColor(type);
    const scoreBarBorderStyle: React.CSSProperties = showButton
        ? {
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
          }
        : {
              borderRadius: 4,
          };

    return (
        <Row style={style}>
            <Col flex={1}>
                <Row
                    style={{
                        background: typeColor.bg,
                        padding: "8px 16px",
                        ...scoreBarBorderStyle,
                    }}
                >
                    <Col flex={1}>
                        <Space size={8}>
                            {title}
                            <Tooltip title={description}>
                                <ExclamationCircleOutlined />
                            </Tooltip>
                        </Space>
                    </Col>
                    <Col>
                        <div>
                            <span>Overall grade: </span>
                            <span style={{ color: typeColor.text, fontWeight: 600 }}>
                                {score}% ({grade})
                            </span>
                            <span style={{ color: typeColor.text, fontWeight: 600 }}>
                                {" "}
                                - Above average by
                            </span>
                            <span style={{ color: typeColor.text, fontWeight: 600 }}>
                                {" "}
                                {surpass}%
                            </span>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col>
                {showButton && (
                    <Button
                        type="primary"
                        style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            height: "100%",
                        }}
                        onClick={onButtonClick}
                    >
                        Optimize contract
                    </Button>
                )}
            </Col>
        </Row>
    );
};
