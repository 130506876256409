import React from "react";
import { Row, Space, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";

interface History {
    dataLabel: number | string;
    data: number | string;
    dataColor?: string;
}

export interface SupplierOverviewCardProps {
    title: string;
    tip: string;
    data: number;
    dataColor?: string;
    history?: History[];
}

export const SupplierOverviewCard: React.FC<SupplierOverviewCardProps> = ({
    title,
    tip,
    data,
    history = [],
    dataColor,
}) => {
    return (
        <div
            style={{
                padding: "12px 16px 24px 16px",
                borderRadius: 4,
                background: "#F6F7F9",
            }}
        >
            <Space>
                <span style={{ fontWeight: 500 }}>{title}</span>
                <Tooltip data-testid="supplier-overview-card-tip" title={tip}>
                    <ExclamationCircleOutlined style={{ color: "#1890FF " }} />
                </Tooltip>
            </Space>
            <div
                style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: 500,
                    color: dataColor,
                }}
            >
                <NumberFormat
                    prefix="$"
                    value={data}
                    displayType="text"
                    thousandSeparator
                ></NumberFormat>
            </div>
            {history && (
                <Row justify="center" style={{ marginTop: 10 }}>
                    <Space size={20}>
                        {history?.map((item) => (
                            <Space
                                size={0}
                                direction="vertical"
                                align="center"
                                key={item.dataLabel}
                                style={{ fontSize: 12 }}
                            >
                                <span>{item.dataLabel}</span>
                                <span style={{ color: item.dataColor }}>{item.data}</span>
                            </Space>
                        ))}
                    </Space>
                </Row>
            )}
        </div>
    );
};
