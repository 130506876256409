import { FeatureFlag, FeatureFlagType } from "./FeatureFlagTypes";

export const UserType = "User";
export const GroupType = "Group";

export interface User {
    id: number;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    avatar: string;
    groups?: Group[];
    type: UGType;
    status: UserStatus;
    roles: UserRole[];
    timezone: string;
    flags?: FeatureFlag[];
}

export interface Group {
    id: number;
    name: string;
    avatar: string;
    description: string;
    type: UGType;
}

export interface GroupUpdateRequest {
    group: Group;
    add: number[];
    remove: number[];
}

export interface RegisterRequest {
    user: User;
    strategy: AuthStrategy;
    password: string;
}

export enum AuthStrategy {
    PWD = "password",
    GOOGLE = "google",
    MICROSOFT = "microsoft",
}

export enum UGType {
    USER = "user",
    GROUP = "group",
}

export enum UserRole {
    USER = "user",
    VIEWER = "viewer",
    ADMIN = "admin",
    REVIEWER = "reviewer",
    SUPER_ADMIN = "superadmin",
    BOT = "bot",
}

export const isSuperAdmin = (roles: UserRole[]) => {
    return roles.indexOf(UserRole.SUPER_ADMIN) !== -1;
};

export const isAdmin = (roles: UserRole[]) => {
    return roles.indexOf(UserRole.ADMIN) !== -1;
};

export const isReviewer = (roles: UserRole[]) => {
    return roles.indexOf(UserRole.REVIEWER) !== -1;
};

export const isUser = (roles: UserRole[]) => {
    return roles.indexOf(UserRole.USER) !== -1;
};
export enum UserStatus {
    ACTIVE = "active",
    WAITING = "waiting",
    DISABLED = "disabled",
}

export const isFlagEnabled = (flagType: FeatureFlagType, flags?: FeatureFlag[]) => {
    return flags?.find((item) => item.flagType === flagType)?.enabled;
};
