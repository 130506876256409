import { Result } from "antd";
import React from "react";
import { useParams } from "react-router";
import MainLayout from "../navigation/MainLayout";
import { UserEditPanel } from "../user/UserEditPanel";

export const AdminUserView: React.FC = () => {
    const { userId } = useParams();
    let content = (
        <Result
            status="404"
            title="User not present"
            subTitle="Sorry, the user you are looking for does not exist."
        />
    );

    if (userId !== undefined && !isNaN(Number(userId))) {
        content = (
            <>
                <UserEditPanel userId={Number(userId)} isAdmin={true}></UserEditPanel>
            </>
        );
    }

    return (
        <MainLayout
            mainPanel={<div style={{ paddingTop: 10 }}>{content}</div>}
            selected="users"
            collapsed={true}
        ></MainLayout>
    );
};
