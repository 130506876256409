import React from "react";
import { Tag } from "antd";

interface SimulationScoreProps {
    score: number;
}

const scoreColorInterval = [
    {
        max: 101,
        min: 80,
        color: "success",
    },
    {
        max: 80,
        min: 60,
        color: "warning",
    },
    {
        max: 60,
        min: 0,
        color: "error",
    },
];

const getScoreColor = (score: number) => {
    return scoreColorInterval.find((item) => item.max > score && item.min <= score)?.color;
};

export const SimulationScore: React.FC<SimulationScoreProps> = ({ score }) => {
    const color = getScoreColor(score);

    return (
        <Tag color={color} data-testid="simulation-score">
            {score}%
        </Tag>
    );
};
