import { OrderFormStatus } from "./OrderFormTypes";

export interface FAttributeConfig {
    type: string;
    name: string;
    key: string;
    setValue(order: any, value: any): void;
    internalAttributes?: FAttributeConfig[];
    multiple: boolean;
}

export const OrderFormAttributesConfig = {
    expirationDate: {
        type: "Date",
        name: "Expiration Date",
        key: "expirationDate",
        multiple: false,
        setValue: (order: any, value: any) => {
            order.expirationDate = value;
            order.status = OrderFormStatus.RENEWAL_SCHEDULED;
        },
    } as FAttributeConfig,
    orderStartDate: {
        type: "Date",
        name: "Order Start Date",
        key: "orderStartDate",
        multiple: false,
        setValue: (order: any, value: any) => {
            order.orderStartDate = value;
        },
    } as FAttributeConfig,
    orderEndDate: {
        type: "Date",
        name: "Order End Date",
        key: "orderEndDate",
        multiple: false,
        setValue: (order: any, value: any) => {
            order.orderEndDate = value;
        },
    } as FAttributeConfig,
    value: {
        type: "number",
        name: "Value",
        key: "value",
        multiple: false,
        setValue: (order: any, value: any) => {
            order.value = value;
        },
    } as FAttributeConfig,
    vendorKey: {
        type: "vendor",
        name: "Vendor",
        key: "vendorKey",
        multiple: false,
        setValue: (order: any, value: any) => {
            order.vendorKey = value;
        },
    } as FAttributeConfig,
};
export const SKUAttributeConfig = [
    {
        type: "string",
        name: "Name",
        key: "name",
        multiple: false,
        setValue: (sku: any, value: any) => {
            sku.name = value;
        },
    } as FAttributeConfig,
    {
        type: "string",
        name: "SKU",
        key: "sku",
        multiple: false,
        setValue: (sku: any, value: any) => {
            sku.sku = value;
        },
    } as FAttributeConfig,
    {
        type: "number",
        name: "Price",
        key: "price",
        multiple: false,
        setValue: (sku: any, value: any) => {
            sku.price = value;
        },
    } as FAttributeConfig,
    {
        type: "number",
        name: "Total Price",
        key: "totalprice",
        multiple: false,
        setValue: (sku: any, value: any) => {
            sku.totalprice = value;
        },
    } as FAttributeConfig,
    {
        type: "number",
        name: "Quantity",
        key: "quantity",
        multiple: false,
        setValue: (sku: any, value: any) => {
            sku.quantity = value;
        },
    } as FAttributeConfig,
];
