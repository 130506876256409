import { Avatar, Form, Input, message, Modal } from "antd";
import React, { useState } from "react";
import { Vendor } from "../../types/VendorTypes";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TextArea from "antd/lib/input/TextArea";
import { createVendor } from "../../common/api/VendorClient";

interface VendorModalArgument {
    value?: string,
    onCreate: (vendor: Vendor) => void,
    onCancel?: () => void
}
export const NewVendorModal: React.FC<VendorModalArgument> = ({ value, onCreate, onCancel }) => {

    const vendorKey = (value: string | undefined) => {
        if(value===undefined){
            return "noKey";
        }
        return value.toLowerCase().replaceAll(" ", "").trim() + String(Math.round(Math.random() * 100))
    }

    const [newVendor, setNewVendor] = useState({
        vendorKey: vendorKey(value),
        hero: "",
        name: value ? value : "",
        url: "",
        description: ""
    } as Vendor);


    const createNewVendor = () => {
        if (newVendor.name.length < 5) {
            message.error("Vendor name should be more than 5 characters long");
            return;
        }
        createVendor(newVendor).then((vendor) => {
            if (onCreate) {
                onCreate(vendor);
            }
        }).catch(error => {
            if (error.isILError) {
                if (error.code === 700) {
                    message.warning(error.misc);
                }
                else {
                    message.error(error.message);
                }
            } else {
                message.error(JSON.stringify(error));
            }
        });
    }


    const newVendorModal = <>
        <Modal title="New Vendor"
            visible={true}
            zIndex={1050}
            onOk={() => {
                createNewVendor();
            }}
            onCancel={() => {
                if (onCancel !== undefined) {
                    onCancel();
                }
            }}
        >
            <div style={{ paddingLeft: 55, paddingBottom: 20 }}>
                <Avatar shape="square" src={newVendor.hero ? newVendor.hero : ""} />
                &nbsp;<span style={{ paddingLeft: 10 }}>{newVendor.name}</span>
            </div>
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
                size={"small" as SizeType}
            >
                <Form.Item label="Name">
                    <Input value={newVendor.name} onChange={(e) => {
                        newVendor.name = e.target.value;
                        newVendor.vendorKey = vendorKey(newVendor.name);
                        setNewVendor({ ...newVendor });
                    }} />
                </Form.Item>
                <Form.Item label="Hero image">
                    <Input value={newVendor.hero} placeholder="Image url" onChange={(e) => {
                        newVendor.hero = e.target.value;
                        setNewVendor({ ...newVendor });
                    }} />
                </Form.Item>
                <Form.Item label="URL">
                    <Input value={newVendor.url} onChange={(e) => {
                        newVendor.url = e.target.value;
                        setNewVendor({ ...newVendor });
                    }} />
                </Form.Item>

                <Form.Item label="Description">
                    <TextArea value={newVendor.description} onChange={(e) => {
                        newVendor.description = e.target.value;
                        setNewVendor({ ...newVendor });
                    }} />
                </Form.Item>

                <Form.Item label="VendorKey">
                    <Input value={newVendor.vendorKey} disabled={true} />
                </Form.Item>
            </Form>
        </Modal>
    </>
    return <>{newVendorModal}</>;
}
