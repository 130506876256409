import React from "react";
import { NegotiationLeverDetail } from "../../types/NegotiationTypes";

interface NegotiationLeverOutcomeTipProps {
    negotiationLever: NegotiationLeverDetail;
}

export const NegotiationLeverOutcomeTip: React.FC<NegotiationLeverOutcomeTipProps> = ({
    negotiationLever,
}) => {
    let content;
    if (negotiationLever.lever.outcome) {
        content = <span>{negotiationLever.lever.outcome}</span>;
    } else {
        content = negotiationLever.lever.options.map((option) => {
            return (
                <div key={option.id}>
                    <h4 style={{ color: "inherit" }}>{option.label}:</h4>
                    <span>{option.outcome}</span>
                </div>
            );
        });
    }
    return (
        <>
            <h3 style={{ color: "inherit" }}>Preferred Outcome</h3>
            {content}
        </>
    );
};
