import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tabs } from "antd";
import {
    LeverDetail,
    Negotiation,
    NegotiationLeverDetail,
    NegotiationLeversCategory,
} from "../../types/NegotiationTypes";
import {
    addLever,
    getAverageSimulationScore,
    getLevers,
    getNegotiation,
    updateNegotiation,
    updateLever,
} from "../../common/api/NegotiationsClient";
import { roundToTwoDecimals } from "../../common/Number";
import { handleError } from "../../common/ErrorHandling";
import { LeverRangeToggle } from "../../common/components/LeverRangeToggle";
import { NegotiationLeversContainer } from "./NegotiationLeversContainer";
import { NegotiationLeversTable } from "./NegotiationLeversTable";
import { NegotiationDealScore } from "./NegotiationDealScore";
import { NegotiationLeversTableHeader } from "./NegotiationLeversTableHeader";
import { getGrade } from "./config/grades";
import { NegotiationLeverRemoveModal } from "./NegotiationLeverRemoveModal";

interface NegotiationSimulationPanelProps {
    negotiationId: number;
}

export function sumLeversScore(levers: LeverDetail[]) {
    return levers.reduce((pre, cur) => {
        return (
            pre +
            (cur.lever.weight *
                (cur.lever.options.find((option) => option.id === cur.selectedOptionId)?.weight ||
                    0)) /
                100
        );
    }, 0);
}

export const NegotiationSimulationPanel: React.FC<NegotiationSimulationPanelProps> = ({
    negotiationId,
}) => {
    const [negotiation, setNegotiation] = useState({} as Negotiation);
    const [loading, setLoading]: any[] = useState(false);
    const [procurementLevers, setProcurementLevers] = useState([] as NegotiationLeverDetail[]);
    const [legalLevers, setLegalLevers] = useState([] as NegotiationLeverDetail[]);
    const [newLeverId, setNewLeverId] = useState<number>();
    const [averageScore, setAverageScore] = useState(0);
    const [currentTab, setCurrentTab] = useState(NegotiationLeversCategory.PROCUREMENT_LEVERS);
    const [leverToRemove, setLeverToRemove] = useState<NegotiationLeverDetail>();
    const [showRemoveLeverModal, setShowRemoveLeverModal] = useState(false);
    const [legalLeverWeight, setLegalLeverWeight] = useState(0);

    const procurementScore = sumLeversScore(procurementLevers.filter((item) => item.isSelected));
    const legalScore = sumLeversScore(legalLevers.filter((item) => item.isSelected));

    const overallScore = roundToTwoDecimals(
        (procurementScore * (100 - legalLeverWeight) + legalScore * legalLeverWeight) / 100
    );
    const overallGrade = getGrade(overallScore)?.grade || "";
    const potentialToImprove = roundToTwoDecimals(100 - overallScore);

    const currentLevers =
        currentTab === NegotiationLeversCategory.PROCUREMENT_LEVERS
            ? procurementLevers
            : legalLevers;
    const currentSelectedLevers = currentLevers
        .filter((item) => item.isSelected)
        .sort(
            (a, b) =>
                new Date(b.recordCreationDate).getTime() - new Date(a.recordCreationDate).getTime()
        );
    const currentUnselectedLevers = currentLevers.filter((item) => !item.isSelected);

    const currentScore = roundToTwoDecimals(sumLeversScore(currentSelectedLevers));
    const currentGrade = getGrade(currentScore)?.grade || "";
    const currentTitle =
        currentTab === NegotiationLeversCategory.PROCUREMENT_LEVERS
            ? "Procurement score"
            : "Legal score";

    const refreshNegotiation = async () => {
        const res = await getNegotiation(negotiationId);
        setNegotiation(res);
        setLegalLeverWeight(res.legalWeight);
    };

    const refreshLevers = async () => {
        setLoading(true);
        try {
            const res = await getLevers(negotiationId);
            const procurementLevers = res.filter(
                (item) => item.lever.category === NegotiationLeversCategory.PROCUREMENT_LEVERS
            );
            const legalLevers = res.filter(
                (item) => item.lever.category === NegotiationLeversCategory.lEGAL_LEVERS
            );
            setProcurementLevers(procurementLevers);
            setLegalLevers(legalLevers);
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const refreshAverageScore = async () => {
        const res = await getAverageSimulationScore(negotiationId);
        setAverageScore(res.average);
    };

    const handleLeverWeightChange = async (weight: number) => {
        const newNegotiation = { ...negotiation, legalWeight: weight };
        await updateNegotiation(newNegotiation);
        refreshNegotiation();
    };

    const handleLeverSelect = async (lever: NegotiationLeverDetail) => {
        const res = await addLever(negotiationId, lever);
        setNewLeverId(res.id);
        refreshLevers();
    };

    const handleLeverOptionChange = async (newOptionId: number, lever: NegotiationLeverDetail) => {
        const newLever = {
            ...lever,
            selectedOptionId: newOptionId,
        };
        await updateLever(newLever);
        refreshLevers();
    };

    const handleLeverRemove = async (lever: NegotiationLeverDetail) => {
        setLeverToRemove(lever);
        setShowRemoveLeverModal(true);
    };

    useEffect(() => {
        refreshNegotiation();
        refreshLevers();
        refreshAverageScore();
    }, [negotiationId]);

    const removeLeverModal = showRemoveLeverModal ? (
        <NegotiationLeverRemoveModal
            onRemove={() => {
                setShowRemoveLeverModal(false);
                refreshLevers();
            }}
            onCancel={() => setShowRemoveLeverModal(false)}
            value={leverToRemove}
        />
    ) : (
        <></>
    );

    const simulationHeader = (
        <div style={{ marginTop: 20 }}>
            <Tabs
                tabBarExtraContent={
                    <LeverRangeToggle
                        value={legalLeverWeight}
                        onChange={setLegalLeverWeight}
                        afterChange={handleLeverWeightChange}
                    />
                }
                items={[
                    {
                        label: "Procurement levers",
                        key: NegotiationLeversCategory.PROCUREMENT_LEVERS,
                    },
                    { label: "Legal levers", key: NegotiationLeversCategory.lEGAL_LEVERS },
                ]}
                onChange={(tabKey) => setCurrentTab(tabKey as NegotiationLeversCategory)}
            />
        </div>
    );

    const simulation = (
        <Row gutter={20}>
            <Col span={4}>
                <NegotiationLeversContainer
                    levers={currentUnselectedLevers}
                    onSelect={handleLeverSelect}
                />
            </Col>
            <Col span={20}>
                <Card>
                    {
                        <NegotiationLeversTableHeader
                            potential={potentialToImprove}
                            title={currentTitle}
                            score={currentScore}
                            grade={currentGrade}
                        />
                    }
                    {
                        <NegotiationLeversTable
                            levers={currentSelectedLevers}
                            loading={loading}
                            newLeverId={newLeverId}
                            onChange={handleLeverOptionChange}
                            onRemove={handleLeverRemove}
                        />
                    }
                </Card>
            </Col>
        </Row>
    );

    return (
        <>
            <NegotiationDealScore
                overallScore={overallScore}
                overallGrade={overallGrade}
                averageScore={averageScore}
            />
            {simulationHeader}
            {simulation}
            {removeLeverModal}
        </>
    );
};
