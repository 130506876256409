export enum AccessOwnerType {
    USER,
    GROUP,
    NONE
}

export enum AccessRight {
    READ_WRITE = 3,
    READ = 1,
    ADMIN = 4,
    NONE = 0
}

export interface ResourceAccess {
    accessOnwerType: AccessOwnerType,
    accessOnwerId: number,
    accessRight: AccessRight
}

export function canWrite(right: AccessRight) {
    if (right === AccessRight.READ_WRITE || right === AccessRight.ADMIN) {
        return true;
    }
    return false;
}
