import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import NumberFormat from "react-number-format";
import { getInsights } from "../../common/api/NegotiationsClient";
import { Vendor } from "../../types/VendorTypes";
import { NegotiationInsigtsType } from "../../types/NegotiationTypes";
import { NegotiationInsightCard, NegotiationInsightCardProps } from "./NegotiationInsightCard";
import { NegotiationCotermModal } from "./NegotiationCotermModal";
import { NegotiationPriceDiffModal } from "./NegotiationPriceDiffModal";
import { NegotiationEndDatesModal } from "./NegotiationEndDatesModal";

interface NegotiationInsightsContainerProps {
    vendors?: Vendor[];
}

export const NegotiationInsightsContainer: React.FC<NegotiationInsightsContainerProps> = ({
    vendors = [],
}) => {
    const [showCoterm, setShowCoterm] = useState(false);
    const [showUnitPrice, setShowUnitPrice] = useState(false);
    const [showEndDates, setShowEndDates] = useState(false);
    const [insights, setInsights] = useState<NegotiationInsightCardProps[]>([]);
    const [insightsDetail, setInsightsDetail] = useState({} as NegotiationInsigtsType);

    useEffect(() => {
        getInsights(vendors.map((v) => v.vendorKey)).then((res) => {
            const discrepancy = res.priceDiff.discrepancy;
            const discrepancyPrefix = discrepancy > 0 ? "↑ " : discrepancy < 0 ? "↓ " : "";
            setInsightsDetail(res);

            setInsights([
                {
                    label: "Co-terming",
                    tips: "Co-term all existing contracts with the same vendor to the exact same end date in order to get a volume discount.",
                    data: `-${res?.coterm?.reduce} contracts`,
                    detail: `Contracts: ${res?.coterm?.before} > Contracts: ${res?.coterm?.after}`,
                    onClick: () => setShowCoterm(true),
                },
                {
                    label: "Unit price differential",
                    //FIX: change the tips when correct text provided
                    tips: "Co-term all existing contracts with the same vendor to the exact same end date in order to get a volume discount.",
                    data: (
                        <span>
                            {discrepancyPrefix}
                            <NumberFormat
                                prefix="$"
                                thousandSeparator={true}
                                displayType={"text"}
                                value={Math.abs(discrepancy)}
                            ></NumberFormat>
                        </span>
                    ),
                    detail: `Number of SKUs: ${res?.priceDiff?.skuNumber}   Number of vendors: ${res?.priceDiff?.vendorNumber}`,
                    onClick: () => setShowUnitPrice(true),
                },
                {
                    label: "Year-end dates",
                    //FIX: change the tips when correct text provided
                    tips: "Co-term all existing contracts with the same vendor to the exact same end date in order to get a volume discount.",
                    data: `${res?.endDates?.total} Expiring deals`,
                    detail: `Early renewals: ${res?.endDates?.earlyEnd}   Normal renewals:${res?.endDates?.normalEnd}`,
                    onClick: () => setShowEndDates(true),
                },
            ]);
        });
    }, [vendors, vendors.length]);

    return (
        <Row gutter={20} style={{ marginTop: 20 }}>
            {insights.map((v) => (
                <Col span={8} key={v.label}>
                    <NegotiationInsightCard {...v} />
                </Col>
            ))}
            {insightsDetail.coterm?.orderForms && (
                <NegotiationCotermModal
                    visible={showCoterm}
                    orderForms={insightsDetail.coterm.orderForms}
                    onCancel={() => setShowCoterm(false)}
                />
            )}
            {insightsDetail.priceDiff?.changedOrderSKUs && (
                <NegotiationPriceDiffModal
                    visible={showUnitPrice}
                    changedOrderSKUs={insightsDetail.priceDiff.changedOrderSKUs}
                    discrepancy={insights[1]?.data}
                    onCancel={() => setShowUnitPrice(false)}
                />
            )}
            {insightsDetail.endDates?.orderForms && (
                <NegotiationEndDatesModal
                    visible={showEndDates}
                    orderForms={insightsDetail.endDates.orderForms}
                    onCancel={() => setShowEndDates(false)}
                />
            )}
        </Row>
    );
};
