import React from "react";
import { Alert, Modal } from "antd";
import { Error } from "../../types/Error";

interface ErrorInfoModalProps {
    open: boolean;
    error: Error;
    onCancel?: () => void;
}

export const ErrorInfoModal: React.FC<ErrorInfoModalProps> = ({ open, error, onCancel }) => {
    return (
        <Modal open={open} title="Error" onCancel={onCancel} footer={null} width={1000}>
            <Alert
                type="error"
                description={<pre>{JSON.stringify(error, null, 2).replace(/\\n/g, "\n\t")}</pre>}
            ></Alert>
        </Modal>
    );
};
