import React from "react";
import { Tag } from "antd";
import { StarFilled } from "@ant-design/icons";

interface SupplierScoreProps {
    score: number;
}

const scoreColorInterval = [
    {
        max: 5.1,
        min: 4,
        color: "success",
    },
    {
        max: 4,
        min: 3,
        color: "warning",
    },
    {
        max: 3,
        min: 0,
        color: "error",
    },
];

const getScoreColor = (score: number) => {
    return scoreColorInterval.find((item) => item.max > score && item.min <= score)?.color;
};

export const SupplierScore: React.FC<SupplierScoreProps> = ({ score }) => {
    const color = getScoreColor(score);

    return (
        <Tag color={color} icon={<StarFilled />} data-testid="supplier-score">
            {score}
        </Tag>
    );
};
