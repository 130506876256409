import { FilterGroup, PageRequest, PagerObject, Sort } from "../../types/DataFetchingTypes";
import {
    Supplier,
    SupplierFullCompetitor,
    SupplierInsight,
    SupplierWithEmployee,
    UserSupplierSetting,
} from "../../types/SupplierTypes";
import { TimeQuarter } from "../../types/TimeTypes";
import { safeDELETE, safeGET, safePOST, safePUT } from "./SafeClient";

export function listSuppliers(
    pageRequest: PageRequest,
    sorter: Sort[],
    filter: FilterGroup[],
    quarter?: TimeQuarter,
    abort?: any
): Promise<PagerObject<Supplier[]>> {
    const sorting = sorter
        .map((v) => `sort[]=${v.attribute}&order[]=${v.order}`)
        .reduce((p, v) => `${p}&${v}`, "");
    const paging = `current=${pageRequest.current}&pageSize=${pageRequest.pageSize}`;
    const filtering = filter
        .map((v) => {
            return v.filter
                .map((fv) => {
                    return `filter[]=${fv.attribute}&operand[]=${fv.operand}&value[]=${fv.value}&group[]=${v.groupId}&gop[]=${v.groupType}`;
                })
                .join("&");
        })
        .reduce((p, v) => `${p}&${v}`, "");
    let quarterQuery = "";
    if (quarter) {
        quarterQuery = `&year=${quarter.year}&quarter=${quarter.quarter}`;
    }
    return safeGET(`/api/supplier?${paging}${sorting}${filtering}${quarterQuery}`, abort);
}

export function createSupplier(supplier: Supplier, abort?: any): Promise<Supplier> {
    return safePOST("/api/supplier/extend", supplier, abort);
}

export function getSupplierById(id: number, abort?: any): Promise<SupplierWithEmployee> {
    return safeGET(`/api/supplier/${id}`, abort);
}

export function updateSupplier(supplier: Supplier, abort?: any): Promise<Supplier> {
    return safePUT(`/api/supplier/${supplier.id}`, supplier, abort);
}

export function deleteSupplier(id: number, abort?: any): Promise<Supplier> {
    return safeDELETE(`/api/supplier/${id}`, undefined, abort);
}

export function getSupplierCompetitors(id: number, abort?: any): Promise<SupplierFullCompetitor[]> {
    return safeGET(`/api/supplier/${id}/competitor`, abort);
}

export function getSupplierInsights(id: number, abort?: any): Promise<SupplierInsight[]> {
    return safeGET(`/api/supplier/${id}/insight`, abort);
}

export function getUserSupplierSetting(userId: number): Promise<UserSupplierSetting> {
    return safeGET(`/api/supplier/user/${userId}/setting`);
}

export function updateUserSupplierSetting(
    setting: UserSupplierSetting
): Promise<UserSupplierSetting> {
    return safePUT(`/api/supplier/user/${setting.userId}/setting`, setting);
}
