import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { AnnotationValue } from "../../../types/Document";
import { PageImage } from "../../../types/ReviewTypes";
import { BoundingData } from "./ReviewInterfaces";

function mapBlocksToBounds(
    blocks: any[],
    width: number,
    height: number,
    page: number
): BoundingData[] {
    return blocks
        .filter((block: any) => block.BlockType === "LINE")
        .map((block: any) => {
            /**
             * Only capture the lines, other items will be of no use
             */
            const left = Math.round(block.Geometry.BoundingBox.Left * width);
            const top = Math.round(block.Geometry.BoundingBox.Top * height);
            const labelWidth = Math.round(block.Geometry.BoundingBox.Width * width);
            const labelHeight = Math.round(block.Geometry.BoundingBox.Height * height);
            return {
                x: left,
                y: top,
                w: labelWidth,
                h: labelHeight,
                page: page,
                label: {
                    id: block.Id,
                    text: block.Text,
                },
            };
        });
}

function transformBoundToRectangles(boundings: BoundingData[], ctx: CanvasRenderingContext2D) {
    return boundings.forEach((bound: any) => {
        ctx.beginPath();
        ctx.rect(bound.x, bound.y, bound.w, bound.h);
        if (bound.hover) {
            ctx.strokeStyle = "red";
        } else if (bound.selected) {
            ctx.strokeStyle = "#cfc108";
        } else {
            ctx.strokeStyle = "#8c8d8f";
        }
        ctx.stroke();
        if (bound.selected) {
            ctx.font = "14px verdana";
            ctx.fillStyle = "#fff30f";
            ctx.textBaseline = "top";
            const width = ctx.measureText(bound.text).width;
            ctx.fillRect(bound.x, bound.y + bound.h, width, 14);
            ctx.fillStyle = "#000";
            ctx.fillText(bound.text, bound.x, bound.y + bound.h);
        }
    });
}

function redrawCanvas(
    imageCanvas: HTMLCanvasElement,
    imageElement: HTMLImageElement,
    boundings: BoundingData[]
) {
    const ctx = imageCanvas.getContext("2d")!;
    ctx!.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);
    return transformBoundToRectangles(boundings, ctx);
}

function intersectAttributesAndBounds(
    attributeAnnotations: AnnotationValue[],
    boundings: BoundingData[]
) {
    const boundIndex = new Map(
        boundings.map((bound) => {
            bound.selected = false;
            return [bound.label.id, bound];
        })
    );

    attributeAnnotations.forEach((annotation) => {
        if (boundIndex.has(annotation.attributeLabel)) {
            boundIndex.get(annotation.attributeLabel)!.selected = true;
            boundIndex.get(
                annotation.attributeLabel
            )!.text = `${annotation.attributeName} : ${annotation.attributeValue}`;
        }
    });
}

interface ILCanvas {
    documentSerialId: string;
    image: PageImage;
    page: any;
    attributeAnnotations: AnnotationValue[];
    onLabelSelection: (selection: BoundingData, clientX: number, clientY: number) => void;
    suppress?: boolean;
}

export const CanvasElement: React.FC<ILCanvas> = ({
    documentSerialId,
    image,
    page,
    attributeAnnotations,
    onLabelSelection,
    suppress,
}) => {
    const [loaded, setLoaded] = useState(false);

    let containeRef = React.createRef<HTMLDivElement>();
    let imageCanvasRef = React.createRef<HTMLCanvasElement>();
    let imageRef = React.createRef<HTMLImageElement>();

    useEffect(() => {
        if (loaded) {
            drawCanvas(
                imageRef.current!,
                imageCanvasRef.current!,
                containeRef.current!,
                attributeAnnotations
            );
        }
    }, [attributeAnnotations, loaded]);
    useEffect(() => {
        setLoaded(false);
    }, [image]);

    const drawCanvas = (
        imageElement: HTMLImageElement,
        imageCanvas: HTMLCanvasElement,
        containerElement: HTMLDivElement,
        attributeAnnotations: AnnotationValue[]
    ) => {
        const imgHeight =
            (containerElement.clientWidth / imageElement.naturalWidth) * imageElement.naturalHeight;
        /*set new image size */
        imageElement.width = containerElement.clientWidth;
        imageElement.height = imgHeight;

        /*set canvas size*/
        imageCanvas.width = imageElement.width;
        imageCanvas.height = imageElement.height;

        const boundingData = mapBlocksToBounds(
            page.blocks,
            imageElement.width,
            imageElement.height,
            page.page
        );

        intersectAttributesAndBounds(attributeAnnotations, boundingData);

        redrawCanvas(imageCanvas, imageElement, boundingData);

        if (suppress) {
            return;
        }

        imageCanvas.onmousemove = (e) => {
            var r = imageCanvas.getBoundingClientRect(),
                x = e.clientX - r.left,
                y = e.clientY - r.top;
            boundingData.forEach((_b) => (_b.hover = false));

            for (var i = boundingData.length - 1, b; (b = boundingData[i]); i--) {
                if (x >= b.x && x <= b.x + b.w && y >= b.y && y <= b.y + b.h) {
                    boundingData.forEach((_b) => (_b.hover = false));
                    b.hover = true;
                    break;
                }
            }
            redrawCanvas(imageCanvas, imageElement, boundingData);
        };

        imageCanvas.onclick = (e) => {
            var r = imageCanvas.getBoundingClientRect(),
                x = e.clientX - r.left,
                y = e.clientY - r.top;

            for (var i = boundingData.length - 1, b; (b = boundingData[i]); i--) {
                if (x >= b.x && x <= b.x + b.w && y >= b.y && y <= b.y + b.h) {
                    // annotate(b.label);
                    onLabelSelection(b, e.clientX, e.clientY);
                    redrawCanvas(imageCanvas, imageElement, boundingData);
                    break;
                }
            }
        };
    };
    const drawCanvasElement = (
        event: React.SyntheticEvent<HTMLImageElement, Event>,
        attributeAnnotations: AnnotationValue[]
    ) => {
        const imageElement = event.target as HTMLImageElement;
        const imageCanvas = imageElement.parentNode as HTMLCanvasElement;
        const containerElement = imageCanvas.parentElement as HTMLDivElement;
        drawCanvas(imageElement, imageCanvas, containerElement, attributeAnnotations);
    };

    const imageOnLoad = (event: any) => {
        setLoaded(true);
        /*redraw on resize*/
        window.addEventListener("resize", () => {
            drawCanvasElement(event, attributeAnnotations);
        });
    };

    return (
        <Spin spinning={!loaded}>
            <div
                key={`${documentSerialId}-${page.page}`}
                className="mainCanvaContainer"
                ref={containeRef}
                style={{ minHeight: 800 }}
            >
                <canvas key={page.page + "DOC"} ref={imageCanvasRef}>
                    <img
                        key={`${documentSerialId}-${page.page}-canvas`}
                        alt="document"
                        ref={imageRef}
                        src={image.url}
                        onLoad={imageOnLoad}
                    />
                </canvas>
            </div>
        </Spin>
    );
};

export default CanvasElement;
