import React from "react";
import { Row, Space, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface NegotiationInsightCardProps {
    label: string;
    tips: string;
    data: React.ReactNode;
    detail: React.ReactNode;
    onClick?: () => void;
}

export const NegotiationInsightCard: React.FC<NegotiationInsightCardProps> = ({
    label,
    tips,
    data,
    detail,
    onClick,
}) => (
    <div
        style={{
            padding: "12px 16px",
            borderRadius: "4px",
            background: "#F6F7F9",
            cursor: "pointer",
        }}
        onClick={onClick}
    >
        <Row>
            <Space>
                <span>{label}</span>
                <Tooltip title={tips}>
                    <ExclamationCircleOutlined style={{ color: "#1890FF" }} />
                </Tooltip>
            </Space>
        </Row>
        <Row
            justify="center"
            style={{
                color: "#FF7A1A",
                fontSize: 28,
                fontWeight: 500,
            }}
        >
            {data}
        </Row>
        <Row justify="center" style={{ whiteSpace: "pre" }}>
            {detail}
        </Row>
    </div>
);
