import { Card } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../navigation/MainLayout";
import { NegotiationSimulationPanel } from "./NegotiationSimulationPanel";
import { NegotiationDetailInsights } from "./NegotiationDetailInsights";

export const NegotiationDetailsView: React.FC = () => {
    const params = useParams();
    const [negotiationId] = useState(Number(params.negotiationId));

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <Card>
                <NegotiationDetailInsights negotiationId={negotiationId} />
                <NegotiationSimulationPanel negotiationId={negotiationId} />
            </Card>
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"Negotiations"} collapsed={true} />;
};
