import React from "react";
import { Col, Row } from "antd";

interface NegotiationLeversTableHeaderProps {
    title: string;
    score: number;
    grade: string;
    potential: number;
}

export const NegotiationLeversTableHeader: React.FC<NegotiationLeversTableHeaderProps> = ({
    title,
    score,
    grade,
    potential,
}) => (
    <Row align="middle">
        <Col flex={1}>
            {title}:{" "}
            <span style={{ color: "#FF7A1A", fontWeight: 600 }}>
                {score}% ({grade})
            </span>
        </Col>
        <Col>
            <div
                style={{
                    color: "#18AC00",
                    fontWeight: 500,
                    padding: "8px 12px",
                    borderRadius: 4,
                    background: "#EDFBDF",
                }}
            >
                Your deal has potential to improve by {potential}%
            </div>
        </Col>
    </Row>
);
