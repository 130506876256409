import { Spin } from "antd";
import React from "react";
import { useUserState } from "../../common/UserContext";
import MainLayout from "../navigation/MainLayout";
import { UserEditPanel } from "./UserEditPanel";

export const UserPreferencesView: React.FC = () => {
    const { me } = useUserState();
    const panel = me.id ? (
        <UserEditPanel userId={me.id}></UserEditPanel>
    ) : (
        <Spin spinning={true}>
            <div style={{ width: "100%", height: "100%" }}>&nbsp;</div>
        </Spin>
    );

    return (
        <MainLayout
            mainPanel={<div style={{ paddingTop: 10 }}>{panel}</div>}
            selected={"preferences"}
            collapsed={true}
        />
    );
};
