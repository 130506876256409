import React from "react";
import { Button, Select, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { NegotiationLeverDetail } from "../../types/NegotiationTypes";
import { getGrade } from "./config/grades";

export interface NegotiationLeversTableProps {
    levers?: NegotiationLeverDetail[];
    newLeverId?: number;
    loading?: boolean;
    onChange?: (optionId: number, lever: NegotiationLeverDetail) => void;
    onRemove?: (lever: NegotiationLeverDetail) => void;
}

export const NegotiationLeversTable: React.FC<NegotiationLeversTableProps> = ({
    loading,
    newLeverId,
    levers,
    onChange,
    onRemove,
}) => {
    const columns = [
        {
            title: "Lever",
            key: "name",
            render: (_: unknown, row: NegotiationLeverDetail) => {
                return row.lever.name;
            },
        },
        {
            title: "Value",
            key: "value",
            render: (_: unknown, row: NegotiationLeverDetail) => {
                const selectOptions = row.lever.options.map((option) => ({
                    value: option.id,
                    label: option.label,
                }));
                return (
                    <Select
                        value={row.selectedOptionId}
                        options={selectOptions}
                        style={{ width: "100%" }}
                        bordered={false}
                        onSelect={(value) => onChange && onChange(value, row)}
                    ></Select>
                );
            },
        },
        {
            title: "Best practice",
            key: "best",
            render: (_: unknown, row: NegotiationLeverDetail) => {
                if (row.lever.best) {
                    return row.lever.best;
                }
                const option = row.lever.options.find((item) => item.id === row.selectedOptionId);
                return option?.best;
            },
        },
        {
            title: "Score",
            dataIndex: "lever.options",
            key: "score",
            render: (_: unknown, row: NegotiationLeverDetail) => {
                const score =
                    row.lever.options.find((item) => item.id === row.selectedOptionId)?.weight || 0;
                return getGrade(score)?.grade;
            },
        },
        {
            title: "Actions",
            dataIndex: "",
            key: "actions",
            render: (_: unknown, row: NegotiationLeverDetail) => {
                return (
                    <Button
                        danger
                        data-testid="remove-lever-button"
                        type="link"
                        icon={<CloseOutlined />}
                        onClick={() => onRemove && onRemove(row)}
                    ></Button>
                );
            },
        },
    ];

    const handleRowClass = (row: NegotiationLeverDetail) => {
        return row.id === newLeverId ? "newlyAddedRow" : "";
    };

    return (
        <Table
            dataSource={levers}
            loading={loading}
            rowKey="id"
            columns={columns}
            style={{ marginTop: 16 }}
            rowClassName={handleRowClass}
        ></Table>
    );
};
