import moment from "moment";

/*****
 * 
 * PARSE DIFFERENT DATE STRINGS FROM THE UI
 *
 *   */
const DATE_FORMATS = [
    "YYYY-MM-DD h:mm:ss a",
    "YYYY-MM-DDTh:mm:ss a",
    "DD-MM-YYYY",
    "MM/DD/YYYY",
    "MMM DD, YYYY",
]

export const DATE_FORMAT = "MM-DD-YYYY";

export function extractDate(rawDateText: string) {
    for (let format of DATE_FORMATS) {
        const parsedDate = moment(rawDateText, format)

        if (parsedDate.isValid()) {
            return parsedDate;
        }

    }
}
export function formatDetailTimeString(date: string|Date) {
    return formatDetailDate(moment(date))
}

export function momentDate(date: Date | undefined): moment.Moment | undefined {
    if (date === undefined) return undefined;
    return moment(date);
}

export function formatDetailDate(dateObj: moment.Moment | undefined) {
    if (undefined) return "";
    return dateObj?.format("MM-DD-YYYY h:mm:ss a");
}

export function formatDateString(date: string|Date) {
    return formatDate(moment(date));
}


export function formatDate(dateObj: moment.Moment | undefined): string {
    if (dateObj === undefined || !dateObj.isValid())
        return ""
    return dateObj.format(DATE_FORMAT);
}
