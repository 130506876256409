import { DatePicker, Form, Modal, Spin } from "antd";
import { Notification, NotificationStatus } from "../../types/NotificationTypes";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { momentDate } from "../date/DateOp";
import { addNotification, updateNotification } from "../../common/api/NotificationsClient";
import { Resource } from "../../types/ResourceType";
import moment, { Moment } from "moment";
import { handleError } from "../../common/ErrorHandling";
import { User } from "../../types/UserGroup";

interface NotificationModalArgument {
    eNotification?: Notification;
    resource: Resource;
    onCreate: (notification: Notification) => void;
    onCancel?: () => void;
}

interface NotificationSpecModalArg {
    notification: Notification;
    onComplete: (notification: Notification | undefined) => void;
    user: User;
}

interface MofidModalArgs {
    onComplete: (notification: Notification | undefined) => void;
    title: string;
    notification: Notification;
    form: JSX.Element;
}
const NotifModal: React.FC<MofidModalArgs> = ({ onComplete, title, notification, form }) => {
    const [loading, setLoading] = useState(false);

    const __updateNotification = async () => {
        setLoading(true);
        try {
            const notification_ = await updateNotification(notification.resource, notification);
            onComplete(notification_);
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
            onComplete(undefined);
        }
    };

    return (
        <Modal
            title={title}
            visible={true}
            onOk={__updateNotification}
            onCancel={() => {
                onComplete(undefined);
            }}
        >
            <Spin spinning={loading}>
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} layout="horizontal" size={"small" as SizeType}>
                    {form}
                </Form>
            </Spin>
        </Modal>
    );
};

function disabledDate(current: Moment) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
}

export const NotificationAcknowledgeModal: React.FC<NotificationSpecModalArg> = ({
    notification,
    onComplete,
    user,
}) => {
    const [editedNotification, setEditedNotification] = useState({
        ...notification,
        ...{ enactedBy: user.id },
        ...{ status: NotificationStatus.ACKNOWDELGED },
    });
    return (
        <NotifModal
            form={
                <Form.Item label="Message">
                    <TextArea
                        value={editedNotification.enactedReason}
                        onChange={(e) => {
                            editedNotification.enactedReason = e.target.value;
                            setEditedNotification({ ...editedNotification });
                        }}
                    />
                </Form.Item>
            }
            title={"Acknowledge Notification"}
            notification={editedNotification}
            onComplete={onComplete}
        />
    );
};

export const SnoozeModal: React.FC<NotificationSpecModalArg> = ({ notification, onComplete, user }) => {
    const nextNotificationDate = momentDate(notification.notificationDate)!.add(moment.duration(25, "hour")).toDate();
    const [editedNotification, setEditedNotification] = useState({
        ...notification,
        ...{ enactedBy: user.id },
        ...{ nextNotificationDate: nextNotificationDate },
        ...{ status: NotificationStatus.SNOOZED },
    });
    return (
        <NotifModal
            form={
                <>
                    <Form.Item label="Snooze until">
                        <DatePicker
                            value={momentDate(editedNotification.nextNotificationDate)}
                            allowClear={false}
                            disabledDate={disabledDate}
                            onChange={(date) => {
                                if (date !== undefined) {
                                    notification.nextNotificationDate = date!.toDate();
                                    setEditedNotification({ ...editedNotification });
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Reason">
                        <TextArea
                            value={editedNotification.enactedReason}
                            onChange={(e) => {
                                editedNotification.enactedReason = e.target.value;
                                setEditedNotification({ ...editedNotification });
                            }}
                        />
                    </Form.Item>
                </>
            }
            title={"Snooze Notification"}
            notification={editedNotification}
            onComplete={onComplete}
        />
    );
};

export const EditSnoozeModal: React.FC<NotificationSpecModalArg> = ({ notification, onComplete, user }) => {
    const [editedNotification, setEditedNotification] = useState({
        ...notification,
        ...{ enactedBy: user.id },
    });
    return (
        <NotifModal
            form={
                <>
                    <Form.Item label="Snooze until">
                        <DatePicker
                            value={momentDate(editedNotification.nextNotificationDate)}
                            allowClear={false}
                            disabledDate={disabledDate}
                            onChange={(date) => {
                                if (date !== undefined) {
                                    editedNotification.nextNotificationDate = date!.toDate();
                                    setEditedNotification({ ...editedNotification });
                                }
                            }}
                        />
                    </Form.Item>
                </>
            }
            title={"Reschedule Snooze"}
            notification={editedNotification}
            onComplete={onComplete}
        />
    );
};
export const NotificationModal: React.FC<NotificationModalArgument> = ({
    eNotification,
    resource,
    onCreate,
    onCancel,
}) => {
    const [notification, setNotification] = useState({} as Notification);
    const [loading, setLoading] = useState(false);
    if (eNotification !== undefined && eNotification !== notification) {
        setNotification(eNotification);
    }

    const doAddNotification = async () => {
        setLoading(true);
        let un = {} as Notification;
        try {
            if (eNotification) {
                un = await updateNotification(resource, notification);
            } else {
                un = await addNotification(resource, notification);
            }

            onCreate(un);
        } catch (error: any) {
            setLoading(false);
        }
    };

    const modal = (
        <Modal
            title={eNotification ? "New Notification" : "Update Notification"}
            visible={true}
            onOk={() => {
                doAddNotification();
            }}
            onCancel={() => {
                if (onCancel !== undefined) {
                    onCancel();
                }
            }}
        >
            <Spin spinning={loading}>
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 18 }} layout="horizontal" size={"small" as SizeType}>
                    <Form.Item label="Scheduled for">
                        <DatePicker
                            value={momentDate(notification.notificationDate)}
                            allowClear={false}
                            disabledDate={disabledDate}
                            onChange={(date) => {
                                if (date !== undefined) {
                                    notification.notificationDate = date!.toDate();
                                    setNotification({ ...notification });
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Message">
                        <TextArea
                            value={notification.message}
                            onChange={(e) => {
                                notification.message = e.target.value;
                                setNotification({ ...notification });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
    return modal;
};
