import React, { useState } from "react";
import Dragger, { DraggerProps } from "antd/lib/upload/Dragger";
import { Spin, UploadProps } from "antd";

interface CustomDraggerProps extends DraggerProps {
    icon: React.ReactNode;
    title: string;
    description: string;
}

export const CustomDragger: React.FC<CustomDraggerProps> = ({
    icon,
    title,
    description,
    onChange,
    ...restProps
}) => {
    const [spinning, setSpinning] = useState(false);
    const handleChange: UploadProps["onChange"] = (info) => {
        setSpinning(true);
        if (info.file.status !== "uploading") {
            setSpinning(false);
        }
        onChange?.(info);
    };

    return (
        <Spin spinning={spinning}>
            <Dragger
                style={{ borderStyle: "solid", borderColor: "#1890ff", padding: 12 }}
                onChange={handleChange}
                {...restProps}
            >
                <div>
                    <div style={{ fontSize: 36, color: "#1890ff", lineHeight: 1 }}>{icon}</div>
                    <div style={{ fontSize: 14, color: "#000000", marginTop: 8 }}>{title}</div>
                    <div style={{ fontSize: 12, color: "#666666" }}>{description}</div>
                </div>
            </Dragger>
        </Spin>
    );
};
