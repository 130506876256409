import { PagerObject } from "../../types/DataFetchingTypes";
import { Vendor } from "../../types/VendorTypes";
import { safeGET, safePOST } from "./SafeClient";

export function listOrgs(abort?: any): Promise<PagerObject<Vendor[]>> {
    return safeGET(`/api/organization`, abort);
}

export function createOrg(orgName: string, abort?: any): Promise<Vendor> {
    return safePOST(`/api/organization/`, {orgName}, abort)
}