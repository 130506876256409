import React, { useEffect, useState } from "react";
import { Checkbox, Col, Modal, Row } from "antd";
import { Resource, ResourceType } from "../../../types/ResourceType";
import { ResourceNotificationSettings } from "../../../types/NotificationTypes";
import {
    getNotificationsSettingsForResource,
    updateNotificationsSettingsForResource,
} from "../../../common/api/NotificationsClient";
import { handleError } from "../../../common/ErrorHandling";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface NotificationModalProps {
    resourceId: number;
    resourceType?: ResourceType;
    open?: boolean;
    onOk?: () => void;
    onCancel?: () => void;
}

let SettingsCache = {} as ResourceNotificationSettings;

export const NotificationModal: React.FC<NotificationModalProps> = ({
    resourceId,
    resourceType = ResourceType.OrderForm,
    open,
    onOk,
    onCancel,
}) => {
    const [resource, setResource] = useState({
        resourceId,
        resourceType,
    } as Resource);
    const [settings, setSettings] = useState({} as ResourceNotificationSettings);

    const refreshSettings = async () => {
        try {
            const res = await getNotificationsSettingsForResource(resource);
            SettingsCache = res;
            setSettings(res);
        } catch (error: any) {
            handleError(error);
        }
    };
    const handleSettingsChange = (_settings: any) => {
        setSettings((pre) => ({ ...pre, ..._settings }));
    };

    const updateSettings = async () => {
        await updateNotificationsSettingsForResource(resource, settings);
        await refreshSettings();
        onOk?.();
    };

    const handleCancel = () => {
        setSettings({ ...SettingsCache });
        onCancel?.();
    };

    const confirm = () => {
        Modal.confirm({
            title: "Are you sure you want to update notifications?",
            icon: <ExclamationCircleOutlined />,
            content: "You can update notifications any time.",
            okText: "Yes",
            cancelText: "No",
            width: 520,
            onOk: updateSettings,
        });
    };

    useEffect(() => {
        setResource({ resourceId, resourceType });
    }, [resourceId, resourceType]);

    useEffect(() => {
        if (resource.resourceId > 0) {
            refreshSettings();
        }
    }, [resource]);

    return (
        <Modal
            title="Notifications Settings"
            open={open}
            onCancel={handleCancel}
            onOk={confirm}
            okText="Update"
        >
            <Row gutter={[8, 10]}>
                <Col span={24}>
                    <Checkbox
                        checked={settings.notifyOnMigration}
                        onChange={(e) => {
                            handleSettingsChange({ notifyOnMigration: e.target.checked });
                        }}
                    >
                        migration notification
                    </Checkbox>
                </Col>
                <Col span={24}>
                    <Checkbox
                        checked={settings.notify30Days}
                        onChange={(e) => {
                            handleSettingsChange({ notify30Days: e.target.checked });
                        }}
                    >
                        30 days before expiration
                    </Checkbox>
                </Col>
                <Col span={24}>
                    <Checkbox
                        checked={settings.notify60Days}
                        onChange={(e) => {
                            handleSettingsChange({ notify60Days: e.target.checked });
                        }}
                    >
                        60 days before expiration
                    </Checkbox>
                </Col>
                <Col span={24}>
                    <Checkbox
                        checked={settings.notify90Days}
                        onChange={(e) => {
                            handleSettingsChange({ notify90Days: e.target.checked });
                        }}
                    >
                        90 days before expiration
                    </Checkbox>
                </Col>

                <Col span={24}>
                    <hr />
                </Col>
                <Col span={24}>
                    <Checkbox
                        checked={settings.notifyGroups}
                        onChange={(e) => {
                            handleSettingsChange({ notifyGroups: e.target.checked });
                        }}
                    >
                        Send notification to user groups.
                    </Checkbox>
                </Col>
            </Row>
        </Modal>
    );
};
