import { ReactNode } from "react";
import { message } from "antd";

export const alertMessage = {
    success(content: ReactNode) {
        message.success({
            content,
            className: "alertMessage__success",
        });
    },
};
