import React from "react";
import { SupplierProduct } from "../../../types/SupplierTypes";

interface SupplierProductsProps {
    products: SupplierProduct[];
}

export const SupplierProducts: React.FC<SupplierProductsProps> = ({ products }) => {
    return (
        <ul style={{ marginTop: 8, paddingLeft: "1rem" }}>
            {products.map((item) => (
                <li key={item.id}>
                    <span style={{ fontWeight: 500 }}>{item.name}: </span>
                    {item.description}
                </li>
            ))}
        </ul>
    );
};
