import React, { useState } from "react";
import { Divider, Dropdown, Layout, Menu, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../../common/components/Avatar";

import {
  NotificationOutlined,
  FileSearchOutlined,
  SettingOutlined,
  AuditOutlined,
  FileDoneOutlined,
  ContainerOutlined,
  GlobalOutlined,
  TeamOutlined,
  DotChartOutlined,
  CarryOutOutlined,
  GroupOutlined,
  FileTextOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { useUserState } from "../../common/UserContext";
import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";
import { isAdmin, isSuperAdmin } from "../../types/UserGroup";
import SubMenu from "antd/lib/menu/SubMenu";
import { isUser } from "../../types/UserGroup";
import { geturl } from "../../common/api/SafeClient";

const { Header, Content, Footer, Sider } = Layout;

interface ScreenLayout {
  mainPanel: any;
  selected: any;
  collapsed: any;
}

const MainLayout: React.FC<ScreenLayout> = ({ mainPanel, selected, collapsed }) => {
  const navigate = useNavigate();

  const [isSelected, setIsSelected] = useState(collapsed);
  const { me } = useUserState();

  const menu = (
    <Menu onClick={() => { }} mode="horizontal">
      <Menu.Item>Hi {me.firstName} 👋</Menu.Item>
      <Menu.Item icon={<SettingOutlined />}>
        <Link to="/app/preferences"> My preferences</Link>
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />}>
        <a href={geturl("/api/logout")}>Log out</a>
      </Menu.Item>
    </Menu>
  );

  const general = isUser(me.roles || []) ? (
    <>
      <Menu.Item
        key="Dashboard"
        icon={<DotChartOutlined />}
        onClick={() => navigate("/app/dashboard")}
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        key="Calendar"
        icon={<CarryOutOutlined />}
        onClick={() => navigate("/app/calendar")}
      >
        Calendar
      </Menu.Item>
      <Menu.Item
        key="vendors"
        icon={<GroupOutlined />}
        onClick={() => navigate("/app/vendor")}
      >
        Vendors
      </Menu.Item>
      <Menu.Item
        key="Negotiations"
        icon={<AuditOutlined />}
        onClick={() => navigate("/app/negotiations")}
      >
        Negotiation intelligence
      </Menu.Item>
      <Menu.Item
        key="SupplierSheets"
        icon={<FileTextOutlined />}
        onClick={() => navigate("/app/supplier")}
      >
        Buyer Briefs
      </Menu.Item>
    </>
  ) : (
    <></>
  );

  const admin = isAdmin(me.roles || []) ? (
    <>
      <Menu.Item
        key="newOrder"
        icon={<ContainerOutlined />}
        onClick={() => navigate("/app/order/new")}
      >
        Add Forms
      </Menu.Item>
      <Menu.Item
        key="users"
        icon={<TeamOutlined />}
        onClick={() => navigate("/app/admin/users")}
      >
        Users/Groups
      </Menu.Item>
    </>
  ) : (
    <></>
  );

  const superAdminLinks = isSuperAdmin(me.roles || []) ? (
    <>
      <Divider />
      <SubMenu key="reviewMenues" icon={<FileSearchOutlined />} title="Form Reviews">
        <Menu.Item
          key={"reviewOpen"}
          icon={<AuditOutlined />}
          onClick={() => navigate("/app/review")}
        >
          Open Form Reviews
        </Menu.Item>
        <Menu.Item
          key={"reviewDone"}
          icon={<FileDoneOutlined />}
          onClick={() => navigate("/app/review/completed")}
        >
          Completed Form Reviews
        </Menu.Item>
      </SubMenu>
      <Menu.Item
        key="orgs"
        icon={<GlobalOutlined />}
        onClick={() => navigate("/app/admin/orgs")}
      >
        Organizations
      </Menu.Item>
      <Menu.Item
        key="notifications"
        icon={<NotificationOutlined />}
        onClick={() => navigate("/app/notifications")}
      >
        Notifications
      </Menu.Item>
      <Menu.Item
        key="flags"
        icon={<ToolOutlined />}
        onClick={() => navigate("/app/admin/flags")}
      >
        Feature Flags
      </Menu.Item>
    </>
  ) : (
    <></>
  );

  //TODO: Not currently needed. We may expose this again once we open reviews to regular users.
  //At present, all reviews will occur by InfinityLoop staff
  // const reviewer = isReviewer(me.roles || []) ? (
  //     <>
  //         <Divider />{" "}
  //     </>
  // ) : (
  //     <></>
  // );

  const onCollapse = () => {
    setIsSelected(!isSelected);
  };
  return (
    <Spin spinning={me.email === undefined}>
      <div style={{ minWidth: 1400 }}>
        <Header className="site-layout-background" style={{
            padding: 0,
            display: 'flex',
            alignItems: 'center'
          }}>
          <Link to="/app/dashboard">
            <img
              src="/infinity-loop-app-logo-full.svg"
              alt="logo"
              style={{
                width: "230px",
                paddingLeft: "16px",
                // paddingTop: "20px",
                float: "left",
              }}
            />
          </Link>

          <div
            style={{
              width: "100%",
              textAlign: "right",
              paddingRight: "30px",
              fontSize: "20px",
            }}
          >
            {/* <BellOutlined style={{ color: "white", paddingRight: "30px" }} />*/}
            <Dropdown overlay={menu}>
              <a href="https://example.com" onClick={(e) => e.preventDefault()}>
                <Avatar email={me.email} />
              </a>
            </Dropdown>
          </div>
        </Header>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider collapsible collapsed={isSelected} onCollapse={onCollapse} theme="light" className="sidebar">
            <Menu theme="light" defaultSelectedKeys={[selected]} mode="inline">
              {general}
              <Divider />
              <Menu.Item
                key="preferences"
                icon={<SettingOutlined />}
                onClick={() => navigate("/app/preferences")}
              >
                Preferences
              </Menu.Item>
              {admin}
              {superAdminLinks}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Content style={{ margin: "0 16px", minHeight: 800 }}>
              {mainPanel}
            </Content>
            <Footer style={{ textAlign: "center" }}>InfinityLoop, Inc &copy;</Footer>
          </Layout>
        </Layout>
      </div>
    </Spin>
  );
};

export default MainLayout;
