import React from "react";
import { Card } from "antd";
import MainLayout from "../navigation/MainLayout";
import { FeatureFlagTable } from "./components/FeatureFlagTable";

export const FeatureFlagsView: React.FC = () => {
    const content = (
        <div style={{ paddingTop: 10 }}>
            <Card title="Feature Flags">
                <FeatureFlagTable></FeatureFlagTable>
            </Card>
        </div>
    );
    return <MainLayout mainPanel={content} selected="flags" collapsed={true}></MainLayout>;
};
