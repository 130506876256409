import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

interface BreadcrumbItem {
    name: string;
    path: string;
}

interface BreadcrumbsBarProps {
    breadcrumbs: BreadcrumbItem[];
}
export const BreadcrumbsBar: React.FC<BreadcrumbsBarProps> = ({ breadcrumbs }) => {
    return (
        <Breadcrumb>
            {breadcrumbs.map((item) => (
                <Breadcrumb.Item key={item.path}>
                    <Link to={item.path}>{item.name}</Link>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};
