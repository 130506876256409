import React, { CSSProperties } from "react";
import { Company } from "../../../types/DashboardTypes";
import { CompanyList, getCompanyLayout } from "./CompanyList";
import "./IndustryCard.css";

interface IndustryCardProps {
    size?: "large" | "middle" | "small";
    style?: CSSProperties;
    name: string;
    total: number;
    companies: Company[];
    isActive?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const IndustryCard: React.FC<IndustryCardProps> = ({
    size = "large",
    style,
    name,
    total,
    companies,
    isActive,
    onClick,
}) => {
    const layoutMap = {
        large: {
            height: 244,
            layoutType: "loose" as const,
        },
        middle: {
            height: 178,
            layoutType: "compact" as const,
        },
        small: {
            height: 112,
            layoutType: "compact" as const,
        },
    };

    const layout = layoutMap[size];
    const colorMap = [
        { min: 1e6, color: "#D33833" },
        { min: 5e5, color: "#F97539" },
        { min: 1e5, color: "#9ABA6E" },
        { min: 1e4, color: "#649D3E" },
        { min: 1e3, color: "#4D4D4D" },
        { min: 0, color: "#273A58" },
    ];

    const color = colorMap.find((item) => item.min <= total)?.color;

    const cardClass = isActive ? "activeIndustryCard" : undefined;

    return (
        <div style={{ color: "#fff", ...style }} onClick={onClick}>
            <div style={{ marginBottom: 4, position: "relative", zIndex: 1 }}>{name}</div>
            <div
                className={cardClass}
                style={{
                    height: layout.height,
                    borderRadius: 4,
                    padding: 12,
                    background: "rgba(255, 255, 255, 0.08)",
                    fontWeight: 500,
                    position: "relative",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        filter: "blur(4rem)",
                        borderRadius: 100,
                        background: color,
                    }}
                ></div>
                <CompanyList
                    layoutType={layout.layoutType}
                    columns={getCompanyLayout(companies, size === "small")}
                />
            </div>
        </div>
    );
};
