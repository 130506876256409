import React, { useState } from "react";
import { Button, Select, Space } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { CommentsPanel } from "../comments/CommentsPanel";
import { Negotiation, NegotiationStatus } from "../../types/NegotiationTypes";
import { ResourceType } from "../../types/ResourceType";
import { negotiationStatusToString } from "./NegotiationStatusTag";

interface NegotiationDetailToolboxProps {
    negotiation: Negotiation;
    onStatusChange?: (status: NegotiationStatus) => void;
}

export const NegotiationDetailToolbox: React.FC<NegotiationDetailToolboxProps> = ({
    negotiation,
    onStatusChange,
}) => {
    const [showMessages, setShowMessages] = useState(false);

    const messagesDrawer = (
        <>
            {negotiation.id && (
                <CommentsPanel
                    resourceId={negotiation.id}
                    visible={showMessages}
                    resourceType={ResourceType.NEGOTIATION}
                    onClose={() => setShowMessages(false)}
                />
            )}
        </>
    );
    return (
        <>
            {messagesDrawer}
            <Space size={10}>
                <Select value={negotiation.status} onSelect={onStatusChange}>
                    {Object.values(NegotiationStatus).map((status) => {
                        return (
                            <Select.Option value={status}>
                                {negotiationStatusToString(status)}
                            </Select.Option>
                        );
                    })}
                </Select>

                <Button
                    size={"small"}
                    onClick={() => {
                        setShowMessages(true);
                    }}
                >
                    <MessageOutlined />
                </Button>
            </Space>
        </>
    );
};
