import React, { PropsWithChildren } from "react";

interface PdfPageProps {
    page?: number;
    total?: number;
}

export const PdfPage: React.FC<PropsWithChildren<PdfPageProps>> = ({ children, page, total }) => {
    const totalPages = total ? `/${total}` : "";
    const pagination = page ? `${page}${totalPages}` : "";
    return (
        <div
            style={{
                position: "relative",
                width: "400mm",
                height: "565mm",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: "1rem",
            }}
        >
            <div
                data-testid="pdf-logo-header"
                style={{
                    backgroundColor: "#282c34",
                    padding: "20px 16px",
                }}
            >
                <img
                    src="/infinity-loop-app-logo-full.svg"
                    alt="logo"
                    style={{
                        width: "230px",
                    }}
                />
            </div>
            <div style={{ flex: 1 }}>{children}</div>
            <div style={{ textAlign: "center", position: "relative" }}>
                InfinityLoop, Inc &copy;
                <span style={{ position: "absolute", right: 0 }}>{pagination}</span>
            </div>
        </div>
    );
};
