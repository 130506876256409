import React from "react";
import { Col, Row } from "antd";
import { formatNumber } from "../../../common/Number";
import { Company } from "../../../types/DashboardTypes";

interface CompanyItemProps extends Company {
    layoutType?: "loose" | "compact";
    size?: "large" | "middle" | "small";
}

export const CompanyItem: React.FC<CompanyItemProps> = ({
    layoutType = "loose",
    size = "middle",
    name,
    value,
}) => {
    const layoutTypeMap = {
        loose: {
            large: {
                name: 20,
                value: 16,
            },
            middle: {
                name: 16,
                value: 12,
            },
            small: {
                name: 14,
                value: 10,
            },
        },
        compact: {
            large: {
                name: 20,
                value: 16,
            },
            middle: {
                name: 14,
                value: 10,
            },
            small: {
                name: 12,
                value: 10,
            },
        },
    };
    const sizeMap = layoutTypeMap[layoutType];
    const sizeStyle = sizeMap[size];

    return (
        <div style={{ textAlign: "center", lineHeight: 1 }}>
            <div style={{ fontSize: sizeStyle.name }}>{name}</div>
            <div style={{ fontSize: sizeStyle.value }}>{formatNumber(value)}</div>
        </div>
    );
};

type CompanyLayout = Array<Array<CompanyItemProps[]>>;

export const getCompanyLayout = (
    companies: Company[],
    isCompact = false
): CompanyLayout | undefined => {
    switch (companies.length) {
        case 1:
            return [
                [
                    [
                        {
                            ...companies[0],
                            size: "large",
                        },
                    ],
                ],
            ];
        case 2:
            return [
                [
                    [
                        {
                            ...companies[0],
                            size: "large",
                        },
                    ],
                ],
                [
                    [
                        {
                            ...companies[1],
                            size: "large",
                        },
                    ],
                ],
            ];
        case 3:
            return [
                [
                    [
                        {
                            ...companies[0],
                            size: "large",
                        },
                    ],
                ],
                [
                    [
                        {
                            ...companies[1],
                        },
                    ],
                    [
                        {
                            ...companies[2],
                        },
                    ],
                ],
            ];
        case 4:
            return [
                [
                    [
                        {
                            ...companies[0],
                            size: "large",
                        },
                    ],
                ],
                [
                    [
                        {
                            ...companies[1],
                        },
                    ],
                    [
                        {
                            ...companies[2],
                        },
                    ],
                    [
                        {
                            ...companies[3],
                        },
                    ],
                ],
            ];
        case 5:
            return isCompact
                ? [
                      [
                          [
                              {
                                  ...companies[0],
                                  size: "large",
                              },
                          ],
                      ],
                      [
                          [
                              {
                                  ...companies[1],
                              },
                          ],
                          [
                              {
                                  ...companies[2],
                              },
                          ],
                          [
                              {
                                  ...companies[3],
                                  size: "small",
                              },
                              {
                                  ...companies[4],
                                  size: "small",
                              },
                          ],
                      ],
                  ]
                : [
                      [
                          [
                              {
                                  ...companies[0],
                                  size: "large",
                              },
                          ],
                          [
                              {
                                  ...companies[1],
                                  size: "large",
                              },
                          ],
                      ],
                      [
                          [
                              {
                                  ...companies[2],
                              },
                          ],
                          [
                              {
                                  ...companies[3],
                              },
                          ],
                          [
                              {
                                  ...companies[4],
                              },
                          ],
                      ],
                  ];
        case 6:
            return isCompact
                ? [
                      [
                          [
                              {
                                  ...companies[0],
                                  size: "large",
                              },
                          ],
                          [
                              {
                                  ...companies[1],
                                  size: "large",
                              },
                          ],
                      ],
                      [
                          [
                              {
                                  ...companies[2],
                              },
                          ],
                          [
                              {
                                  ...companies[3],
                              },
                          ],
                          [
                              {
                                  ...companies[4],
                                  size: "small",
                              },
                              {
                                  ...companies[5],
                                  size: "small",
                              },
                          ],
                      ],
                  ]
                : [
                      [
                          [
                              {
                                  ...companies[0],
                                  size: "large",
                              },
                          ],
                      ],
                      [
                          [
                              {
                                  ...companies[1],
                              },
                          ],
                          [
                              {
                                  ...companies[2],
                              },
                          ],
                          [
                              {
                                  ...companies[3],
                              },
                          ],
                          [
                              {
                                  ...companies[4],
                                  size: "small",
                              },
                              {
                                  ...companies[5],
                                  size: "small",
                              },
                          ],
                      ],
                  ];
        case 7:
            return [
                [
                    [
                        {
                            ...companies[0],
                            size: "large",
                        },
                    ],
                    [
                        {
                            ...companies[1],
                            size: "large",
                        },
                    ],
                ],
                [
                    [
                        {
                            ...companies[2],
                        },
                    ],
                    [
                        {
                            ...companies[3],
                        },
                    ],
                    [
                        {
                            ...companies[4],
                        },
                    ],
                    [
                        {
                            ...companies[5],
                            size: "small",
                        },
                        {
                            ...companies[6],
                            size: "small",
                        },
                    ],
                ],
            ];
        default:
            return;
    }
};

interface CompanyListProps {
    columns?: CompanyLayout;
    layoutType?: "compact" | "loose";
}

export const CompanyList: React.FC<CompanyListProps> = ({ columns, layoutType = "loose" }) => {
    if (!columns) return null;
    return (
        <Row style={{ height: "100%" }}>
            {columns.map((col, colIndex) => (
                <Col span={24 / columns.length} key={colIndex}>
                    {col.map((row, rowIndex) => (
                        <Row style={{ height: `calc(100% / ${col.length}` }} key={rowIndex}>
                            {row.map((item, index) => (
                                <Col
                                    span={24 / row.length}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    key={index}
                                >
                                    <CompanyItem {...item} layoutType={layoutType} />
                                </Col>
                            ))}
                        </Row>
                    ))}
                </Col>
            ))}
        </Row>
    );
};
