import { Card, Descriptions, Button, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getUpload } from "../../common/api/UploadApi";
import {
    DocumentArchive,
    DocumentDataInstance,
    GetUploadDocumentResponse,
    UploadType,
} from "../../types/Document";
import { formatDetailTimeString } from "../date/DateOp";
import MainLayout from "../navigation/MainLayout";

interface FilePanelArgs {
    type: UploadType;
    file: DocumentArchive | DocumentDataInstance;
}
const FilePanel: React.FC<FilePanelArgs> = ({ type, file }) => {
    const items = [];
    items.push(
        <Descriptions.Item label="File name" key={`name-${file.uploadId}-${file.filename}`}>
            {file.filename}
        </Descriptions.Item>
    );
    if (type === UploadType.ZIP) {
        const archive = file as DocumentArchive;
        items.push(
            <Descriptions.Item label="Status" key={`status-${file.uploadId}-${file.filename}`}>
                {archive.status}
            </Descriptions.Item>
        );
        items.push(
            <Descriptions.Item label="Upload date" key={`date-${file.uploadId}-${file.filename}`}>
                {formatDetailTimeString(archive.uploadDate)}
            </Descriptions.Item>
        );
        const childrenFile = archive.files?.map((f) => (
            <FilePanel key={`${f.documentSerialId}`} type={UploadType.PDF} file={f} />
        ));
        items.push(
            <Descriptions.Item label="Files" key={`files-${file.uploadId}`}>
                <div>{childrenFile}</div>
            </Descriptions.Item>
        );
    } else {
        const instance = file as DocumentDataInstance;
        items.push(
            <Descriptions.Item label="Order Form" key={`of-${instance.documentSerialId}`}>
                <Link to={`/app/order/${instance.orderFormId}`}>See order Form</Link>
            </Descriptions.Item>
        );
        items.push(
            <Descriptions.Item label="Upload Date" key={`date-${instance.documentSerialId}`}>
                {formatDetailTimeString(instance.creationDate)}
            </Descriptions.Item>
        );
    }

    return <Descriptions bordered>{items}</Descriptions>;
};

const SummaryView: React.FC = () => {
    const params = useParams();
    const [uploadId] = useState(Number(params.uploadId));
    const [documentUpload, setDocumentUpload] = useState({} as GetUploadDocumentResponse);
    useEffect(() => {
        const fn = async () => {
            const _documentUpload = await getUpload(uploadId);
            setDocumentUpload(_documentUpload);
        };
        fn();
    }, [uploadId]);

    const files = documentUpload?.files?.map((f) => (
        <FilePanel
            key={`upload-${f.uploadId}`}
            type={documentUpload?.upload?.uploadType}
            file={f}
        />
    ));

    const panel = (
        <div className="contract-panel" style={{ paddingTop: 10 }}>
            <Card>
                <div className="uploadSummary">
                    Upload Summary - {formatDetailTimeString(documentUpload?.upload?.uploadDate)}
                </div>
                <div>{files}</div>
            </Card>
            <Row align={"middle"} justify={"center"}>
                <Col span={24}>
                    <div style={{ textAlign: "center", padding: 20 }}>
                        <Button type={"primary"} size={"large"}>
                            <Link to={"/app/order/new/"}>Add More Forms</Link>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );

    return <MainLayout collapsed={true} selected={"neworder"} mainPanel={panel} />;
};

export default SummaryView;
