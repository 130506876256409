import React, { CSSProperties, useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { VendorHeatmap } from "./components/VendorHeatmap";
import DatePeriodPicker from "../date/DatePeriodPicker";
import { PeriodDates } from "../date/TimePeriods";
import { getIndustryStatistics } from "../../common/api/DashboardClientV2";
import { handleError } from "../../common/ErrorHandling";
import { Industry, IndustryStatistics, Sector } from "../../types/DashboardTypes";

interface VendorHeatmapPanelProps {
    style?: CSSProperties;
}

const formatteHeatmapData = (industries: IndustryStatistics[]): Sector[] => {
    const industryMap = {} as Record<string, Industry>;
    for (const industry of industries) {
        if (!industryMap[industry.industryName]) {
            industryMap[industry.industryName] = {
                sectorName: industry.sectorName,
                name: industry.industryName,
                value: industry.industryTotal,
                companies: [],
            };
        }
        industryMap[industry.industryName].companies.push({
            name: industry.vendorName,
            value: industry.vendorTotal,
            esgScore: industry.esgScore,
            simulationScore: industry.simulationScore,
        });
    }

    const sectorMap = {} as Record<string, Sector>;
    for (const industry of Object.values(industryMap)) {
        if (!sectorMap[industry.sectorName]) {
            sectorMap[industry.sectorName] = {
                name: industry.sectorName,
                industries: [],
            };
        }
        sectorMap[(industry as any).sectorName].industries.push(industry);
    }

    return Object.values(sectorMap);
};

export const VendorHeatmapPanel: React.FC<VendorHeatmapPanelProps> = ({ style }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dates, setDates] = useState({} as PeriodDates);
    const [sectors, setSectors] = useState([] as Sector[]);

    const refreshData = async () => {
        if (isFetching) return;
        if (!dates.startDate || !dates.endDate) return;

        setIsFetching(true);
        setLoading(true);

        try {
            const data = await getIndustryStatistics(dates);
            setSectors(formatteHeatmapData(data));
        } catch (error) {
            handleError(error);
        } finally {
            setIsFetching(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshData();
    }, [dates]);

    return (
        <div style={style}>
            <Row align="middle" style={{ marginBottom: 20 }}>
                <Col flex={1}>
                    <div className="sectionTitle">Vendor heatmap</div>
                    <div className="sectionSubtitle">
                        Total value/orders categorized by sectors and industries.
                    </div>
                </Col>
                <Col>
                    <DatePeriodPicker
                        onPeriodChange={(startDate: Date, endDate: Date) =>
                            setDates({ startDate, endDate })
                        }
                    />
                </Col>
            </Row>
            <Spin spinning={loading}>
                <VendorHeatmap sectors={sectors} />
            </Spin>
        </div>
    );
};
