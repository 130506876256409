import React, { useState } from "react";
import { Col, Row } from "antd";
import { IndustryCard } from "./IndustryCard";
import { Industry, Sector } from "../../../types/DashboardTypes";
import { IndustryModal } from "./IndustryModal";

const getSectorLayout = (sectors: Sector[]) => {
    const slicedSectors = sectors.map((sector, index) => {
        if (index === 0) {
            return {
                ...sector,
                industries: sector.industries.slice(0, 4).map((industry) => ({
                    ...industry,
                    companies: industry.companies.slice(0, 7),
                })),
            };
        }
        return {
            ...sector,
            industries: sector.industries.slice(0, 2).map((industry) => ({
                ...industry,
                companies: industry.companies.slice(0, 6),
            })),
        };
    });

    switch (slicedSectors.length) {
        case 1:
            return [[slicedSectors[0]]];
        default:
            return [[slicedSectors[0]], [...slicedSectors.slice(1, 4)]];
    }
};

interface VendorHeatmapProps {
    sectors: Sector[];
}

export const VendorHeatmap: React.FC<VendorHeatmapProps> = ({ sectors }) => {
    const [showIndustryModal, setShowIndustryModal] = useState(false);
    const [industry, setIndustry] = useState({} as Industry);
    const [activeIndustry, setActiveIndustry] = useState("");

    if (!sectors.length) return null;
    const columns = getSectorLayout(sectors);

    const openIndustryModal = (data: Industry, key: string) => {
        setActiveIndustry(key);
        setIndustry(data);
        setShowIndustryModal(true);
    };

    const closeIndustryModal = () => {
        setActiveIndustry("");
        setShowIndustryModal(false);
    };

    return (
        <Row
            gutter={18}
            style={{
                borderRadius: 4,
                background: "#273A58",
                padding: 18,
                paddingBottom: 2,
                color: "#fff",
                overflow: "clip",
            }}
        >
            {columns.map((col, colIndex) => (
                <Col span={24 / columns.length} key={colIndex}>
                    {col.map((row, rowIndex) => (
                        <div key={rowIndex}>
                            <div style={{ fontWeight: 700, position: "relative", zIndex: 1 }}>
                                {row.name}
                            </div>
                            <Row gutter={16}>
                                {row.industries.map((item, index) => (
                                    <Col span={row.industries.length === 1 ? 24 : 12} key={index}>
                                        <IndustryCard
                                            size={
                                                colIndex < 1
                                                    ? index > 1
                                                        ? "middle"
                                                        : undefined
                                                    : "small"
                                            }
                                            name={item.name}
                                            total={item.value}
                                            companies={item.companies}
                                            style={{ marginBottom: 16 }}
                                            isActive={
                                                activeIndustry ===
                                                `${colIndex}-${rowIndex}-${index}`
                                            }
                                            onClick={() =>
                                                openIndustryModal(
                                                    item,
                                                    `${colIndex}-${rowIndex}-${index}`
                                                )
                                            }
                                        ></IndustryCard>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                </Col>
            ))}
            <IndustryModal
                open={showIndustryModal}
                industry={industry}
                onCancel={closeIndustryModal}
            />
        </Row>
    );
};
