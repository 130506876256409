import React, { CSSProperties } from "react";
import { Space, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SupplierInsight } from "../../../types/SupplierTypes";

interface InsightsCardProps {
    insights: SupplierInsight[];
    tip?: string;
    style?: CSSProperties;
}

export const InsightsCard: React.FC<InsightsCardProps> = ({ insights, tip, style }) => {
    return (
        <div style={style}>
            <div style={{ marginBottom: 8, fontWeight: 500, fontSize: 16 }}>
                <Space>
                    Insights
                    <Tooltip title={tip}>
                        <ExclamationCircleOutlined
                            style={{ color: "#1890FF " }}
                            data-testid="supplier-insights-card-tip"
                        />
                    </Tooltip>
                </Space>
            </div>
            <div
                style={{
                    borderRadius: 4,
                    background: "#F6F7F9",
                    padding: "12px 16px",
                    lineHeight: 1.75,
                }}
            >
                <ul style={{ paddingLeft: "1rem", marginBottom: 0 }}>
                    {insights.map((item) => (
                        <li key={item.id}>
                            <span style={{ fontWeight: 500 }}>{item.title}: </span>
                            {item.content}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
