import React, { useState } from "react";
import { Button, Col, Input, Row, Space } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

interface EditableCellProps {
    content: string;
    showEdit?: boolean;
    onClick?: () => void;
    onCancel?: () => void;
    onOk?: (value: string) => void;
}
export const EditableCell: React.FC<EditableCellProps> = ({
    showEdit = false,
    content,
    onClick,
    onCancel,
    onOk,
}) => {
    const [value, setValue] = useState(content);

    const handleCancel = () => {
        setValue(content);
        onCancel && onCancel();
    };

    return (
        <>
            {showEdit ? (
                <Space>
                    <Input value={value} onChange={(e) => setValue(e.target.value)}></Input>
                    <Button
                        danger
                        type="link"
                        size="small"
                        icon={<CloseOutlined />}
                        onClick={handleCancel}
                    ></Button>
                    <Button
                        type="link"
                        size="small"
                        icon={<CheckOutlined style={{ color: "#52c41a" }} />}
                        onClick={() => onOk && onOk(value)}
                    ></Button>
                </Space>
            ) : (
                <Row align="middle">
                    <Col flex={1}>{content}</Col>
                    <Col>
                        <Button
                            type="text"
                            size="small"
                            icon={<EditOutlined />}
                            onClick={onClick}
                        ></Button>
                    </Col>
                </Row>
            )}
        </>
    );
};
