import React from "react";
import { Spin } from "antd";

export const FullPanelSpin = () => {
    return (
        <Spin wrapperClassName="fullPanelSpin" data-testid="full-panel-spin">
            <div></div>
        </Spin>
    );
};
