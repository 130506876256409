import Gravatar from "react-gravatar";

interface AvatarProps {
    email?: string;
}

const Avatar: React.FC<AvatarProps> = (props) => {
    return <Gravatar email={props.email} default="identicon" />;
};

export default Avatar;
