import { Skeleton, Tag, Popover, AutoComplete } from "antd";
import { Tag as ILTag } from "../../types/TagTypes";
import { PlusOutlined } from "@ant-design/icons";
import { createRef, useEffect, useState } from "react";
import { getTags, loadTagSuggestions, addTag, removeTag } from "../../common/api/TagClient";
import { ResourceType } from "../../types/ResourceType";
import { CompactPicker } from "react-color";
import { Errors } from "../../types/Error";
import { handleError } from "../../common/ErrorHandling";
import { BaseSelectRef } from "rc-select";

interface TagArguments {
    resourceId: number;
    resourceType: ResourceType;
    readOnly?: boolean;
}

export const TagPanel: React.FC<TagArguments> = ({ resourceId, resourceType, readOnly }) => {
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([] as ILTag[]);
    const [showInput, setShowInput] = useState(false);
    const [tagValue, setTagValue] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [tagColor, setTagColor] = useState("");
    const [tagOptions, setTagOptions] = useState(new Map<string, ILTag>());
    const inputRef = createRef<BaseSelectRef>();

    const ro = readOnly || false;

    useEffect(() => {
        setLoading(true);
        getTags([resourceId], resourceType)
            .then((map) => {
                const _tags = Array.from(map.values()).reduce(
                    (p, v) => p.concat(v.tags),
                    [] as ILTag[]
                );
                setTags(_tags);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (showInput) {
            inputRef.current?.focus();
        }
    }, [showInput]);

    useEffect(() => {
        setLoading(true);
        try {
            if (tagValue.length === 0) {
                return;
            }
            if (tagValue.length < 3) {
                throw Errors.UI_ERROR.createNew(
                    "The tag name should be at least 3 characters long"
                );
            }
            const found = tags.filter((t) => t.name === tagValue);
            if (found.length > 0) {
                throw Errors.UI_ERROR.createNew("Please pick a new tag");
            }
            // if (tagValue.indexOf(" ") !== -1) {
            //     throw Errors.UI_ERROR.createNew("The tag name should not contain spaces");
            // }
            addTag(
                {
                    resourceId: resourceId,
                    resourceType: resourceType,
                },
                {
                    name: tagValue,
                    color: tagColor,
                } as ILTag
            )
                .then((tag) => {
                    setTags(tags.concat([tag]));
                })
                .catch((error) => {
                    handleError(error);
                });
        } catch (error: any) {
            handleError(error);
        } finally {
            setShowInput(false);
            setLoading(false);
            setTagValue("");
            setSearchValue("");
        }
    }, [tagValue]);

    const handleComplete = (match: string) => {
        loadTagSuggestions(match).then((tags) => {
            setTagOptions(new Map(tags.map((v) => [v.name, v])));
        });
    };

    const handleCloseTag = (e: any, tag: ILTag) => {
        removeTag(
            {
                resourceId: resourceId,
                resourceType: resourceType,
            },
            tag
        )
            .then((_rtag) => {
                //              const stag = tags.filter(utag => tag.name === utag.name)[0];
                //                const tagIndex = tags.indexOf(stag)
                // // setTags(newTags.concat([]));
            })
            .catch((error) => {
                e.preventDefault();
                handleError(error);
            });
    };

    const dispalyTags = tags.map((v) => (
        <Tag
            key={v.name}
            color={v.color}
            closable={!ro}
            onClose={(e) => {
                handleCloseTag(e, v);
            }}
        >
            {v.name}
        </Tag>
    ));
    const addTagEL = showInput ? (
        <>
            <Popover
                placement="bottomRight"
                
                title={searchValue}
                content={
                    <CompactPicker
                        onChange={(color) => {
                            setTagColor(color.hex);
                        }}
                    />
                }
                visible={true}
            >
                <Tag className="site-tag-plus" color={tagColor} key={"searchpaview"}>
                    {searchValue}
                </Tag>
                <AutoComplete
                    size={"small"}
                    options={Array.from(tagOptions.values()).map((v) => {
                        return {
                            value: v.name,
                            label: (
                                <Tag key={v.name} color={v.color}>
                                    {v.name}
                                </Tag>
                            ),
                        };
                    })}
                    style={{ width: 75 }}
                    ref={inputRef}
                    onSelect={(value: any) => {
                        setTagValue(value as string);
                    }}
                    onSearch={(e) => {
                        setSearchValue(e);
                        handleComplete(e);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Escape") {
                            setSearchValue("");
                            setShowInput(false);
                        } else if (e.key === "Enter") {
                            setTagValue(searchValue);
                        }
                    }}
                    placeholder={"New Tag"}
                />
            </Popover>
        </>
    ) : (
        <Tag
            className="site-tag-plus"
            onClick={() => {
                setShowInput(true);
            }}
        >
            <PlusOutlined /> New Tag
        </Tag>
    );

    return (
        <Skeleton loading={loading}>
            {dispalyTags}
            {ro ? "" : addTagEL}
        </Skeleton>
    );
};
