import { Row, Col, Table, Input, Image, Space, Button, Tag, InputRef } from "antd";
import React, { createRef, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { listVendors } from "../../common/api/VendorClient";
import { SortFilter } from "../../common/CommonInterfaces";
import { handleError } from "../../common/ErrorHandling";
import { SearchOutlined } from "@ant-design/icons";
import {
    Filter,
    FilterGroupType,
    FilterOperand,
    Pagination,
    Sort,
    sortOrderTranslate,
} from "../../types/DataFetchingTypes";
import MainLayout from "../navigation/MainLayout";
import { getAllVendorTags, getTags } from "../../common/api/TagClient";
import { ResourceType } from "../../types/ResourceType";
import { Tag as TagType, TaggedResource } from "../../types/TagTypes";
import { Vendor } from "../../types/VendorTypes";
import { convertTagListToFilter } from "../tag/TagTranslation";

const { Column } = Table;

export const VendorsView: React.FC = () => {
    const [data, setData]: any[] = useState([]);
    const [pagination, setPagination] = useState({
        pages: 0,
        current: 1,
        total: 0,
        pageSize: 10,
    } as Pagination);

    const [sortFilter, setSortFilter] = useState({ sort: [], filter: [] } as SortFilter);
    const [tags, setTags] = useState(new Map<number, TaggedResource>());
    const [loading, setLoading]: any[] = useState(true);
    const [reload, setReload]: any[] = useState(0);
    const [tagFilter, setTagFilter] = useState([] as string[]);
    const [allVendorTags, setAllVendorTags] = useState([] as TagType[]);

    const searchRef = createRef<InputRef>();

    const handleTableChange = (pagination: any, filters_: any, sorter: any) => {
        setPagination(pagination);

        const fs = { filter: [], sort: [] } as SortFilter;

        if (sorter.columnKey !== undefined) {
            const sort = {
                attribute: sorter.columnKey,
                order: sortOrderTranslate(sorter.order),
            } as Sort;
            if (sorter.columnKey.indexOf(".") !== -1) {
                sort.prefix = sorter.columnKey.split(".")[0];
                sort.attribute = sorter.columnKey.split(".")[1];
            }
            fs.sort = [sort];
        }
        if (filters_.status) {
        }
        if (filters_.name) {
            const names = filters_.name.map((v: string) => {
                return {
                    attribute: "name",
                    operand: FilterOperand.SIMILAR,
                    value: v,
                } as Filter;
            });
            const nameSearch = {
                groupId: "name",
                groupType: FilterGroupType.AND,
                filter: names,
            };
            fs.filter.push(nameSearch);
        }
        if (filters_.tags) {
            setTagFilter(filters_.tags);
        } else {
            setTagFilter([]);
        }
        setSortFilter(fs);
        setReload(reload + 1);
    };

    const handleNameSearch = async (_selectedKeys: any, confirm: any) => {
        confirm({ closeDropdown: true });
    };

    const handleReset = (clearFilter: any) => {
        clearFilter();
    };

    const getAllTags = async () => {
        const data = await getAllVendorTags();
        setAllVendorTags(data);
    };

    useEffect(() => {
        setLoading(true);
        listVendors(pagination, sortFilter.sort, sortFilter.filter, tagFilter)
            .then(async (pager) => {
                if (pager.pagination.total > 0) {
                    const [tagged] = await Promise.all([
                        getTags(
                            pager.data.map((v) => v.id),
                            ResourceType.Vendor
                        ),
                    ]);
                    setTags(tagged);
                }

                setData(pager.data);
                setPagination(pager.pagination);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setLoading(false);
            });

        getAllTags();
    }, [reload]);

    const vendorTable = (
        <Table
            dataSource={data}
            pagination={pagination}
            loading={loading}
            rowKey="vendorKey"
            onChange={handleTableChange}
        >
            <Column
                title=""
                dataIndex="hero"
                render={(value) => {
                    return <Image width={40} preview={false} src={value} />;
                }}
            />
            <Column
                title="Vendor Name"
                sorter={true}
                dataIndex="name"
                key="name"
                render={(value, row: Vendor) => {
                    return <Link to={`/app/vendor/${row.vendorKey}`}>{value}</Link>;
                }}
                filterIcon={<SearchOutlined />}
                filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                    return (
                        <div style={{ padding: 8 }}>
                            <Input
                                ref={searchRef}
                                placeholder={`Search `}
                                value={selectedKeys[0]}
                                onChange={(e) =>
                                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                                }
                                onPressEnter={() => {
                                    handleNameSearch(selectedKeys, confirm);
                                }}
                                style={{ marginBottom: 8, display: "block" }}
                            />
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={() => handleNameSearch(selectedKeys, confirm)}
                                    icon={<SearchOutlined />}
                                    size="small"
                                    style={{ width: 90 }}
                                >
                                    Search
                                </Button>
                                <Button
                                    onClick={() => handleReset(clearFilters)}
                                    size="small"
                                    style={{ width: 90 }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        confirm({ closeDropdown: true });
                                    }}
                                >
                                    Close
                                </Button>
                            </Space>
                        </div>
                    );
                }}
            />
            <Column
                title="Renewal value"
                sorter={true}
                key="metrics.value"
                dataIndex="metrics.value"
                render={(_value, row: Vendor) => {
                    return (
                        <NumberFormat
                            value={row.metrics.value}
                            thousandSeparator={true}
                            displayType={"text"}
                            prefix={"$"}
                        />
                    );
                }}
            />
            <Column
                title="Orders for renewal"
                sorter={true}
                key="metrics.renewalOrders"
                dataIndex="metrics.renewalOrders"
                render={(_value, row: Vendor) => {
                    return <>{row.metrics.renewalOrders}</>;
                }}
            />
            <Column
                title="Total orders"
                sorter={true}
                key="metrics.orders"
                dataIndex="metrics.orders"
                render={(_value, row: Vendor) => {
                    return <>{row.metrics.orders}</>;
                }}
            />
            <Column
                title="Tags"
                dataIndex="tags"
                key="tags"
                filters={convertTagListToFilter(allVendorTags)}
                render={(_value, row: Vendor) => {
                    return (
                        <>
                            {tags.get(row.id)?.tags.map((t) => (
                                <Tag key={`${t.name}-tags-${row.id}-${t.color}`} color={t.color}>
                                    {t.name}
                                </Tag>
                            ))}
                        </>
                    );
                }}
            />
        </Table>
    );

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <Row justify={"center"}>
                <Col span={24}>{vendorTable}</Col>
            </Row>
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"vendors"} collapsed={true} />;
};
