import { NotificationStatus } from "../../types/NotificationTypes";
import {
    NotificationOutlined,
    CalendarOutlined,
    FieldTimeOutlined,
    CarryOutOutlined,
    StopOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

export function statusToIcon(status: NotificationStatus) {
    switch (status) {
        case NotificationStatus.SENT:
            return <NotificationOutlined />;
        case NotificationStatus.SCHEDULED:
            return <CalendarOutlined />;
        case NotificationStatus.SNOOZED:
            return <FieldTimeOutlined />;
        case NotificationStatus.ACKNOWDELGED:
            return <CarryOutOutlined />;
        case NotificationStatus.DISABLED:
            return <StopOutlined />;
        default:
            return <></>;
    }
}

export function statusToTag(status: NotificationStatus | undefined) {
    switch (status) {
        case NotificationStatus.SENT:
            return <Tag color={"cyan"}>{statusToIcon(status)}&nbsp;&nbsp;Notification Active</Tag>;
        case NotificationStatus.SCHEDULED:
            return <Tag color={"cyan"}>{statusToIcon(status)}&nbsp;&nbsp;Notification Scheduled</Tag>;
        case NotificationStatus.SNOOZED:
            return <Tag color={"yellow"}>{statusToIcon(status)}&nbsp;&nbsp;Notification Snoozed</Tag>;
        case NotificationStatus.ACKNOWDELGED:
            return <Tag color={"green"}>{statusToIcon(status)}&nbsp;&nbsp;Notification Acknowledged</Tag>;
        case NotificationStatus.DISABLED:
            return <Tag color={"magenta"}>{statusToIcon(status)}&nbsp;&nbsp;Notification Disabled</Tag>;
        default:
            return <Tag color={"yellow"}>None</Tag>;
    }
}

export const statusToString=(status:NotificationStatus)=>{
    switch (status) {
        case NotificationStatus.SENT:
            return "Notification Active";
        case NotificationStatus.SCHEDULED:
            return "Notification Scheduled";
        case NotificationStatus.SNOOZED:
            return "Notification Snoozed";
        case NotificationStatus.ACKNOWDELGED:
            return "Notification Acknowledged";
        case NotificationStatus.DISABLED:
            return "Notification Disabled";
        default:
            return "None";
    }

}

export const notificationFilterStatus = Object.values(NotificationStatus).map(v => {
    return {
        text: statusToString(v as NotificationStatus),
        value: v
    }
});

