export const DefaultTimezone = "America/New_York";

export enum TimeUnit {
    YEAR = "year",
    QUARTER = "quarter",
    MONTH = "month",
    WEEK = "week",
    DAY = "day",
}

export const getFourQuarters = (__date: Date): TimeQuarter[] => {
    const quarters = [] as TimeQuarter[];
    for (let m = 0; m < 4; m++) {
        const date = new Date(__date);
        const dt = new Date(date.setMonth(date.getMonth() + 3 * m));
        const quarter = getQuarter(dt);
        const year = dt.getFullYear();
        quarters.push({ year: year, quarter: quarter } as TimeQuarter);
    }
    return quarters;
};

export const getQuarterStartEnd = (timeQuarter: TimeQuarter) => {
    var firstDate = new Date(timeQuarter.year, (timeQuarter.quarter-1) * 3, 1);
    var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    return [firstDate, endDate];
};

export interface TimeQuarter {
    year: number;
    quarter: number;
}

export interface Timespan {
    startDate: Date;
    endDate: Date;
}

export const getWeekNum = (date: Date) => {
    var dateObj = new Date(date);
    dateObj.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    dateObj.setDate(dateObj.getDate() + 3 - ((dateObj.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(dateObj.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
        1 +
        Math.round(
            ((dateObj.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
        )
    );
};

export const getTimeQuarter = (date: Date) => {
    return { quarter: getQuarter(date), year: date.getFullYear() };
};

export const getQuarter = (date: Date) => {
    return Math.floor((date.getMonth() + 3) / 3);
};

export const getDayDiff = (startDate: Date, endDate: Date): number => {
    const msInDay = 24 * 60 * 60 * 1000;

    // 👇️ explicitly calling getTime()
    return Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / msInDay);
};

export const extractUnit = (unit: TimeUnit, date: Date) => {
    switch (unit) {
        case TimeUnit.YEAR:
            return `${date.getFullYear()}`;
        case TimeUnit.QUARTER:
            return `${date.getFullYear()}:${getQuarter(date)}`;
        case TimeUnit.MONTH:
            return `${date.getFullYear()}:${date.getMonth()}`;
        case TimeUnit.WEEK:
            const wn = getWeekNum(date);

            if (wn === 52 && date.getMonth() === 0) {
                return `${date.getFullYear() - 1}:${wn}`;
            }
            return `${date.getFullYear()}:${wn}`;
        case TimeUnit.DAY:
            return `${date.getFullYear()}:${getWeekNum(date)}:${date.getDate()}`;
    }
};
