import React from "react";
import { Space } from "antd";
import { NegotiationLeverDetail } from "../../types/NegotiationTypes";
import { NegotiationLeverItem } from "./NegotiationLeverItem";

interface NegotiationLeversContainerProps {
    levers: NegotiationLeverDetail[];
    onSelect?: (lever: NegotiationLeverDetail) => void;
}
export const NegotiationLeversContainer: React.FC<NegotiationLeversContainerProps> = ({
    levers,
    onSelect,
}) => {
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {levers.map((item) => {
                return (
                    <NegotiationLeverItem
                        key={item.lever.id}
                        negotiationLever={item}
                        onClick={onSelect}
                    />
                );
            })}
        </Space>
    );
};
