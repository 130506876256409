import { Form, Select, DatePicker } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { getPeriodRange, TimePeriods } from "./TimePeriods";

const { RangePicker } = DatePicker;
interface PeriodPickerInterface {
    onPeriodChange: (startDate: Date, endDate: Date) => void;
}

const DatePeriodPicker: React.FC<PeriodPickerInterface> = ({ onPeriodChange }) => {
    const [period, setPeriod] = useState(TimePeriods.Next12Months);
    const [dates, setDates] = useState(getPeriodRange(period));

    useEffect(() => {
        if (period === TimePeriods.Custom) {
            return;
        }
        const timeRange = getPeriodRange(period);
        setDates(timeRange);
    }, [period]);
    useEffect(() => {
        onPeriodChange(dates[0], dates[1]);
    }, [dates]);

    const periodSelector = (
        <Select
            style={{ width: "200px", float: "right" }}
            value={period}
            onSelect={(selected: string) => {
                setPeriod(selected as TimePeriods);
            }}
        >
            {Object.values(TimePeriods).map((dr) => {
                return (
                    <Select.Option value={dr as string} key={dr}>
                        {dr}
                    </Select.Option>
                );
            })}
        </Select>
    );
    return (
        <div className={"datePeriodPicker"}>
            {period === TimePeriods.Custom ? (
                <RangePicker
                    style={{ float: "right" }}
                    value={dates.map((d: Date) => moment(d)) as [Moment, Moment]}
                    onCalendarChange={(dates: any) => {
                        if (dates) {
                            setDates([dates[0], dates[1]]);
                        } else {
                            setPeriod(TimePeriods.Next12Months);
                        }
                    }}
                />
            ) : (
                <Form.Item label={"Time Period"} key={"TimePeriodSelector"}>
                    {" "}
                    {periodSelector}
                </Form.Item>
            )}
        </div>
    );
};

export default DatePeriodPicker;
