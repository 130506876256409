import { Button, Card, Col, Divider, Form, Input, Layout, Row, Spin } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { useSearchParams } from "react-router-dom";
import { safePOST } from "../../common/api/SafeClient";
import { handleError } from "../../common/ErrorHandling";
import { useEffect, useState } from "react";
import { useUserState } from "../../common/UserContext";

export const Login: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const { me } = useUserState();

    const enactRedirect = () => {
        let path = searchParams.get("path");
        if (!path) {
            path = "/app/dashboard";
        }
        window.location.href = `/api/login/safeRedirect?redirect=${path}`;
    };

    useEffect(() => {
        if (me.email !== undefined) {
            enactRedirect();
        }
    }, [me]);

    const submittForm = async (props: any) => {
        try {
            setLoading(true);
            await safePOST("/api/login/password", props);
            enactRedirect();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const usernamePwd = (
        <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            action={"/api/login/password"}
            onFinish={submittForm}
            autoComplete="off"
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: "Please input your username!" }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
            >
                <Input.Password />
            </Form.Item>
            <Row justify={"end"}>
                <Col>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                </Col>
            </Row>
        </Form>
    );
    const google = (
        <>
            <a href="/login/google">
                <Button
                    className={"loginButtons"}
                    type={"primary"}
                    icon={<GoogleOutlined />}
                    onClick={() => {}}
                >
                    Login with Google
                </Button>
            </a>
        </>
    );

    const content = (
        <Row align={"middle"} justify="center" style={{ height: "100vh" }}>
            <Col style={{ height: "100vh" }}>
                <Card style={{ top: "30%" }}>
                    <Spin spinning={loading}>
                        <Row>
                            <Col span={11}>{usernamePwd}</Col>
                            <Col span={2} style={{ paddingLeft: 18 }}>
                                <Divider
                                    type="vertical"
                                    style={{ height: "100%", paddingLeft: 15 }}
                                />
                            </Col>
                            <Col span={11}>
                                {/*                               <Row align={"middle"} justify="center" className={"loginRow"}>
                                    <Col style={{ paddingBottom: 10 }}>{microsoft}</Col>
                                </Row>
 */}
                                <Row align={"middle"} justify="center" className={"loginRow"}>
                                    <Col>{google}</Col>
                                    {
                                        // <Link to={'/app/signup'}>Or Signup</Link>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Divider />
                        </Row>
                        {/*                     <Row align={"middle"} justify="center">
                            <Col>
                                <Link to={"/app/join"}>
                                    <div>Join</div>
                                </Link>
                            </Col>
                        </Row>

*/}
                    </Spin>
                </Card>
            </Col>
        </Row>
    );

    const layour = (
        <Layout
            style={{
                minHeight: "100vh",
                background: "#00203D",
            }}
        >
            <Content>{content}</Content>
        </Layout>
    );

    return layour;
};
