import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { Supplier } from "../../../types/SupplierTypes";

interface SupplierEditModalProps {
    open: boolean;
    supplier: Supplier;
    onCancel: () => void;
    onOk: (form: Supplier) => void;
}

export const SupplierEditModal: React.FC<SupplierEditModalProps> = ({
    supplier,
    open,
    onCancel,
    onOk,
}) => {
    const [form, setForm] = useState({} as Supplier);

    useEffect(() => {
        setForm(supplier);
    }, [supplier]);

    return (
        <Modal
            title={form.name}
            open={open}
            onCancel={onCancel}
            footer={
                <>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={() => onOk(form)} type="primary">
                        Confirm
                    </Button>
                </>
            }
        >
            <Form>
                <Form.Item label="Description">
                    <Input.TextArea
                        rows={4}
                        value={form.description}
                        onChange={(e) => setForm({ ...form, description: e.target.value })}
                    ></Input.TextArea>
                </Form.Item>
            </Form>
        </Modal>
    );
};
