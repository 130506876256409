export interface Competitor {
    name: string;
    website: string;
    logo: string;
    rank: number;
    keyProducts: string[];
    keyDifferentiators: string[];
}
export enum LogoBoxType {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
}

export const getLogoBoxClassByType = (type?: LogoBoxType) => {
    switch (type) {
        case LogoBoxType.SUCCESS:
            return "tagLikeBox__success";
        case LogoBoxType.WARNING:
            return "tagLikeBox__warning";
        case LogoBoxType.ERROR:
            return "tagLikeBox__error";
        default:
            return "";
    }
};

export const getLogoBoxTypeByRank = (rank: number) => {
    switch (rank) {
        case 1:
            return LogoBoxType.SUCCESS;
        case 2:
            return LogoBoxType.WARNING;
        case 3:
            return LogoBoxType.ERROR;
        default:
            return undefined;
    }
};

export interface SupplierNews {
    title: string;
    url: string;
    date: string;
}

export interface Supplier {
    id: number;
    name: string;
    description?: string;
    address?: string;
    logo?: string;
    symbol?: string;
    website?: string;
    linkedIn?: string;
    twitter?: string;
    industries?: string;
    keyOfferings?: string;
    keyBusinessDrivers?: string;
    esgScore?: number;
    esgRanking?: string;
    competitorNames?: string;
    creatorId: number;
    recordCreationDate: Date;
    recordDeletionDate: Date;
}

export interface SupplierCompetitor {
    id: number;
    supplierId: number;
    name: string;
    logo?: string;
    website?: string;
    keyOfferings?: string;
    recordCreationDate: Date;
}

export interface SupplierFullCompetitor extends SupplierCompetitor {
    keyDifferentiators: SupplierCompetitorDifferentiator[];
}

export interface SupplierInsight {
    id: number;
    supplierId: number;
    title: string;
    content: string;
    recordCreationDate: Date;
}

export interface SupplierEmployee {
    id: number;
    supplierId: number;
    current?: number;
    previous?: number;
    percentage?: number;
    recordCreationDate: Date;
    recordUpdateDate: Date;
}

export interface SupplierWithEmployee extends Supplier {
    currentEmployee?: number;
    previousEmployee?: number;
    employeeDifference?: number;
    products?: SupplierProduct[];
    businessDrivers?: SupplierBusinessDriver[];
}

export interface UserSupplierSetting {
    userId: number;
    alwaysShowGenerator: boolean;
}

export interface SupplierProduct {
    id: number;
    supplierId: number;
    name: string;
    description: string;
    recordCreationDate: Date;
}

export interface SupplierBusinessDriver extends SupplierProduct {}

export interface SupplierCompetitorDifferentiator {
    id: number;
    competitorId: number;
    name: string;
    description: string;
    recordCreationDate: Date;
}
