export enum ErrorType {
    ACCESS_ERROR = "AccessError",
    DB_ERROR = "DbError",
    SERVER_ERROR = "ServerError",
    REQUEST_ERROR = "RequestError",
    LOCATION_ERROR = "LocationError",
    UI_ERROR = "UIError",
    NOT_AUTHENTICATED = "NotAuthenticaded",
    WRONG_CREDENTIALS = "WrongCredentials",
    EXTERNAL_ERROR = "ExternalError",
    OPENAI_ERROR = "OpenaiError",
    COMPANIES_API_ERROR = "CompaniesApiError",
    RAPID_API_ERROR = "RapidApiError",
}

export interface Error {
    type: ErrorType;
    message: string;
    code: number;
    isILError: boolean;
    misc?: any;
}

export class Errors implements Error {
    static WRONG_CREDENTIALS: Errors = new Errors(
        ErrorType.WRONG_CREDENTIALS,
        "Wrong credentials (username or password)",
        401
    );
    static NOT_AUTHENTICATED: Errors = new Errors(
        ErrorType.NOT_AUTHENTICATED,
        "User not authenticated",
        401
    );
    static NOT_ENOUGH_RIGHTS: Errors = new Errors(
        ErrorType.ACCESS_ERROR,
        "Not enough rights to access the resource",
        403
    );
    static DB_ERROR: Errors = new Errors(ErrorType.DB_ERROR, "An error ocurred in our DB", 500);
    static CONVERSION_ERROR: Errors = new Errors(ErrorType.REQUEST_ERROR, "Bad request", 400);
    static SERVER_ERROR: Errors = new Errors(
        ErrorType.SERVER_ERROR,
        "Error processing request",
        500
    );
    static NOT_FOUND_ERROR: Errors = new Errors(
        ErrorType.LOCATION_ERROR,
        "Requested object not found",
        404
    );
    static UI_ERROR: Errors = new Errors(ErrorType.UI_ERROR, "UIError", 700);

    type: ErrorType;
    message: string;
    isILError: boolean = true;
    code: number;
    misc?: any;

    private constructor(err: ErrorType, message: string, code: number, misc?: any) {
        this.type = err;
        this.message = message;
        this.code = code;
        this.misc = misc;
    }

    createNew(misc: any): Error {
        return new Errors(this.type, this.message, this.code, misc);
    }
}
