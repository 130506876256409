export enum ResourceType {
    OrderForm = 1,
    Vendor = 3,
    Report = 4,
    NONE = 5,
    NEGOTIATION = 6
}
export interface Resource {
    resourceType: ResourceType,
    resourceId: number
}

export function toResourceType(val: number): ResourceType {
    switch (val) {
        case 1:
            return ResourceType.OrderForm;
        case 2:
            return ResourceType.NONE;
        case 3:
            return ResourceType.Vendor;
        case 4:
            return ResourceType.Report;
        case 5:
            return ResourceType.NONE;
        case 6:
            return ResourceType.NEGOTIATION;
    }
    return ResourceType.NONE;
}
