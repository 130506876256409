import React from "react";
import { Card } from "antd";
import MainLayout from "../navigation/MainLayout";
import { TopSuppliersPanel } from "./TopSuppliersPanel";
import { CostChartPanel } from "./CostChartPanel";
import { VendorHeatmapPanel } from "./VendorHeatmapPanel";

export const DashboardView = () => {
    const panel = (
        <Card style={{ marginTop: 10 }}>
            <CostChartPanel />
            <TopSuppliersPanel style={{ marginTop: 24 }} />
            <VendorHeatmapPanel style={{ marginTop: 24 }} />
        </Card>
    );
    return (
        <MainLayout mainPanel={<>{panel}</>} selected={"Dashboard"} collapsed={true}></MainLayout>
    );
};
