export const shallowEqual = (object1: any, object2: any) => {
    if (object1 === null || object2 === null || object1 === undefined || object2 === undefined) {
        return false;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            if (object1[key] instanceof Date && object2[key] instanceof Date) {
                if (object1[key].getTime() === object2[key].getTime()) {
                    return true;
                }
            } else if (typeof object1[key] === "object") {
                if (!shallowEqual(object1[key], object2[key])) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
    return true;
};

export const retnum = (str: any): number => {
    // eslint-disable-next-line no-new-wrappers
    var num = new String(str).replace(/[^0-9(\\.){0-1}]/g, "");
    return parseFloat(num);
};
