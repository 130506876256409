import {
    Button,
    Col,
    DatePicker,
    Input,
    InputNumber,
    Popover,
    Radio,
    Row,
    Select,
} from "antd";
import { useEffect, useState } from "react";
import { retnum } from "../../../common/Objects";
import {
    FAttributeConfig,
    OrderFormAttributesConfig,
    SKUAttributeConfig,
} from "../../../types/Attributes";
import { AnnotationSKU, AnnotationValue } from "../../../types/Document";
import { DATE_FORMAT, extractDate } from "../../date/DateOp";
import { VendorInput } from "../../vendor/VendorInput";
import { BoundingData } from "./ReviewInterfaces";

const { Option } = Select;

interface SKUPanelArgs {
    onSet: (
        block: any,
        updatedSKU?: AnnotationSKU[],
        updatedAttributes?: AnnotationValue[]
    ) => void;
    bounding: BoundingData;
    skus: AnnotationSKU[];
}
const SKUPanel: React.FC<SKUPanelArgs> = ({ bounding, skus, onSet }) => {
    const attr = Object.values(SKUAttributeConfig);
    const [value, setValue] = useState("");
    const [selectedSKU, setSelectedSKU] = useState(-1);
    const [selectedAttribute, setSelectedAttribute] = useState(attr[0].key);

    useEffect(() => {
        const sku = skus.filter(
            (s) =>
                s.annotationValues.filter((a) => a.attributeLabel === bounding.label.id).length > 0
        );
        if (sku.length > 0) {
            const index = skus.indexOf(sku[0]);
            setSelectedSKU(index);
            const attrArr = sku[0].annotationValues.filter((a) => {
                return a.attributeLabel === bounding.label.id;
            });
            setSelectedAttribute(attrArr[0].attributeName);
            setValue(attrArr[0].attributeValue);
        } else {
            setSelectedSKU(-1);
            setValue("");
        }
    }, [bounding]);

    const setVal = () => {
        if (selectedSKU === -1) {
            skus.push({
                annotationValues: [
                    {
                        pageNumber: bounding.page,
                        attributeName: selectedAttribute,
                        attributeLabel: bounding.label.id,
                        attributeValue: value,
                    },
                ],
            });
        } else {
            const sku = skus[selectedSKU];
            const attributeArr = sku.annotationValues.filter(
                (v) => v.attributeName === selectedAttribute
            );
            if (attributeArr.length > 0) {
                const attribute = attributeArr[0];
                const attrIndex = sku.annotationValues.indexOf(attribute);
                skus[selectedSKU].annotationValues[attrIndex].pageNumber = bounding.page;
                skus[selectedSKU].annotationValues[attrIndex].attributeLabel = bounding.label.id;
                skus[selectedSKU].annotationValues[attrIndex].attributeValue = value;
            } else {
                skus[selectedSKU].annotationValues.push({
                    pageNumber: bounding.page,
                    attributeName: selectedAttribute,
                    attributeLabel: bounding.label.id,
                    attributeValue: value,
                });
            }
        }
        onSet(bounding, skus, undefined);
    };

    useEffect(() => {
        setValue(bounding?.label?.text);
    }, [bounding]);

    const opts = skus.map((s, i) => <Option value={i} key={`opt-${i}`}>{`SKU ${i + 1}`}</Option>);
    opts.push(
        <Option value={-1} key={"newSKU"}>
            New Product (SKU)
        </Option>
    );

    const selector = (
        <Select
            style={{ width: "100%" }}
            value={selectedSKU}
            onChange={(v) => {
                setSelectedSKU(v);
            }}
        >
            {opts}
        </Select>
    );

    const skuAttributes = (
        <Select
            style={{ width: "100%" }}
            value={selectedAttribute}
            onChange={(v) => {
                setSelectedAttribute(v);
            }}
        >
            {attr.map((v) => (
                <Option value={v.key} key={v.key}>
                    {v.name}
                </Option>
            ))}
        </Select>
    );

    const panel = (
        <>
            <Col span={24}>{selector}</Col>
            <Col span={2}>Raw: </Col>
            <Col span={22}>
                <Input value={bounding?.label?.text} />
            </Col>
            <Col span={2}>Value</Col>
            <Col span={4}>{skuAttributes}</Col>
            <Col span={18}>
                {getAttributeInput(
                    attr.filter((a) => a.key === selectedAttribute)[0],
                    (val) => {
                        setValue(val);
                    },
                    bounding?.label?.text,
                    value
                )}
            </Col>
        </>
    );
    return (
        <>
            {panel}
            <Col span={6} offset={12}>
                <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                        onSet(bounding, undefined, undefined);
                    }}
                >
                    Cancel
                </Button>
            </Col>
            <Col span={6}>
                <Button style={{ width: "100%" }} type={"primary"} onClick={setVal}>
                    Set SKU Attribute
                </Button>
            </Col>
        </>
    );
};

const options = [
    { label: "Order Form Attributes", value: "attribute" },
    { label: "Product (SKU)", value: "sku" },
];

const getAttributeInput = (
    attribute: FAttributeConfig,
    onChange: (value: any) => void,
    initialValue: any,
    attributeValue: any
) => {
    switch (attribute.type) {
        case "Date":
            let v = attributeValue;
            if (typeof attributeValue === "string") {
                v = extractDate(attributeValue);
            }
            return (
                <DatePicker allowClear={false} onChange={onChange} format={DATE_FORMAT} value={v} />
            );
        case "number":
            return (
                <InputNumber
                    style={{ width: "300px" }}
                    value={retnum(attributeValue)}
                    onChange={onChange}
                />
            );

        case "vendor":
            return (
                <VendorInput
                    value={attributeValue}
                    onSelect={(val) => {
                        console.log(val);
                        onChange(val);
                    }}
                />
            );
        default:
            return (
                <Input
                    defaultValue={initialValue}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    value={attributeValue}
                />
            );
    }
};

interface AttributePanelArgs {
    onSet: (
        block: any,
        updatedSKU?: AnnotationSKU[],
        updatedAttributes?: AnnotationValue[]
    ) => void;
    bounding: BoundingData;
    attributes: AnnotationValue[];
}
const AttributePanel: React.FC<AttributePanelArgs> = ({ bounding, attributes, onSet }) => {
    const attrs = Object.values(OrderFormAttributesConfig);
    const [selectedAttribute, setSelectedAttribute] = useState(attrs[0]);
    const [attributeValue, setAttributeValue] = useState(undefined as any);

    useEffect(() => {
        const attrArr = attributes.filter((a) => a.attributeLabel === bounding.label.id);
        if (attrArr.length > 0) {
            const attr = attrArr[0];
            setSelectedAttribute(attrs.filter((a) => a.key === attr.attributeName)[0]);
            setAttributeValue(attr.attributeValue);
        } else {
            setSelectedAttribute(attrs[0]);
            setAttributeValue(undefined);
        }
    }, [bounding]);

    const setVal = () => {
        const attrArr = attributes.filter((a) => a.attributeName === selectedAttribute.key);
        const attrIndex = attributes.indexOf(attrArr[0]);
        if (attrArr.length > 0) {
            attributes[attrIndex].pageNumber = bounding.page;
            attributes[attrIndex].attributeValue = attributeValue;
            attributes[attrIndex].attributeLabel = bounding.label.id;
        } else {
            attributes.push({
                pageNumber: bounding.page,
                attributeName: selectedAttribute.key,
                attributeLabel: bounding.label.id,
                attributeValue: attributeValue,
            });
        }
        onSet(bounding, undefined, attributes);
    };
    const selection = (
        <Select
            style={{ width: "100%" }}
            value={selectedAttribute.key}
            onChange={(value: string) => {
                setAttributeValue(undefined);
                setSelectedAttribute(attrs.filter((a) => a.key === value)[0]);
            }}
        >
            {attrs.map((v) => (
                <Option value={v.key} key={v.key}>
                    {v.name}
                </Option>
            ))}
        </Select>
    );
    const attributeSelection = (
        <>
            <Col span={3}>Attribute: </Col>
            <Col span={21}>{selection}</Col>
            <Col span={3}>Raw: </Col>
            <Col span={21}>
                <Input value={bounding?.label?.text} />
            </Col>
            <Col span={3}>Value: </Col>
            <Col span={21}>
                {getAttributeInput(
                    selectedAttribute,
                    (val) => {
                        setAttributeValue(val);
                    },
                    bounding?.label?.text,
                    attributeValue
                )}
            </Col>

            <Col span={6} offset={12}>
                <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                        onSet(bounding, undefined, undefined);
                    }}
                >
                    Cancel
                </Button>
            </Col>
            <Col span={6}>
                <Button style={{ width: "100%" }} type={"primary"} onClick={setVal}>
                    Set Attribute Value
                </Button>
            </Col>
        </>
    );

    return attributeSelection;
};

interface AttributePopupArgs {
    popupState: PopupState;
    attributes: AnnotationValue[];
    skus: AnnotationSKU[];
    onSet: (
        block: any,
        updatedSKU?: AnnotationSKU[],
        updatedAttributes?: AnnotationValue[]
    ) => void;
}

export interface PopupState {
    visible: boolean;
    x: number;
    y: number;
    w: number;
    h: number;
    bounding: BoundingData;
}
const AttributePopupPanel: React.FC<AttributePopupArgs> = ({
    popupState,
    attributes,
    skus,
    onSet,
}) => {
    const [mode, setMode] = useState("attribute");

    useEffect(() => {}, []);
    const modeSelector = (
        <Radio.Group
            options={options}
            value={mode}
            optionType="button"
            buttonStyle="solid"
            onChange={(value) => {
                setMode(value.target.value);
            }}
        />
    );

    const popupContent = (
        <div style={{ width: "700px" }}>
            <Row gutter={[8, 8]}>
                <Col span={24}>{modeSelector}</Col>
                {mode === "sku" ? (
                    <SKUPanel bounding={popupState.bounding} skus={skus} onSet={onSet} />
                ) : (
                    <AttributePanel
                        bounding={popupState.bounding}
                        attributes={attributes}
                        onSet={onSet}
                    />
                )}
            </Row>
        </div>
    ); //

    return (
        <Popover
            content={popupContent}
            title="Annotate attribute"
            trigger={"click"}
            visible={popupState.visible}
        >
            <div
                className="reviewCanvasTracker"
                style={{
                    top: `${popupState.y}px`,
                    left: `${popupState.x}px`,
                    width: `${popupState.w}px`,
                    height: `${popupState.h}px`,
                }}
            >
                {" "}
            </div>
        </Popover>
    );
};

export default AttributePopupPanel;
