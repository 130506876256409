import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CoffeeOutlined,
    EyeOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import { ReviewStatus } from "../../types/ReviewTypes";

export function ReviewStatusToTag(status: ReviewStatus) {
    switch (status) {
        case ReviewStatus.NOT_STARTED:
            return (
                <Tag icon={<CoffeeOutlined />} color={"warning"}>
                    {reviewStatusToString(status)}
                </Tag>
            );
        case ReviewStatus.REVIEW:
            return (
                <Tag icon={<EyeOutlined />} color={"processing"}>
                    {reviewStatusToString(status)}
                </Tag>
            );
        case ReviewStatus.SUBMITTED:
            return (
                <Tag icon={<LoadingOutlined />} color={"default"}>
                    {reviewStatusToString(status)}
                </Tag>
            );
        case ReviewStatus.COMPLETED:
            return (
                <Tag icon={<CheckCircleOutlined />} color={"success"}>
                    {reviewStatusToString(status)}
                </Tag>
            );
        case ReviewStatus.FAILED:
            return (
                <Tag icon={<CloseCircleOutlined />} color={"error"}>
                    {reviewStatusToString(status)}
                </Tag>
            );
        default:
            return <></>;
    }
}

export function reviewStatusToString(status: ReviewStatus) {
    switch (status) {
        case ReviewStatus.NOT_STARTED:
            return "Review Not Started";
        case ReviewStatus.COMPLETED:
            return "Review Completed";
        case ReviewStatus.SUBMITTED:
            return "Review Submitted";
        case ReviewStatus.REVIEW:
            return "Review in Progress";
        case ReviewStatus.FAILED:
            return "Review Failed";
        default:
            return "";
    }
}
