import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { Vendor } from "../../types/VendorTypes";
import { getVendors } from "../../common/api/VendorClient";
import { handleError } from "../../common/ErrorHandling";
import { ChangedOrderSKU } from "../../types/NegotiationTypes";

interface NegotiatioinPriceDiffModalProps {
    changedOrderSKUs: ChangedOrderSKU[];
    visible: boolean;
    discrepancy: React.ReactNode;
    onCancel?: () => void;
}

export const NegotiationPriceDiffModal: React.FC<NegotiatioinPriceDiffModalProps> = ({
    visible,
    changedOrderSKUs,
    discrepancy,
    onCancel,
}) => {
    const [loading, setLoading] = useState(true);
    const [vendorsMap, setVendorsMap] = useState(new Map<string, Vendor>());

    const refreshTable = async () => {
        try {
            setLoading(true);
            const vendorKeys = changedOrderSKUs.map((v) => v.vendorKey);
            const orderVendors = await getVendors(Array.from(new Set(vendorKeys)));
            const vendorMap = new Map(orderVendors.map((v) => [v.vendorKey, v]));
            setVendorsMap(vendorMap);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshTable();
    }, [changedOrderSKUs]);

    const columns = [
        {
            title: "Supplier name",
            dataIndex: "vendorKey",
            key: "vendorKey",
            render: (value: string) => {
                const vendor = vendorsMap.get(value);
                return vendor?.name;
            },
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Delta",
            dataIndex: "delta",
            key: "delta",
        },
    ];

    return (
        <Modal
            open={visible}
            title={<span>Unit price differential – {discrepancy}</span>}
            onCancel={onCancel}
            footer={<Button onClick={onCancel}>Close</Button>}
            width={1200}
        >
            <Table
                dataSource={changedOrderSKUs}
                loading={loading}
                columns={columns}
                pagination={{ position: changedOrderSKUs.length > 10 ? ["bottomRight"] : [] }}
                rowKey={"id"}
                size="small"
            ></Table>
        </Modal>
    );
};
