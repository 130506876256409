import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { Link } from "react-router-dom";
import { OrderForm } from "../../types/OrderFormTypes";
import { Vendor } from "../../types/VendorTypes";
import { getVendors } from "../../common/api/VendorClient";
import { getUsers } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";
import { getDayDiff } from "../../types/TimeTypes";
import NumberFormat from "react-number-format";
import { User } from "../../types/UserGroup";
import { formatDateString } from "../date/DateOp";

interface NegotiatioinCotermModalProps {
    orderForms: OrderForm[];
    visible: boolean;
    onCancel?: () => void;
}

interface GroupedOrderForm {
    vendorKey: string;
    count: number;
    orders: OrderForm[];
}
interface OrderGroupMap {
    [key: string]: Omit<GroupedOrderForm, "vendorKey">;
}

export const NegotiationCotermModal: React.FC<NegotiatioinCotermModalProps> = ({
    visible,
    orderForms,
    onCancel,
}) => {
    const [loading, setLoading] = useState(true);
    const [vendorsMap, setVendorsMap] = useState(new Map<string, Vendor>());
    const [ownersMap, setOwnersMap] = useState(new Map<number, User>());

    const refreshTable = async () => {
        try {
            setLoading(true);
            const vendorKeys = orderForms.map((v) => v.vendorKey);

            const [orderVendors, owners] = await Promise.all([
                getVendors(Array.from(new Set(vendorKeys))),

                getUsers(Array.from(new Set(orderForms.map((v) => v.ownerId)))),
            ]);
            const vendorMap = new Map(orderVendors.map((v) => [v.vendorKey, v]));
            const ownerMap = new Map(owners.map((v) => [v.id, v]));
            setVendorsMap(vendorMap);
            setOwnersMap(ownerMap);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshTable();
    }, [orderForms]);

    const groupOrderByVendor = (orders: OrderForm[]): GroupedOrderForm[] => {
        const orderMap = {} as OrderGroupMap;
        for (const order of orders) {
            const vendorKey = order.vendorKey;
            if (!orderMap[vendorKey]) {
                orderMap[vendorKey] = {
                    count: 1,
                    orders: [],
                };
            } else {
                orderMap[vendorKey].count += 1;
            }
            orderMap[vendorKey].orders.push(order);
        }
        return Object.entries(orderMap).map(([key, value]) => {
            return {
                ...value,
                vendorKey: key,
            };
        });
    };

    const data = groupOrderByVendor(orderForms);

    const expandedRowRender = (data: OrderForm[]) => {
        const columns = [
            {
                title: "Order form name",
                dataIndex: "name",
                key: "name",
                render: (value: string, row: OrderForm) => {
                    return <Link to={"/app/order/" + row.id + ""}>{value}</Link>;
                },
                width: 400,
            },
            {
                title: "Value",
                dataIndex: "value",
                key: "value",
                render: (value: number) => {
                    return (
                        <NumberFormat
                            value={value}
                            thousandSeparator={true}
                            displayType={"text"}
                            prefix={"$"}
                        />
                    );
                },
            },
            {
                title: "Days to expire",
                dataIndex: "expirationDate",
                key: "expirationDate",
                render: (value: string) => {
                    return (
                        <span>
                            {getDayDiff(new Date(), new Date(value))} days (
                            {formatDateString(new Date(value))})
                        </span>
                    );
                },
            },
            {
                title: "Owner",
                dataIndex: "ownerId",
                key: "ownerId",
                render: (value: number) => {
                    const owner = ownersMap.get(value);
                    return owner?.name;
                },
            },
        ];

        return <Table columns={columns} pagination={false} dataSource={data} rowKey="id" />;
    };

    const reduce = orderForms.length - data.length;

    const columns = [
        {
            title: "Supplier name",
            dataIndex: "vendorKey",
            key: "vendorKey",
            render: (value: string) => {
                const vendor = vendorsMap.get(value);
                return vendor?.name;
            },
        },
        {
            title: "# of Contracts",
            dataIndex: "count",
            key: "count",
        },
    ];

    return (
        <Modal
            open={visible}
            title={`Co-terming ↓ ${reduce} contracts`}
            onCancel={onCancel}
            footer={<Button onClick={onCancel}>Close</Button>}
            width={1200}
        >
            <Table
                dataSource={data}
                loading={loading}
                columns={columns}
                expandable={{
                    expandedRowRender: (row) => expandedRowRender(row.orders),
                }}
                pagination={{ position: data.length > 10 ? ["bottomRight"] : [] }}
                rowKey="vendorKey"
                size="small"
            ></Table>
        </Modal>
    );
};
