import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    LeftCircleOutlined,
    MinusCircleOutlined,
    RightCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { Affix, Button, Collapse, Descriptions, Divider, InputNumber, PageHeader, Tag } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
    getTranscript,
    getReview,
    updateReview,
    requestTranscript,
    startReview,
    submittReview,
    assignUser,
} from "../../common/api/ReviewClient";
import { getUsers } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";
import { OrderFormAttributesConfig, SKUAttributeConfig } from "../../types/Attributes";
import { AnnotationSKU, AnnotationValue } from "../../types/Document";
import {
    DocumentPageTranscript,
    DocumentReviewResponse,
    DocumentTranscript,
    PageImage,
    ReviewStatus,
    TranscriptionStatus,
} from "../../types/ReviewTypes";
import { Group, User, UserRole } from "../../types/UserGroup";
import { UserGroupSelectionModal } from "../user/UserGroupSelectionModal";
import AttributePopupPanel, { PopupState } from "./panel/AttributesPanel";
import CanvasElement from "./panel/CanvasElement";
import { BoundingData } from "./panel/ReviewInterfaces";
const { Panel } = Collapse;
interface Reviewing extends DocumentReviewResponse {
    transcript: DocumentTranscript;
}

const extractPage = (pageNum: number, reviewing: Reviewing) => {
    const selectedPage = {
        page: { page: pageNum, blocks: [] } as DocumentPageTranscript,
        image: { url: "" } as PageImage,
    };
    const index = pageNum - 1;
    if (
        reviewing.review !== undefined &&
        reviewing.images !== undefined &&
        reviewing.images.length > 0
    ) {
        selectedPage.image = reviewing.images[index];
    }
    if (reviewing.transcript !== undefined && reviewing.transcript.pageCount !== undefined) {
        selectedPage.page = reviewing.transcript.pages![index];
    }
    return selectedPage;
};

const ReviewDetail: React.FC = () => {
    const params = useParams();
    const [, setLoading] = useState(false);
    const [documentId] = useState(params.documentId || "");
    const [reviewing, setReviewing] = useState({} as Reviewing);
    const [pageNum, setPageNum] = useState(1);
    const [reviewer, setReviewer] = useState({} as User);
    const [disabled, setDisabled] = useState(false);
    const [popUpState, setPopupState] = useState({
        visible: false,
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        bounding: {},
    } as PopupState);

    const [skus, setSkus] = useState([] as AnnotationSKU[]);
    const [attributes, setAttributes] = useState([] as AnnotationValue[]);

    const [showUserSearch, setShowUserSearch] = useState(false);

    useEffect(() => {
        const fn = async () => {
            try {
                const reviewResponse = await getReview(documentId);
                const transcript = await getTranscript(documentId);
                const revReviewing = { ...reviewResponse, transcript: transcript };
                setReviewing(revReviewing);
                setSkus(reviewResponse.review.skus || []);
                setAttributes(reviewResponse.review.attributes || []);
            } catch (error: any) {
                handleError(error);
            }
        };
        fn();
    }, []);

    useEffect(() => {
        const fn = async () => {
            if (reviewing.review !== undefined && reviewing.review?.reviewerId !== -1) {
                try {
                    setReviewer((await getUsers([reviewing.review.reviewerId]))[0]);
                } catch (error: any) {
                    handleError(error);
                }
            }
            if (
                reviewing.review?.status === ReviewStatus.COMPLETED ||
                reviewing.review?.status === ReviewStatus.SUBMITTED
            ) {
                setDisabled(true);
            }
        };
        fn();
    }, [reviewing]);

    const updateReviewAttrs = async (skus: AnnotationSKU[], attributes: AnnotationValue[]) => {
        try {
            const update = await updateReview(documentId, skus, attributes);
            setAttributes(update.attributes || attributes);
            setSkus(update.skus || skus);
        } catch (error: any) {
            handleError(error);
        }
    };

    const handleLabelSelection = (bounding: BoundingData, clientX: number, clientY: number) => {
        setPopupState({
            visible: true,
            x: clientX,
            y: clientY,
            w: bounding.w / 2,
            h: bounding.h,
            bounding: bounding,
        });
    };

    const localSubmitReview = async () => {
        try {
            setLoading(true);
            const reviewSubmit = await submittReview(documentId);
            if (reviewSubmit.success) {
                reviewing.review.status = ReviewStatus.SUBMITTED;
                setReviewing({ ...reviewing });
            }
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const localStartReview = async () => {
        try {
            setLoading(true);
            const reviewStart = await startReview(documentId);
            if (reviewStart) {
                reviewing.review.status = ReviewStatus.REVIEW;
                setReviewing({ ...reviewing });
            }
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const localAssignUser = async (user: User | Group) => {
        try {
            setLoading(true);
            const rev = await assignUser(documentId, user.id);
            if (rev) {
                reviewing.review.reviewerId = user.id;
                setReviewing({ ...reviewing });
            }
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
            setShowUserSearch(false);
        }
    };

    const localRequestTranscription = async () => {
        try {
            setLoading(true);
            const jid = await requestTranscript(documentId);
            if (jid.success) {
                reviewing.review.transcriptionStatus = TranscriptionStatus.REQUESTED;
                setReviewing({ ...reviewing });
            }
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const pages = extractPage(pageNum, reviewing);
    const canvas = (
        <CanvasElement
            documentSerialId={documentId}
            page={pages.page}
            image={pages.image}
            suppress={disabled}
            onLabelSelection={handleLabelSelection}
            attributeAnnotations={attributes.concat(
                skus.reduce(
                    (p, s, i) =>
                        s.annotationValues
                            .map((a) => {
                                return {
                                    attributeName: `SKU ${i + 1} - ${a.attributeName}`,
                                    attributeValue: a.attributeValue,
                                    attributeLabel: a.attributeLabel,
                                } as AnnotationValue;
                            })
                            .concat(p),
                    [] as AnnotationValue[]
                )
            )}
        />
    );

    const pageControls = [
        <Divider key="divider-1" />,
        <Button
            disabled={pageNum <= 1}
            key="back-rev"
            type="text"
            onClick={() => {
                setPageNum(pageNum - 1);
            }}
        >
            <LeftCircleOutlined />
        </Button>,
        <InputNumber
            style={{ width: "50px" }}
            size="small"
            key="rev+ref"
            min={1}
            max={reviewing.document?.pageCount || 1}
            value={pageNum}
            onChange={(val) => {
                setPageNum(val as any);
            }}
        />,
        <Button
            disabled={reviewing.review ? pageNum >= reviewing.document.pageCount : true}
            type="text"
            key="forward-rev"
            onClick={() => {
                setPageNum(pageNum + 1);
            }}
        >
            <RightCircleOutlined />
        </Button>,
    ];
    const extras = [];
    const signs = [];

    extras.push(
        <Button key="rev-info" type="text">
            <InfoCircleOutlined />
        </Button>
    );
    if (reviewing.review?.transcriptionStatus === TranscriptionStatus.NOT_REQUESTED) {
        extras.push(
            <Button
                key="rev-2"
                onClick={() => {
                    localRequestTranscription();
                }}
            >
                Request Transcript
            </Button>
        );
        signs.push(
            <Tag icon={<ExclamationCircleOutlined />} color="warning" key="notranscript">
                Transcript not avalable
            </Tag>
        );
    } else if (reviewing.review?.transcriptionStatus === TranscriptionStatus.REQUESTED) {
        signs.push(
            <Tag icon={<SyncOutlined spin />} color="processing" key="transcriptprocessing">
                Transcript processing
            </Tag>
        );
    } else {
        signs.push(
            <Tag icon={<CheckCircleOutlined />} color="success" key="transcriptcomplete">
                Transcription completed
            </Tag>
        );
    }
    extras.push(
        <Button
            key="rev-3"
            onClick={() => {
                setShowUserSearch(true);
            }}
            disabled={disabled}
        >
            Assign a reviewer
        </Button>
    );
    if (reviewing.review?.reviewerId === -1) {
        signs.push(
            <Tag icon={<MinusCircleOutlined />} color="default" key="noreviewer">
                Review not assigned
            </Tag>
        );
    } else {
        signs.push(
            <Tag color="default" key="reviewers">
                Reviewer: {reviewer.name}
            </Tag>
        );
    }
    if (reviewing.review?.status === ReviewStatus.REVIEW) {
        extras.push(
            <Button
                key="rev-1"
                type="primary"
                onClick={() => {
                    localSubmitReview();
                }}
            >
                Submit review
            </Button>
        );
    } else if (reviewing.review?.status === ReviewStatus.NOT_STARTED) {
        extras.push(
            <Button
                key="rev-1"
                type="primary"
                onClick={() => {
                    localStartReview();
                }}
            >
                Start review
            </Button>
        );
    }

    const ofAttributes = Object.values(OrderFormAttributesConfig).map((a) => {
        const valAttr = attributes.filter((attr) => attr.attributeName === a.key);
        return (
            <Descriptions.Item label={`${a.name}`} key={`${a.key}`}>
                {valAttr.length > 0 ? valAttr[0].attributeValue : ""}
            </Descriptions.Item>
        );
    });

    const skuAttr = Object.values(SKUAttributeConfig);
    const ofSKU = skus.map((sku, i) => {
        const innerAttributes = skuAttr.map((a) => {
            const valAttr = sku.annotationValues.filter((attr) => attr.attributeName === a.key);
            return (
                <Descriptions.Item label={`${a.name}`} key={`SKU${i}-${a.key}`}>
                    {valAttr.length > 0 ? valAttr[0].attributeValue : ""}
                </Descriptions.Item>
            );
        });
        return (
            <Descriptions title={`SKU ${i + 1}`} bordered>
                {innerAttributes}
            </Descriptions>
        );
    });

    const main = (
        <div>
            {showUserSearch ? (
                <UserGroupSelectionModal
                    onSelect={localAssignUser}
                    type="u"
                    onCancel={() => {
                        setShowUserSearch(false);
                    }}
                    roleFilter={[UserRole.REVIEWER, UserRole.SUPER_ADMIN]}
                />
            ) : (
                <></>
            )}
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={reviewing.document?.name}
                subTitle={signs}
                extra={extras.concat(pageControls)}
            >
                <Affix offsetTop={0}>
                    <Collapse>
                        <Panel header="Order Form Attributes" key="1">
                            <Descriptions bordered>{ofAttributes}</Descriptions>
                        </Panel>
                        <Panel header="Product (SKU) Attributes" key="2">{ofSKU}</Panel>
                    </Collapse>
                </Affix>
                <div className="reviewCanvasContainer">
                    <AttributePopupPanel
                        popupState={popUpState}
                        attributes={attributes}
                        skus={skus}
                        onSet={(_block, nskus, nattributes) => {
                            popUpState.visible = false;
                            setPopupState({ ...popUpState });
                            updateReviewAttrs(nskus || skus, nattributes || attributes);
                        }}
                    />
                    {canvas}
                </div>
            </PageHeader>
        </div>
    );
    return main;
};

export default ReviewDetail;
