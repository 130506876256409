import React from "react";
import { roundToTwoDecimals } from "../../../common/Number";
import { NegotiationLeversCategory } from "../../../types/NegotiationTypes";
import { sumLeversScore } from "../../negotiations/NegotiationSimulationPanel";
import { getGrade } from "../../negotiations/config/grades";
import { OrderFormLeversTable, OrderFormLeverTableProps } from "./OrderFormLeversTable";
import { OrderScoreHeader } from "./OrderScoreHeader";
import { OrderScoreToolBar } from "./OrderScoreToolBar";
import { ColorType } from "../utils/Color";

interface OrderScoreProps extends OrderFormLeverTableProps {
    title: string;
    description: string;
    averageScore: number;
    type?: ColorType;
    showOptimizeButton?: boolean;
    onOptimizeButtonClick?: () => void;
    showToggle?: boolean;
    legalLeverWeight: number;
    onToggleChange?: (value: number) => void;
    afterToggleCahgne?: (value: number) => void;
}

export const OrderScore: React.FC<OrderScoreProps> = ({
    title,
    description,
    averageScore,
    type = "error",
    levers = [],
    showOptimizeButton = false,
    onOptimizeButtonClick,
    showToggle = false,
    legalLeverWeight,
    onToggleChange,
    afterToggleCahgne,
    ...restLeverTableOptions
}) => {
    const selectedLevers = levers.filter((item) => item.isSelected);
    const selectedLeversPercentage = levers.length
        ? roundToTwoDecimals((selectedLevers.length / levers.length) * 100)
        : 0;
    const procurementLevers = levers.filter(
        (item) => item.lever.category === NegotiationLeversCategory.PROCUREMENT_LEVERS
    );
    const selectedProcurementLevers = procurementLevers.filter((item) => item.isSelected);
    const legalLevers = levers.filter(
        (item) => item.lever.category === NegotiationLeversCategory.lEGAL_LEVERS
    );
    const selectedLegalLevers = legalLevers.filter((item) => item.isSelected);

    const procurementScore = sumLeversScore(procurementLevers.filter((item) => item.isSelected));
    const legalScore = sumLeversScore(legalLevers.filter((item) => item.isSelected));
    const overallScore = roundToTwoDecimals(
        (procurementScore * (100 - legalLeverWeight) + legalScore * legalLeverWeight) / 100
    );
    const overallGrade = getGrade(overallScore)?.grade || "";

    const surpass = roundToTwoDecimals(overallScore - averageScore);
    return (
        <div>
            <OrderScoreHeader
                style={{ marginTop: 20 }}
                title={title}
                description={description}
                type={type}
                score={overallScore}
                grade={overallGrade}
                surpass={surpass}
                showButton={showOptimizeButton}
                onButtonClick={onOptimizeButtonClick}
            ></OrderScoreHeader>
            <OrderScoreToolBar
                type={type}
                usedPercentage={selectedLeversPercentage}
                usedProcurementNumber={selectedProcurementLevers.length}
                totalProcurementNumber={procurementLevers.length}
                usedLegalNumber={selectedLegalLevers.length}
                totalLegalNumber={legalLevers.length}
                showToggle={showToggle}
                toggleValue={legalLeverWeight}
                onToggleChange={onToggleChange}
                afterToggleChange={afterToggleCahgne}
            ></OrderScoreToolBar>
            <OrderFormLeversTable levers={levers} {...restLeverTableOptions} />
        </div>
    );
};
