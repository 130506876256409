const COLOR_MAP = {
    error: {
        bg: "#FFF1F0",
        text: "#F5222D",
    },
    success: {
        bg: "#EDFBDF",
        text: "#18AC00",
    },
};

export type ColorType = "success" | "error";

export function getTypeColor(type: ColorType) {
    return COLOR_MAP[type];
}
