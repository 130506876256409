import { Tag } from "antd";
import {
    CalendarOutlined,
    WarningOutlined,
    StopOutlined,
    FieldTimeOutlined,
    CarryOutOutlined,
} from "@ant-design/icons";
import { OrderFormStatus } from "../../types/OrderFormTypes";

export function orderStatusToTag(status: OrderFormStatus) {
    switch (status) {
        case OrderFormStatus.EXPIRATION_NOT_SET:
            return (
                <Tag icon={<WarningOutlined />} color={"warning"}>
                    {orderStatusToString(status)}
                </Tag>
            );
        case OrderFormStatus.RENEWAL_SCHEDULED:
            return (
                <Tag icon={<CalendarOutlined />} color={"processing"}>
                    {orderStatusToString(status)}
                </Tag>
            );
        case OrderFormStatus.WILL_NOT_RENEW:
            return (
                <Tag icon={<StopOutlined />} color={"error"}>
                    {orderStatusToString(status)}
                </Tag>
            );
        case OrderFormStatus.OVERDUE:
            return (
                <Tag icon={<FieldTimeOutlined />} color={"error"}>
                    {orderStatusToString(status)}
                </Tag>
            );
        case OrderFormStatus.RENEWED:
            return (
                <Tag icon={<CarryOutOutlined />} color={"success"}>
                    {orderStatusToString(status)}
                </Tag>
            );
        default:
            return <></>;
    }
}

export function orderStatusToString(status: OrderFormStatus) {
    switch (status) {
        case OrderFormStatus.EXPIRATION_NOT_SET:
            return "Expiration unknown";
        case OrderFormStatus.RENEWAL_SCHEDULED:
            return "Scheduled";
        case OrderFormStatus.WILL_NOT_RENEW:
            return "Will not renew";
        case OrderFormStatus.OVERDUE:
            return "Overdue renewal";
        case OrderFormStatus.RENEWED:
            return "Renewed";
        default:
            return "";
    }
}

export const orderFormFilterStatus = Object.values(OrderFormStatus).map((v) => {
    return {
        text: orderStatusToString(v as OrderFormStatus),
        value: v,
    };
});

//status
//Popover
