import React from "react";
import { Typography, Tooltip, Row, Col } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { NegotiationLeverDetail } from "../../types/NegotiationTypes";
import { NegotiationLeverOutcomeTip } from "./NegotiationLeverOutcomeTip";

export interface NegotiationLeverItemProps {
    negotiationLever: NegotiationLeverDetail;
    onClick?: (lever: NegotiationLeverDetail) => void;
}

export const NegotiationLeverItem: React.FC<NegotiationLeverItemProps> = ({
    negotiationLever,
    onClick,
}) => {
    return (
        <div
            data-testid="test-lever"
            className="negotiationLever"
            style={{ cursor: "pointer" }}
            onClick={() => onClick && onClick(negotiationLever)}
        >
            <Row gutter={8} wrap={false}>
                <Col>
                    <PlusOutlined style={{ color: "#1890FF" }} />
                </Col>
                <Col style={{ maxWidth: "80%" }}>
                    <Typography.Text ellipsis>{negotiationLever.lever.name}</Typography.Text>
                </Col>
                <Col>
                    <Tooltip
                        title={<NegotiationLeverOutcomeTip negotiationLever={negotiationLever} />}
                    >
                        <ExclamationCircleOutlined />
                    </Tooltip>
                </Col>
            </Row>
        </div>
    );
};
