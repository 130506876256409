/* eslint-disable no-template-curly-in-string */
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import UsergroupAddOutlined from "@ant-design/icons/lib/icons/UsergroupAddOutlined";
import { Badge, Button, Card, Checkbox, Col, Form, Input, List, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Avatar from "../../common/components/Avatar";
import {
    getPreferencesForUser,
    updatePreferencesForUser,
} from "../../common/api/NotificationsClient";
import { getOrganization, getUsers, updateGroup, updateUser } from "../../common/api/UserClient";
import { removeFromArray } from "../../common/Array";
import { handleError } from "../../common/ErrorHandling";
import { shallowEqual } from "../../common/Objects";
import { NotificationChannel, UserNotificationPreferences } from "../../types/NotificationTypes";
import { Group, GroupUpdateRequest, User, UserStatus } from "../../types/UserGroup";
import { UserGroupSelectionModal } from "./UserGroupSelectionModal";
import { Errors } from "../../types/Error";

export interface UserEditPanelArgumentInteface {
    isAdmin?: boolean;
    userId: number;
}

export const UserEditPanel: React.FC<UserEditPanelArgumentInteface> = ({ isAdmin, userId }) => {
    const [theUser, setTheUser] = useState({} as User);
    const [theUserLoading, setTheUserLoading] = useState(true);
    const [showUGSelectionModal, setShowUGSelectionModal] = useState(false);
    const [preferencesLoading, setPreferencesLoading] = useState(true);
    const [preferencesWorkingCopy, setPreferencesWorkingCopy] = useState({
        notificationEmail: "",
        phoneNumber: "",
        notificationChannel: NotificationChannel.NONE,
    } as UserNotificationPreferences);
    const [preferences, setPreferences] = useState({} as UserNotificationPreferences);
    const [theUserWorkingCopy, setTheUserWorkingCopy] = useState({} as User);
    const [groups, setGroups] = useState([] as Group[]);
    const [organization, setOrganization] = useState("");
    useEffect(() => {
        const getOrg = async () => {
            try {
                const org = await getOrganization();
                setOrganization(org.name);
            } catch (error: any) {
                handleError(error);
            }
        };
        getOrg();
    }, []);

    useEffect(() => {
        setTheUserLoading(true);
        const loadUser = async () => {
            const users = await getUsers([userId]);
            if (users.length !== 1) {
                throw Errors.NOT_FOUND_ERROR.createNew("User not found");
            }
            const user = users[0];
            setTheUserWorkingCopy(JSON.parse(JSON.stringify(user)));
            setTheUser(user);
            const gr = user.groups || [];
            setGroups(JSON.parse(JSON.stringify(gr)) as Group[]);
        };
        try {
            loadUser();
        } catch (error: any) {
            handleError(error);
        } finally {
            setTheUserLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        if (theUser.email) {
            const loadPreferences = async () => {
                const preferences = await getPreferencesForUser(theUser.id);
                setPreferencesWorkingCopy(JSON.parse(JSON.stringify(preferences)));
                setPreferences(preferences);
            };
            try {
                loadPreferences();
            } catch (error: any) {
                handleError(error);
            } finally {
                setPreferencesLoading(false);
            }
            loadPreferences();
        }
    }, [theUser]);

    useEffect(() => {
        if (theUserWorkingCopy.email !== undefined) setTheUserLoading(false);
    }, [theUserWorkingCopy]);

    console.log(preferences, preferencesWorkingCopy);
    
    const hasUserChanged =
        theUser.email !== undefined && !shallowEqual(theUser, theUserWorkingCopy);
    const hasSettingsChanged = !shallowEqual(preferences, preferencesWorkingCopy);

    const changeMarkers = (attribute: string, label: string, a: any, b: any) => {
        let dot =
            a[attribute] !== undefined &&
            b[attribute] !== undefined &&
            a[attribute] !== b[attribute];
        return <Badge dot={dot}>{label}</Badge>;
    };

    const handleUserUpdate = async () => {
        setTheUserLoading(true);
        try {
            await updateUser(theUserWorkingCopy!);
            setTheUser(theUserWorkingCopy);
        } catch (error: any) {
            handleError(error);
        } finally {
            setTheUserLoading(false);
        }
    };

    const handlePreferencesUpdate = () => {
        updatePreferencesForUser(
            theUser!.id!,
            preferencesWorkingCopy as UserNotificationPreferences
        )
            .then((prefs) => {
                setPreferences(prefs);
                setPreferencesWorkingCopy(JSON.parse(JSON.stringify(prefs)));
            })
            .catch((error: any) => handleError(error));
    };

    const localUpdateGroup = async (group: Group, add: number[], remove: number[]) => {
        const req = { group: group, add: add, remove: remove } as GroupUpdateRequest;
        const updated = await updateGroup(req);
        if (updated) {
            if (remove.length > 0) {
                const removalGroup = groups.filter((g) => g.id === group.id)[0];
                removeFromArray(groups || ([] as Group[]), removalGroup);
                setGroups(([] as Group[]).concat(groups));
            }
            if (add.length > 0) {
                setGroups(groups.concat([group]));
            }
        }
        return updated;
    };

    const selectRecipientModal = showUGSelectionModal ? (
        <UserGroupSelectionModal
            blackList={groups?.map((v) => {
                return { id: v.id, type: v.type };
            })}
            type="g"
            onSelect={async (selection) => {
                const update = await localUpdateGroup(selection as Group, [theUser.id], []);
                if (update) {
                    setShowUGSelectionModal(false);
                }
            }}
            onCancel={() => {
                setShowUGSelectionModal(false);
            }}
        />
    ) : (
        <></>
    );

    const removeGroup = (group: Group) => {
        localUpdateGroup(group, [], [theUser.id]);
    };

    const updateUserStatus = (status: UserStatus) => {
        theUserWorkingCopy.status = status;
        setTheUserWorkingCopy({ ...theUserWorkingCopy });
        handleUserUpdate();
    };

    const userContent = (
        <Card
            size="small"
            style={{ height: 420 }}
            title={<div>User Information</div>}
            extra={
                isAdmin ? (
                    theUser.status === UserStatus.ACTIVE ||
                    theUser.status === UserStatus.WAITING ? (
                        <Button
                            size="small"
                            type="primary"
                            danger
                            onClick={() => {
                                updateUserStatus(UserStatus.DISABLED);
                            }}
                        >
                            DEACTIVATE
                        </Button>
                    ) : theUser.status === UserStatus.DISABLED ? (
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                                updateUserStatus(UserStatus.ACTIVE);
                            }}
                        >
                            ACTIVATE
                        </Button>
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )
            }
        >
            <Spin spinning={theUserLoading}>
                <Form.Item
                    label={changeMarkers("avatar", "Avatar", theUserWorkingCopy, theUser)}
                    style={{ paddingTop: 10 }}
                >
                    <Avatar email={theUserWorkingCopy.email} />
                </Form.Item>
                <Form.Item
                    label={changeMarkers("firstName", "First Name", theUserWorkingCopy, theUser)}
                    style={{ paddingTop: 10 }}
                >
                    <Input
                        value={theUserWorkingCopy.firstName}
                        onChange={(e) => {
                            theUserWorkingCopy.firstName = e.target.value;
                            setTheUserWorkingCopy({ ...theUserWorkingCopy });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={changeMarkers("lastName", "Last Name", theUserWorkingCopy, theUser)}
                >
                    <Input
                        value={theUserWorkingCopy.lastName}
                        onChange={(e) => {
                            theUserWorkingCopy.lastName = e.target.value;
                            setTheUserWorkingCopy({ ...theUserWorkingCopy });
                        }}
                    />
                </Form.Item>
                <Form.Item label="email">
                    <Input value={theUserWorkingCopy.email} disabled={true} />
                </Form.Item>
                <Form.Item label="Organization">
                    <Input value={organization} disabled={true} />
                </Form.Item>
                <Row key="buttonPanelAction">
                    <Col span={24}>
                        <div style={{ textAlign: "right" }}>
                            <Button
                                danger
                                disabled={!hasUserChanged}
                                onClick={() =>
                                    setTheUserWorkingCopy(JSON.parse(JSON.stringify(theUser)))
                                }
                            >
                                Cancel
                            </Button>
                            &nbsp;
                            <Button
                                type="primary"
                                disabled={!hasUserChanged}
                                onClick={() => handleUserUpdate()}
                            >
                                Update
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </Card>
    );

    const groupsPanel = (
        <Card
            style={{ height: 420 }}
            size={"small"}
            title={<div>My Groups</div>}
            extra={
                isAdmin ? (
                    <Button
                        type={"dashed"}
                        size="small"
                        onClick={() => setShowUGSelectionModal(true)}
                    >
                        <UsergroupAddOutlined />
                    </Button>
                ) : (
                    <></>
                )
            }
        >
            <List
                size="small"
                dataSource={groups}
                renderItem={(item) => {
                    return (
                        <List.Item
                            actions={
                                isAdmin && (item as any).muttable
                                    ? [
                                          <Button size={"small"}>
                                              <DeleteOutlined onClick={() => removeGroup(item)} />
                                          </Button>,
                                      ]
                                    : []
                            }
                        >
                            <List.Item.Meta
                                avatar={<Avatar email={item.name} />}
                                title={item.name}
                                description={item.description}
                            />
                        </List.Item>
                    );
                }}
            ></List>
        </Card>
    );

    const notificationPrefs = (
        <Card size="small" title={<div>Notification Preferences</div>}>
            <Spin spinning={preferencesLoading}>
                <Form
                    validateMessages={{
                        required: "${label} is required!",
                        types: {
                            email: "${label} is not a valid email!",
                            number: "${label} is not a valid number!",
                        },
                        number: {
                            range: "${label} must be between ${min} and ${max}",
                        },
                    }}
                >
                    <Row></Row>
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                label={changeMarkers(
                                    "phoneNumber",
                                    "Phone number",
                                    preferencesWorkingCopy,
                                    preferences
                                )}
                                style={{ paddingTop: 10 }}
                            >
                                <NumberFormat
                                    className={"ant-input"}
                                    value={preferencesWorkingCopy.phoneNumber}
                                    displayType={"input"}
                                    format="+1 (###) ###-####"
                                    mask="_"
                                    onValueChange={(value) => {
                                        preferencesWorkingCopy.phoneNumber = value.value;
                                        setPreferencesWorkingCopy({ ...preferencesWorkingCopy });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={changeMarkers(
                                    "notificationEmail",
                                    "Email",
                                    preferencesWorkingCopy,
                                    preferences
                                )}
                                rules={[{ type: "email" }]}
                            >
                                <Input
                                    value={preferencesWorkingCopy.notificationEmail}
                                    onChange={(e) => {
                                        preferencesWorkingCopy.notificationEmail = e.target.value;
                                        setPreferencesWorkingCopy({ ...preferencesWorkingCopy });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}></Col>
                        <Col span={10}>
                            <Form.Item>
                                <Checkbox
                                    checked={
                                        preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.SMS ||
                                        preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.EMAIL_AND_SMS
                                    }
                                    onChange={(e) => {
                                        if (
                                            preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.EMAIL_AND_SMS
                                        ) {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.EMAIL;
                                        } else if (
                                            preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.EMAIL
                                        ) {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.EMAIL_AND_SMS;
                                        } else if (
                                            preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.NONE
                                        ) {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.SMS;
                                        } else {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.NONE;
                                        }
                                        setPreferencesWorkingCopy({ ...preferencesWorkingCopy });
                                    }}
                                >
                                    {changeMarkers(
                                        "notificationChannel",
                                        "Notify SMS",
                                        preferencesWorkingCopy,
                                        preferences
                                    )}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox
                                    checked={
                                        preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.EMAIL ||
                                        preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.EMAIL_AND_SMS
                                    }
                                    onChange={(e) => {
                                        if (
                                            preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.EMAIL_AND_SMS
                                        ) {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.SMS;
                                        } else if (
                                            preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.SMS
                                        ) {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.EMAIL_AND_SMS;
                                        } else if (
                                            preferencesWorkingCopy.notificationChannel ===
                                            NotificationChannel.NONE
                                        ) {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.EMAIL;
                                        } else {
                                            preferencesWorkingCopy.notificationChannel =
                                                NotificationChannel.NONE;
                                        }
                                        setPreferencesWorkingCopy({ ...preferencesWorkingCopy });
                                    }}
                                >
                                    {changeMarkers(
                                        "notificationChannel",
                                        "Notify Email",
                                        preferencesWorkingCopy,
                                        preferences
                                    )}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row key="buttonPanelAction">
                        <Col span={24}>
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    danger
                                    disabled={!hasSettingsChanged}
                                    onClick={() => {
                                        setPreferencesWorkingCopy(
                                            JSON.parse(JSON.stringify(preferences))
                                        );
                                    }}
                                >
                                    Cancel
                                </Button>
                                &nbsp;
                                <Button
                                    type="primary"
                                    disabled={!hasSettingsChanged}
                                    onClick={() => handlePreferencesUpdate()}
                                >
                                    Update
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Card>
    );

    const toolsPanel = isAdmin ? (
        <Row>
            <Col></Col>
            <Col></Col>
            <Col></Col>
        </Row>
    ) : (
        <></>
    );

    const content = (
        <>
            {toolsPanel}
            <Row gutter={[8, 8]}>
                <Col span={12}>{userContent}</Col>
                <Col span={12}>{groupsPanel}</Col>
                <Col span={24}>{notificationPrefs}</Col>
            </Row>
        </>
    );

    const panel = (
        <Card>
            {selectRecipientModal}
            {content}
        </Card>
    );

    return panel;
};
