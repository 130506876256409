import { abort } from "process";
import { Resource, ResourceType } from "../../types/ResourceType";
import { Tag, TaggedResource } from "../../types/TagTypes";
import { safeDELETE, safeGET, safePOST } from "./SafeClient";

export async function getTags(
    ids: number[],
    type: ResourceType,
    abort?: any
): Promise<Map<number, TaggedResource>> {
    return new Promise<Map<number, TaggedResource>>((resolve, reject) => {
        const idq = ids.map((v) => `resourceId[]=${v}&resourceType[]=${type}`).join("&");
        safeGET<TaggedResource[]>(`/api/tag?${idq}`, abort)
            .then((response) => {
                resolve(
                    new Map<number, TaggedResource>(response.map((v) => [v.resource.resourceId, v]))
                );
            })
            .catch((error) => reject(error));
    });
}

export async function loadTagSuggestions(match: string, abort?: any): Promise<Tag[]> {
    return safeGET(`/api/tag/s?q=${match}`, abort);
}

export async function addTag(resource: Resource, tag: Tag): Promise<Tag> {
    return safePOST(`/api/tag/${resource.resourceType}/${resource.resourceId}`, tag, abort);
}

export async function removeTag(resource: Resource, tag: Tag): Promise<Tag> {
    return safeDELETE(
        `/api/tag/${resource.resourceType}/${resource.resourceId}/${tag.id}`,
        tag,
        abort
    );
}

export async function getAllOrderTags(expired?: boolean, abort?: any): Promise<Tag[]> {
    let expiredQuery = "";
    if (expired === true || expired === false) {
        expiredQuery = `?expired=${expired}`;
    }
    return safeGET(`/api/tag/order/all${expiredQuery}`, abort);
}

export async function getAllVendorTags(abort?: any): Promise<Tag[]> {
    return safeGET(`/api/tag/vendor/all`, abort);
}
