import React, { CSSProperties } from "react";
import { Col, Row, Space, Tooltip, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SupplierNews } from "../../../types/SupplierTypes";
import { roundToTwoDecimals } from "../../../common/Number";

export interface FinancialNewsCardProps {
    price?: number;
    changePercentage?: number;
    news?: SupplierNews[];
    tip?: string;
    style?: CSSProperties;
}

export const FinancialNewsCard: React.FC<FinancialNewsCardProps> = ({
    price,
    changePercentage,
    news,
    tip,
    style,
}) => {
    const _changePercentage = changePercentage || 0;
    const prefix = _changePercentage > 0 ? "↑" : _changePercentage < 0 ? "↓" : "";
    const formattedPercentage = roundToTwoDecimals(Math.abs(_changePercentage) * 100);
    const changeDisplay = `${prefix} ${formattedPercentage}%`;
    const color = _changePercentage > 0 ? "#52C41A" : _changePercentage < 0 ? "#FF7A1A" : undefined;

    return (
        <div style={style}>
            <div style={{ marginBottom: 8, fontWeight: 500, fontSize: 16 }}>
                <Space>
                    Key financial news
                    <Tooltip title={tip}>
                        <ExclamationCircleOutlined
                            style={{ color: "#1890FF " }}
                            data-testid="supplier-financial-news-card-tip"
                        />
                    </Tooltip>
                </Space>
            </div>
            <div
                style={{
                    borderRadius: 4,
                    background: "#F6F7F9",
                    padding: "12px 16px",
                }}
            >
                <div style={{ borderRadius: 4, background: "#FFF", padding: "8px 10px" }}>
                    <div>Stock performance/Funding</div>
                    <Row align="bottom">
                        <Col flex={1} style={{ fontSize: 16, fontWeight: 500, color }}>
                            {changeDisplay}
                        </Col>
                        <Col>${price || 0} today</Col>
                    </Row>
                </div>
                <div style={{ marginTop: 12 }}>Other news</div>
                <ul style={{ paddingLeft: 0, marginBottom: 0, listStyle: "none" }}>
                    {news?.map((item) => (
                        <li key={item.title} style={{ marginTop: 4 }}>
                            <div>
                                <Typography.Link href={item.url} target="_blank">
                                    {item.title}
                                </Typography.Link>
                                <div>{item.date}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
