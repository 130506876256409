export function removeFromArray(array: any[], element: any) {
    const index = array.indexOf(element);
    array.splice(index, 1);
}

export function mergeObjectArray<T extends Record<string, any>>(
    identifier: keyof T,
    ...arrs: (T[] | undefined)[]
) {
    const map = new Map(
        arrs
            .filter((obj) => Boolean(obj))
            .flat()
            .map((obj) => [(obj as T)[identifier], obj as T])
    );
    return Array.from(map.values());
}
