import { FC } from "react";
import "./App.css";

import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import OrderFormsView from "./components/orderform/OrderFormsView";
import { OrderFormsView as OrderFormsViewV2 } from "./components/orderform/OrderFormsViewV2";
import OrderFormDetailsView from "./components/orderform/OrderFormDetails";
import { VendorsView } from "./components/vendor/VendorsView";
import { NegotiationsView } from "./components/negotiations/NegotiationsView";
import { NegotiationDetailsView } from "./components/negotiations/NegotiationDetailsView";
import { OrganizationView } from "./components/vendor/OrganizationView";
import { VendorDetails } from "./components/vendor/VendorDetails";
import { NotificationsView } from "./components/notifications/NotificationsView";
import { UserStateProvider } from "./common/UserContext";
import { UserPreferencesView } from "./components/user/UserPreferencesView";
import { Calendar } from "./components/calendar/Calendar";
import { AdminUserGroupsView } from "./components/admin/AdminUserGroupsView";
import { AdminUserView } from "./components/admin/AdminUserView";
import { InviteView } from "./components/user/InviteView";
import UploadSummaryView from "./components/orderform/UploadSummaryView";
import { ReviewListCompleted } from "./components/review/ReviewList";
import ReviewListPage from "./components/review/ReviewListPage";
import ReviewDetail from "./components/review/ReviewDetail";
import { SupplierView } from "./components/supplier/SupplierView";
import { SupplierDetailView } from "./components/supplier/SupplierDetailView";
import { DashboardView } from "./components/dashboard-v2/DashboardView";
import { OrderFormScoreView } from "./components/orderform/OrderFormScoreView";
import { WristbandLogin } from "./components/login/WristbandLogin";
import { Login } from "./components/login/Login";
import { FeatureFlagsView } from "./components/admin/FeatureFlagsView";

/**
 *
 * TODO: OPTIMIZE ROUTE RENDERING
 */
const App: FC = () => (
    <Router>
        <UserStateProvider>
            <Routes>
                <Route path="/app/review/" element={<ReviewListPage />} />
                <Route path="/app/review/completed" element={<ReviewListCompleted />} />
                <Route path="/app/review/:documentId" element={<ReviewDetail />} />
                <Route path="/app/order/:orderFormId" element={<OrderFormDetailsView />} />
                <Route path="/app/order/list" element={<OrderFormsView />} />
                <Route path="/app/order/new" element={<OrderFormsViewV2 />} />
                <Route path="/app/order/new/:uploadId" element={<UploadSummaryView />} />
                <Route path="/app/order/score/:orderFormId" element={<OrderFormScoreView />} />
                <Route path="/app/calendar" element={<Calendar />} />
                <Route path="/app/dashboard" element={<DashboardView />} />
                <Route path="/app/vendor/:vendorKey" element={<VendorDetails />} />
                <Route path="/app/vendor" element={<VendorsView />} />
                <Route path="/app/notifications" element={<NotificationsView />} />
                <Route path="/app/preferences" element={<UserPreferencesView />} />
                <Route path="/app/login" element={<WristbandLogin />} />
                <Route path="/app/negotiations" element={<NegotiationsView />} />
                <Route
                    path="/app/negotiations/:negotiationId"
                    element={<NegotiationDetailsView />}
                />
                <Route path="/app/supplier" element={<SupplierView />} />
                <Route path="/app/supplier/:id" element={<SupplierDetailView />} />

                <Route path="/app/admin/orgs" element={<OrganizationView />} />
                <Route path="/app/admin/users" element={<AdminUserGroupsView />} />
                <Route path="/app/admin/users/:userId" element={<AdminUserView />} />
                <Route path="/app/admin/flags" element={<FeatureFlagsView />} />

                <Route path="/app/*" element={<Navigate to="/app/dashboard" />} />
                <Route path="/" element={<Navigate to="/app/dashboard" />} />
                <Route path="/invite/accept/:inviteId" element={<InviteView />} />
                <Route path="/internal/login" element={<Login />} />
            </Routes>
        </UserStateProvider>
    </Router>
);

export default App;
