import React, { useEffect, useState } from "react";
import { Switch, Table } from "antd";
import { createFlag, getFlags, updateFlag } from "../../../common/api/FeatureFlagClient";
import { FeatureFlag, FeatureFlagType } from "../../../types/FeatureFlagTypes";
import { handleError } from "../../../common/ErrorHandling";
import { mergeObjectArray } from "../../../common/Array";

const DEFAULT_FLAGS = [
    {
        name: "Block Upload",
        description: "Only super admin can upload if enabled",
        flagType: FeatureFlagType.Upload,
        enabled: false,
    } as FeatureFlag,
];

export const FeatureFlagTable: React.FC = () => {
    const [flags, setFlags] = useState([] as FeatureFlag[]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);

    const getFeatureFlags = async () => {
        setLoading(true);
        try {
            const res = await getFlags();
            const merged = mergeObjectArray("flagType", DEFAULT_FLAGS, res);
            setFlags(merged);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const refresh = () => {
        setReload(reload + 1);
    };

    useEffect(() => {
        getFeatureFlags();
    }, [reload]);

    const handleFlagChange = async (value: boolean, row: FeatureFlag) => {
        try {
            const toUpdate = {
                ...row,
                enabled: value,
            };
            if (row.id) {
                await updateFlag(toUpdate);
            } else {
                await createFlag(toUpdate);
            }
            refresh();
        } catch (error) {
            handleError(error);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "Name",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "Description",
        },
        {
            title: "Enabled",
            dataIndex: "enabled",
            key: "Enabled",
            render: (value: boolean, row: FeatureFlag) => {
                return (
                    <Switch
                        checked={value}
                        onChange={(value) => handleFlagChange(value, row)}
                    ></Switch>
                );
            },
        },
    ];
    return (
        <Table
            dataSource={flags}
            rowKey="name"
            columns={columns}
            pagination={false}
            loading={loading}
        ></Table>
    );
};
