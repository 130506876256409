import React, { useState } from "react";
import { Image } from "antd";
import { NameAvatar } from "../../../common/components/NameAvatar";

interface CompanyLogoProps {
    name?: string;
    logo?: string;
    size?: number;
    className?: string;
}

export const CompanyLogo: React.FC<CompanyLogoProps> = ({
    logo = "",
    name = "",
    size = 48,
    className,
}) => {
    const [imgError, setImgError] = useState(false);

    const handleImgError = () => {
        setImgError(true);
    };

    return (
        <div
            className={className}
            style={{
                borderRadius: 4,
                border: "1px solid #D9D9D9",
                width: size,
                height: size,
                padding: 8,
            }}
            data-testid="company-logo-box"
        >
            {logo && !imgError ? (
                <Image
                    src={logo}
                    preview={false}
                    data-testid="company-logo"
                    onError={handleImgError}
                ></Image>
            ) : (
                <NameAvatar name={name}></NameAvatar>
            )}
        </div>
    );
};
