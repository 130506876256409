import { Col, Row, Space } from "antd";
import React from "react";
import { LeverRangeToggle } from "../../../common/components/LeverRangeToggle";
import { ColorType, getTypeColor } from "../utils/Color";

interface OrderScoreToolBarProps {
    type?: ColorType;
    usedPercentage: number;
    usedProcurementNumber: number;
    totalProcurementNumber: number;
    usedLegalNumber: number;
    totalLegalNumber: number;
    showToggle?: boolean;
    toggleValue?: number;
    onToggleChange?: (value: number) => void;
    afterToggleChange?: (value: number) => void;
}

export const OrderScoreToolBar: React.FC<OrderScoreToolBarProps> = ({
    type = "error",
    usedPercentage,
    usedProcurementNumber,
    totalProcurementNumber,
    usedLegalNumber,
    totalLegalNumber,
    showToggle,
    toggleValue = 50,
    onToggleChange,
    afterToggleChange,
}) => {
    const typeColor = getTypeColor(type);

    return (
        <Row align="middle">
            <Col flex={1}>
                <Space align="center" size={24} style={{ marginBlock: 16 }}>
                    <span style={{ fontSize: 20, fontWeight: 500, color: typeColor.text }}>
                        {usedPercentage}% lever use
                    </span>
                    <Space size={16}>
                        <span>
                            Procurement:
                            <span style={{ fontWeight: 600, color: typeColor.text }}>
                                {" "}
                                {usedProcurementNumber}/{totalProcurementNumber}
                            </span>
                        </span>
                        <span>
                            legal:
                            <span style={{ fontWeight: 600, color: typeColor.text }}>
                                {" "}
                                {usedLegalNumber}/{totalLegalNumber}
                            </span>
                        </span>
                    </Space>
                </Space>
            </Col>
            {showToggle && (
                <Col>
                    <LeverRangeToggle
                        value={toggleValue}
                        onChange={onToggleChange}
                        afterChange={afterToggleChange}
                    />
                </Col>
            )}
        </Row>
    );
};
