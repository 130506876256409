import { PagerObject } from "../../types/DataFetchingTypes";
import { AnnotationSKU, AnnotationValue } from "../../types/Document";
import {
    DocumentReviewResponse,
    DocumentTranscript,
    ExpandedDocumentReview,
    ReviewSubmitResponse,
    SaveReviewResponse,
    TranscriptRequestResponse,
} from "../../types/ReviewTypes";
import { SortFilterPaged } from "../CommonInterfaces";
import { safeDELETE, safeGET, safePOST, safePUT } from "./SafeClient";

export function listReviews(
    pager: SortFilterPaged<ExpandedDocumentReview>,
    vendorKeys?: string[],
    abort?: any
): Promise<PagerObject<ExpandedDocumentReview[]>> {
    const sorting = pager.sort
        .map((v) => `sort[]=${v.attribute}&order[]=${v.order}`)
        .reduce((p, v) => `${p}&${v}`, "");
    const paging = `current=${pager.pager.pagination.current}&pageSize=${pager.pager.pagination.pageSize}`;
    const filtering = pager.filter
        .map((v) => {
            return v.filter
                .map((fv) => {
                    return `filter[]=${fv.attribute}&operand[]=${fv.operand}&value[]=${fv.value}&group[]=${v.groupId}&gop[]=${v.groupType}`;
                })
                .join("&");
        })
        .reduce((p, v) => `${p}&${v}`, "");
    let vendorQuery = "";
    if (vendorKeys?.length) {
        vendorQuery = vendorKeys.map((v) => `vendorKey[]=${v}`).reduce((p, v) => `${p}&${v}`, "");
    }
    return safeGET(`/api/review?${paging}${sorting}${filtering}${vendorQuery}`, abort);
}

export function getReview(documentId: string, abort?: any): Promise<DocumentReviewResponse> {
    return safeGET(`/api/review/${documentId}`, abort);
}

export function getTranscript(documentId: string, abort?: any): Promise<DocumentTranscript> {
    return safeGET(`/api/review/${documentId}/transcript`, abort);
}

export function updateReview(
    documentId: string,
    skus: AnnotationSKU[],
    attributes: AnnotationValue[],
    abort?: any
): Promise<SaveReviewResponse> {
    return safePUT(`/api/review/${documentId}`, { skus: skus, attributes: attributes }, abort);
}

export function submittReview(documentId: string, abort?: any): Promise<ReviewSubmitResponse> {
    return safePUT(`/api/review/${documentId}/complete`, {}, abort);
}

export function assignUser(documentId: string, userId: number, abort?: any): Promise<boolean> {
    return safePUT(`/api/review/${documentId}/assign`, { userId: userId }, abort);
}

export function startReview(documentId: string, abort?: any): Promise<boolean> {
    return safePUT(`/api/review/${documentId}/start`, {}, abort);
}

export function deleteReview(documentSerialId: string, abort?: any): Promise<boolean> {
    return safeDELETE(`/api/review/${documentSerialId}`, {}, abort);
}

export function requestTranscript(
    documentId: string,
    abort?: any
): Promise<TranscriptRequestResponse> {
    return safePOST(`/api/review/${documentId}/transcript`, {}, abort);
}
