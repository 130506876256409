export interface Pagination {
    pages: number;
    current: number;
    total: number;
    pageSize: number;
}

export interface PagerObject<TYPE> {
    pagination: Pagination;
    sort?: Sort[];
    filter?: FilterGroup[];
    data: TYPE;
}

export interface PageRequest {
    current: number;
    pageSize: number;
}

export enum SortOrder {
    ASC = "ASC",
    DESC = "DESC",
}

export function sortOrderTranslate(sortOrder: string): SortOrder {
    switch (sortOrder) {
        case "ascend":
            return SortOrder.ASC;
        case "descend":
            return SortOrder.DESC;
    }
    return SortOrder.ASC;
}

export interface Sort {
    attribute: string;
    prefix?: string;
    order: SortOrder;
}
export enum FilterOperand {
    EQUALS = "eq",
    GREATER = "gt",
    LOWER = "lt",
    DISCTINT = "neq",
    SIMILAR = "sim",
}

export enum FilterGroupType {
    AND = "and",
    OR = "or",
}

export interface FilterGroup {
    groupId: string;
    groupType: FilterGroupType;
    filter: Filter[];
}

export interface Filter {
    attribute: string;
    value: string;
    operand: FilterOperand;
}

export interface DataFetchRequest {
    paging: PageRequest;
    sort: Sort[];
    filter: FilterGroup[];
}

export interface RemoteLocation {
    Bucket: string;
    Key: string;
}
