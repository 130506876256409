import React from "react";
import { Button } from "antd";
import { SupplierInsight } from "../../types/SupplierTypes";
import { FinancialNewsCard, FinancialNewsCardProps } from "./components/FinancialNewsCard";
import { InsightsCard } from "./components/InsightsCard";

interface SupplierDetailRightPanelProps {
    insights: SupplierInsight[];
    financialNewsOptions: Pick<FinancialNewsCardProps, "price" | "changePercentage" | "news">;
    onCreate?: () => void;
    loading?: boolean;
    showPdfButton?: boolean;
}

export const SupplierDetailRightPanel: React.FC<SupplierDetailRightPanelProps> = ({
    insights,
    financialNewsOptions,
    onCreate,
    loading,
    showPdfButton = true,
}) => {
    return (
        <div>
            {showPdfButton && (
                <Button type="primary" loading={loading} onClick={onCreate}>
                    Create PDF
                </Button>
            )}
            <InsightsCard
                insights={insights}
                tip="Analytical observations or interpretations about the company's performance, strategy, market presence, or any other significant aspect that can provide a deeper understanding."
                style={{ marginTop: 30 }}
            />
            <FinancialNewsCard
                {...financialNewsOptions}
                tip="The five most significant or recent news articles or headlines pertaining to the company, which could range from product launches, financial results, to major partnerships or controversies."
                style={{ marginTop: 30 }}
            />
        </div>
    );
};
