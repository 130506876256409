import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
// import { SegmentedValue } from "antd/lib/segmented";
import { EllipsisOutlined } from "@ant-design/icons";
import MainLayout from "../navigation/MainLayout";
import { TimeQuarter } from "../../types/TimeTypes";
import { Pagination } from "../../types/DataFetchingTypes";
import { Supplier } from "../../types/SupplierTypes";
import {
    deleteSupplier,
    listSuppliers,
    updateSupplier,
    getUserSupplierSetting,
    updateUserSupplierSetting,
} from "../../common/api/SupplierClient";
import { useUserState } from "../../common/UserContext";
import { roundToTwoDecimals } from "../../common/Number";
import { handleError } from "../../common/ErrorHandling";
import { CompanyName } from "./components/CompanyName";
import { SupplierScore } from "./components/SupplierScore";
import { ESGScore } from "./components/ESGScore";
import { SupplierGenratorPanel } from "./SupplierGeneratorPanel";
import { SupplierEditModal } from "./components/SupplierEditModal";

export const SupplierView = () => {
    const userState = useUserState();
    const navigate = useNavigate();
    const [selectedQuarter] = useState<TimeQuarter>();
    const [pagination, setPagination] = useState({
        pages: 0,
        current: 1,
        total: 0,
        pageSize: 10,
    } as Pagination);
    const [data, setData] = useState<Supplier[]>([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [showGenerator, setShowGenerator] = useState(false);
    const [supplierToEdit, setSupplierToEdit] = useState({} as Supplier);
    const [showEditModal, setShowEditModal] = useState(false);

    // const quarters = getFourQuarters(new Date());
    // const options = ["All", ...quarters.map((q) => `${q.year} Q${q.quarter}`)];
    const [alwaysShowGenerator, setAlwaysShowGenerator] = useState(false);

    // const handleQuarterChange = (q: SegmentedValue) => {
    //     const idx = options.indexOf(q as string);
    //     if (idx > 0) {
    //         const quarter = quarters[idx - 1];
    //         setSelectedQuarter(quarter);
    //     } else {
    //         setSelectedQuarter(undefined);
    //     }
    //     setReload(reload + 1);
    // };

    const handleTableChange = (pagination: any) => {
        setPagination(pagination);

        setReload(reload + 1);
    };

    const refreshTable = async () => {
        try {
            setLoading(true);
            const res = await listSuppliers(pagination, [], [], selectedQuarter);
            setData(res.data);
            setPagination(res.pagination);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const editSupplier = async (form: Supplier) => {
        await updateSupplier(form);
        setShowEditModal(false);
        refreshTable();
    };

    const removeSupplier = async (id: number) => {
        await deleteSupplier(id);
        refreshTable();
    };

    const refreshUserSupplierSetting = async () => {
        if (userState.me?.id) {
            const setting = await getUserSupplierSetting(userState.me.id);
            setAlwaysShowGenerator(setting.alwaysShowGenerator);
            if (!showGenerator) {
                setShowGenerator(setting.alwaysShowGenerator);
            }
        }
    };

    const editUserSupplierSetting = async (checked: boolean) => {
        if (userState.me?.id) {
            await updateUserSupplierSetting({
                userId: userState.me.id,
                alwaysShowGenerator: checked,
            });

            refreshUserSupplierSetting();
        }
    };

    useEffect(() => {
        refreshUserSupplierSetting();
    }, [userState]);

    useEffect(() => {
        refreshTable();
    }, [reload]);

    const columns = [
        {
            title: "Company name",
            dataIndex: "name",
            key: "name",
            render: (value: string, row: Supplier) => {
                return (
                    <CompanyName
                        name={value}
                        logo={row.logo}
                        link={`/app/supplier/${row.id}`}
                        domain={row.website}
                        linkedin={row.linkedIn}
                        twitter={row.twitter}
                    />
                );
            },
        },
        {
            title: "Supplier score",
            dataIndex: "esgScore",
            key: "supplierScore",
            width: 130,
            render: (value: number) => {
                const score = roundToTwoDecimals(value / 20);
                return <SupplierScore score={score} />;
            },
        },
        {
            title: "ESG score",
            dataIndex: "esgScore",
            key: "esgScore",
            render: (value: number) => {
                return <ESGScore score={value} />;
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Actions",
            dataIndex: "id",
            key: "actions",
            render: (value: number, row: Supplier) => {
                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: (
                                        <Button
                                            type="text"
                                            onClick={() => {
                                                setSupplierToEdit(row);
                                                setShowEditModal(true);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    ),
                                    key: "edit",
                                },
                                {
                                    label: (
                                        <Button
                                            type="text"
                                            danger
                                            onClick={() => removeSupplier(value)}
                                        >
                                            Delete
                                        </Button>
                                    ),
                                    key: "delete",
                                },
                            ],
                        }}
                    >
                        <EllipsisOutlined style={{ color: "#1890FF" }} />
                    </Dropdown>
                );
            },
        },
    ];

    const supplierTable = (
        <Table
            dataSource={data}
            pagination={pagination}
            loading={loading}
            rowKey="id"
            onChange={handleTableChange}
            columns={columns}
        ></Table>
    );

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <Card>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 20,
                    }}
                >
                    <div>
                        <div style={{ fontSize: 16 }}>Buyer briefs</div>
                        <div style={{ color: "#666" }}>
                            Unlock detailed insights into prospective 3rd party business partners
                            including in-depth company analysis, competitor overview,
                            differentiators, and key negotiation strategies to secure the best deal
                            possible.
                        </div>
                    </div>
                    <Space>
                        <Button type="primary" onClick={() => setShowGenerator(true)}>
                            Add Buyer brief
                        </Button>
                        {
                            //               <Segmented
                            //     options={options}
                            //     style={{ marginLeft: 12 }}
                            //     onChange={handleQuarterChange}
                            // />
                        }
                        <SupplierGenratorPanel
                            open={showGenerator}
                            onClose={() => setShowGenerator(false)}
                            onCreated={(supplier) => navigate(`/app/supplier/${supplier.id}`)}
                            alwaysShow={alwaysShowGenerator}
                            onAlwaysShowChange={editUserSupplierSetting}
                        />
                    </Space>
                </div>
                <div style={{ marginTop: 20 }}>{supplierTable}</div>
            </Card>
            <SupplierEditModal
                open={Boolean(showEditModal && supplierToEdit.id)}
                supplier={supplierToEdit}
                onCancel={() => setShowEditModal(false)}
                onOk={(form) => {
                    editSupplier(form);
                }}
            />
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"SupplierSheets"} collapsed={true} />;
};
