import React from "react";
import { Row, Slider, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export function getLeverRangeSliderClass(weight: number) {
    let className = "leverRangeSlider";
    if (weight > 50) {
        className += " procurementLeverRangeSlider";
    } else if (weight < 50) {
        className += " legalLeverRangeSlider";
    }
    return className;
}

export function getLeverRangeSliderTooltip(value?: number) {
    const legalWeight = Number(value);
    const diff = legalWeight - 50;
    const title = legalWeight < 50 ? "Procurement" : legalWeight > 50 ? "Legal" : "Range";
    const typedWeight = diff < 0 ? 50 - diff : 50 + diff;

    return (
        <>
            <Row justify="center">{title}</Row>
            <Row justify="center">
                {typedWeight}/{100 - typedWeight}
            </Row>
        </>
    );
}

interface LeverRangeToggleProps {
    value: number;
    onChange?: (value: number) => void;
    afterChange?: (value: number) => void;
}

export const LeverRangeToggle: React.FC<LeverRangeToggleProps> = ({
    value,
    onChange,
    afterChange,
}) => {
    return (
        <Row align="middle">
            <span>Lever range</span>
            <Tooltip title="Move the slider to allocate weighting between Procurement and Legal levers">
                <ExclamationCircleOutlined
                    style={{ marginLeft: 4 }}
                    data-testid="lever-range-toggle-trigger"
                />
            </Tooltip>
            <Slider
                className={getLeverRangeSliderClass(value)}
                tooltip={{
                    formatter: getLeverRangeSliderTooltip,
                }}
                style={{ marginLeft: 16, width: 72 }}
                value={value}
                onChange={onChange}
                onAfterChange={afterChange}
            />
        </Row>
    );
};
