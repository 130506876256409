import React from "react";
import { Modal, Table } from "antd";
import NumberFormat from "react-number-format";
import { ESGScore } from "../../supplier/components/ESGScore";
import { Industry } from "../../../types/DashboardTypes";
import { SimulationScore } from "./SimulationScore";
import "./IndustryModal.css";

interface IndustryModalProps {
    open: boolean;
    industry: Industry;
    onCancel?: () => void;
}

export const IndustryModal: React.FC<IndustryModalProps> = ({ industry, open, onCancel }) => {
    const columns = [
        {
            title: "Vendor name",
            dataIndex: "name",
            key: "Name",
        },
        {
            title: "Contract value",
            dataIndex: "value",
            key: "Value",
            render: (value: number) => {
                return (
                    <NumberFormat
                        value={value}
                        thousandSeparator={true}
                        displayType={"text"}
                        prefix={"$"}
                    />
                );
            },
        },
        {
            title: "Simulation score",
            dataIndex: "simulationScore",
            key: "SimulationScore",
            render: (value?: number) => {
                return <SimulationScore score={value || 0} />;
            },
        },
        {
            title: "ESG score",
            dataIndex: "esgScore",
            key: "EsgScore",
            render: (value?: number) => {
                return <ESGScore score={value || 0} />;
            },
        },
    ];

    const ModalTitle = () => (
        <div>
            <span>{industry.sectorName}</span>
            <span style={{ marginLeft: "1rem", color: "#666", fontSize: 12 }}>{industry.name}</span>
        </div>
    );

    const handleRowClass = (_: any, index: number) => {
        return index === 0 ? "highlightRow" : "";
    };

    return (
        <Modal open={open} onCancel={onCancel} title={<ModalTitle />} width={1200} footer={null}>
            <Table
                dataSource={industry.companies}
                columns={columns}
                pagination={false}
                rowKey="name"
                size="small"
                rowClassName={handleRowClass}
            ></Table>
        </Modal>
    );
};
