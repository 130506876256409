export enum GradeColor {
    ERROR = "#F5222D",
    WARNING = "#FF7A1A",
    SUCCESS = "#18AC00",
}
export const grades = [
    {
        max: 101,
        min: 97,
        grade: "A+",
        color: GradeColor.SUCCESS,
    },
    {
        max: 97,
        min: 93,
        grade: "A",
        color: GradeColor.SUCCESS,
    },
    {
        max: 93,
        min: 90,
        grade: "A-",
        color: GradeColor.SUCCESS,
    },
    {
        max: 90,
        min: 87,
        grade: "B+",
        color: GradeColor.SUCCESS,
    },
    {
        max: 87,
        min: 83,
        grade: "B",
        color: GradeColor.WARNING,
    },
    {
        max: 83,
        min: 80,
        grade: "B-",
        color: GradeColor.WARNING,
    },
    {
        max: 80,
        min: 77,
        grade: "C+",
        color: GradeColor.WARNING,
    },
    {
        max: 77,
        min: 73,
        grade: "C",
        color: GradeColor.WARNING,
    },
    {
        max: 73,
        min: 70,
        grade: "C-",
        color: GradeColor.ERROR,
    },
    {
        max: 70,
        min: 67,
        grade: "D+",
        color: GradeColor.ERROR,
    },
    {
        max: 67,
        min: 63,
        grade: "D",
        color: GradeColor.ERROR,
    },
    {
        max: 63,
        min: 60,
        grade: "D-",
        color: GradeColor.ERROR,
    },
    {
        max: 60,
        min: 0,
        grade: "F",
        color: GradeColor.ERROR,
    },
];

export const getGrade = (score: number) => {
    return grades.find((grade) => grade.max > score && grade.min <= score);
};
