export enum FeatureFlagType {
    Upload = 1,
}

export function toFeatureFlagType(val: number) {
    switch (val) {
        case 1:
            return FeatureFlagType.Upload;
    }
}

export interface FeatureFlag {
    id: number;
    name: string;
    description: string;
    flagType: FeatureFlagType;
    enabled: boolean;
}
