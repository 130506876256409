import React from "react";
import { Tag } from "antd";
import { NegotiationStatus } from "../../types/NegotiationTypes";

interface NegotiationStatusTagProps {
    status: NegotiationStatus;
}

export function negotiationStatusToString(status: NegotiationStatus) {
    switch (status) {
        case NegotiationStatus.NOT_STARTED:
            return "Not started";
        case NegotiationStatus.IN_PROGRESS:
            return "In progress";
        case NegotiationStatus.COMPLETE:
            return "Complete";
        case NegotiationStatus.CANCELLED:
            return "Cancelled";
        default:
            return "";
    }
}

export function negotiationStatusToTagColor(status: NegotiationStatus) {
    switch (status) {
        case NegotiationStatus.NOT_STARTED:
        case NegotiationStatus.CANCELLED:
            return "error";
        case NegotiationStatus.IN_PROGRESS:
            return "warning";
        case NegotiationStatus.COMPLETE:
            return "success";
        default:
            return "";
    }
}

export const NegotiationStatusTag: React.FC<NegotiationStatusTagProps> = ({ status }) => {
    return (
        <Tag color={negotiationStatusToTagColor(status)}>{negotiationStatusToString(status)}</Tag>
    );
};
