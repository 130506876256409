import { Row, Col, Table, Input, Image, Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createOrg, listOrgs } from "../../common/api/OrganizationClient";
import { impersonateOrgBot } from "../../common/api/UserClient";

import { handleError } from "../../common/ErrorHandling";

import MainLayout from "../navigation/MainLayout";
import { Vendor } from "../../types/VendorTypes";

const { Column } = Table;

export const OrganizationView: React.FC = () => {
    const [data, setData]: any[] = useState([]);
    const [loading, setLoading]: any[] = useState(true);

    const createOrgClick = async (name: string) => {
        setLoading(true);

        createOrg(name)
            .then((data: any) => {
                alert("created org")
            })
            .catch((error) => {
                alert("failed to create org")
            })
            .finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        setLoading(true);
        listOrgs()
            .then((data: any) => {
                setData(data);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const vendorTable = (
        <Table
            dataSource={data}
            loading={loading}
            rowKey="vendorKey"
        >
            <Column
                title=""
                dataIndex="hero"
                render={(value) => {
                    return <Image width={40} preview={false} src={value} />;
                }}
            />
            <Column
                title="Vendor Name"
                sorter={true}
                dataIndex="name"
                key="name"
                render={(value, row: Vendor) => {
                    return <Link to={`/app/vendor/${row.vendorKey}`}>{value}</Link>;
                }}
            />
            <Column
                title="Impersonate"
                sorter={true}
                dataIndex="impersonate"
                key="impersonate"
                render={(value, row: Vendor) => {
                    console.log('xxxx', value, row)
                    return <Button onClick={() => impersonateOrgBot(row.id)}>impersonate</Button>;
                }}
            />
        </Table>
    );

    const onFinish = (values: any) => {
        createOrgClick(values.orgName)
        console.log('Success:', values);
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    
    const panel = (
        <div style={{ paddingTop: 10 }}>

            <div>
                <h2>Create Org</h2>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Org Name"
                        name="orgName"
                        rules={[{ required: true, message: 'Please input your org name!' }]}
                    >
                        <Input placeholder="Org Name" />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Create New Org
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <hr />
            <Row justify={"center"}>
                <Col span={24}>{vendorTable}</Col>
            </Row>
        </div>
    );

    return <MainLayout mainPanel={<>{panel}</>} selected={"orgs"} collapsed={true} />;
};
