export function roundToTwoDecimals(num: number) {
    return Math.round(num * 100) / 100;
}

export function getTrendDataPrefix(num: number) {
    return num > 0 ? "↑" : num < 0 ? "↓" : "";
}

export function getTrendDataColor(num: number, defaultColor?: string) {
    return num > 0 ? "#52C41A" : num < 0 ? "#FF7A1A" : defaultColor;
}

export function formatNumberWithSuffix(num: number, precision = 1) {
    const suffixs = [
        { suffix: "T", threshold: 1e12 },
        { suffix: "B", threshold: 1e9 },
        { suffix: "M", threshold: 1e6 },
        { suffix: "K", threshold: 1e3 },
        { suffix: "", threshold: 1 },
    ];

    const found = suffixs.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
        const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
        return formatted;
    }

    return num;
}

export function formatNumber(num: number, precision = 1, prefix = "$") {
    const withSuffix = formatNumberWithSuffix(num, precision);
    return `${prefix}${withSuffix}`;
}

interface CovertOption {
    language: string;
    currency: string;
}
export function convertNumberToCurrency(
    value: number,
    option: CovertOption = { language: "en-US", currency: "USD" }
) {
    return value.toLocaleString(option.language, {
        style: "currency",
        currency: option.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
}
