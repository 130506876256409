import { CSSProperties, ReactNode } from "react";
import { message, notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

export function handleError(
    error: any,
    text?: ReactNode,
    description: ReactNode = "Something went wrong. Please try again later.",
    style?: CSSProperties,
    duration = 10,
    placement: NotificationPlacement = "top"
) {
    if (error.isILError) {
        if (error.code === 700) {
            if (text !== undefined) message.warning(text);
            message.warning(error.misc);
        } else {
            notification.error({
                message: text || error.message,
                description,
                style,
                duration,
                placement,
            });
        }
    } else {
        if (text !== undefined) message.error(text);
        console.error(error);
        // message.error(JSON.stringify(error));
    }
}
