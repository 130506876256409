import { AddCommentRequest, Comment } from "../../types/CommentTypes";
import { Resource } from "../../types/ResourceType";
import { safeGET, safePOST } from "./SafeClient";

export function getComments(resource: Resource, abort?: any): Promise<Comment[]> {
    return safeGET(`/api/comment/${resource.resourceType}/${resource.resourceId}`, abort);
}

export function addComment(resource: Resource, comment: AddCommentRequest, abort?: any): Promise<Comment> {
    return safePOST(`/api/comment/${resource.resourceType}/${resource.resourceId}`, comment, abort);
}