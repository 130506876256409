import React from "react";
import { Button, Select, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { OrderFormLeverDetail } from "../../../types/OrderFormTypes";
import { NegotiationLeversCategory } from "../../../types/NegotiationTypes";
import { getGrade } from "../../negotiations/config/grades";

export interface OrderFormLeverTableProps {
    levers?: OrderFormLeverDetail[];
    enableEditing?: boolean;
    onChange?: (optionId: number, lever: OrderFormLeverDetail) => void;
    onRemove?: (lever: OrderFormLeverDetail) => void;
}

const LeverTypeMap = {
    [NegotiationLeversCategory.lEGAL_LEVERS]: "Legal",
    [NegotiationLeversCategory.PROCUREMENT_LEVERS]: "Procurement",
};

export const UNAVAILABLE_VALUE = "Not available";
export const UNAVAILABLE_GRADE = "F";
export enum USE_STATUS {
    YES = "Yes",
    NO = "No",
}

export const OrderFormLeversTable: React.FC<OrderFormLeverTableProps> = ({
    levers,
    enableEditing = false,
    onChange,
    onRemove,
}) => {
    const handleLeverSelect = (optionId: number, lever: OrderFormLeverDetail) => {
        if (!lever.isSelected) {
            onChange?.(optionId, lever);
            return;
        }
        if (optionId !== lever.selectedOptionId) {
            onChange?.(optionId, lever);
        }
    };

    const columns = [
        {
            title: "Lever",
            key: "name",
            render: (_: unknown, row: OrderFormLeverDetail) => {
                return row.lever.name;
            },
        },
        {
            title: "Lever Type",
            key: "type",
            sorter: (a: OrderFormLeverDetail) =>
                a.lever.category === NegotiationLeversCategory.PROCUREMENT_LEVERS ? -1 : 1,
            render: (_: unknown, row: OrderFormLeverDetail) => {
                return LeverTypeMap[row.lever.category];
            },
        },
        {
            title: "Value",
            key: "value",
            render: (_: unknown, row: OrderFormLeverDetail) => {
                const selectOptions = row.lever.options.map((option) => ({
                    value: option.id,
                    label: option.label,
                }));
                if (enableEditing) {
                    return (
                        <Select
                            value={row.selectedOptionId}
                            options={selectOptions}
                            style={{ width: "100%" }}
                            bordered={false}
                            onSelect={(value) => handleLeverSelect(value, row)}
                        ></Select>
                    );
                }
                if (!row.isSelected) return UNAVAILABLE_VALUE;

                const selectOption = row.lever.options.find(
                    (option) => option.id === row.selectedOptionId
                );
                return selectOption?.label;
            },
        },
        {
            title: "Used",
            dataIndex: "isSelected",
            key: "used",
            sorter: (a: OrderFormLeverDetail) => (a.isSelected ? -1 : 1),
            render: (value: boolean) => {
                const text = value ? USE_STATUS.YES : USE_STATUS.NO;
                const color = value ? "#18AC00" : "";
                return <span style={{ color }}>{text}</span>;
            },
        },
        {
            title: "Score",
            key: "score",
            render: (_: unknown, row: OrderFormLeverDetail) => {
                if (!row.isSelected) return UNAVAILABLE_GRADE;

                const selectOption = row.lever.options.find(
                    (option) => option.id === row.selectedOptionId
                );
                const found = getGrade(selectOption?.weight || 0);
                return <span style={{ color: found?.color, fontWeight: 700 }}>{found?.grade}</span>;
            },
        },
        ...(enableEditing
            ? [
                  {
                      title: "Actions",
                      dataIndex: "",
                      key: "actions",
                      render: (_: unknown, row: OrderFormLeverDetail) => {
                          return row.isSelected ? (
                              <Button
                                  danger
                                  data-testid="remove-order-lever-button"
                                  type="link"
                                  icon={<CloseOutlined />}
                                  onClick={() => onRemove?.(row)}
                              ></Button>
                          ) : null;
                      },
                  },
              ]
            : []),
    ];
    const handleRowClass = (row: OrderFormLeverDetail) => {
        return row.isSelected ? "newlyAddedRow" : "";
    };

    return (
        <Table
            dataSource={levers}
            columns={columns}
            size="small"
            pagination={false}
            rowClassName={handleRowClass}
            rowKey="id"
        ></Table>
    );
};
