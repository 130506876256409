import moment from "moment";

export interface PeriodDates {
    startDate: Date | undefined;
    endDate: Date | undefined;
}
export enum TimePeriods {
    Next12Months = "Next 12 Months",
    Next30Days = "Next 30 Days",
    ThisMonth = "This Month",
    ThisQuarter = "This Quarter",
    NextQuarter = "Next Quarter",
    NextSixMonths = "Last 6 Months",
    ThisYear = "This Year",
    Custom = "Custom...",
}
export const getPeriodRange = (period: TimePeriods): Date[] => {
    const currentDate = moment(new Date());

    switch (period) {
        case TimePeriods.Next12Months:
            return [currentDate.toDate(), currentDate.add(12, "months").toDate()];
        case TimePeriods.Next30Days:
            return [currentDate.toDate(), currentDate.add(30, "days").toDate()];
        case TimePeriods.ThisMonth:
            return [currentDate.startOf("month").toDate(), currentDate.endOf("month").toDate()];
        case TimePeriods.ThisQuarter:
            return [currentDate.startOf("quarter").toDate(), currentDate.endOf("quarter").toDate()];
        case TimePeriods.NextQuarter:
            const nextQuarter = currentDate.add(1, "quarter");
            return [nextQuarter.startOf("quarter").toDate(), nextQuarter.endOf("quarter").toDate()];
        case TimePeriods.NextSixMonths:
            return [currentDate.toDate(), currentDate.add(6, "months").toDate()];
        case TimePeriods.ThisYear:
            return [currentDate.startOf("year").toDate(), currentDate.endOf("year").toDate()];
        case TimePeriods.Custom:
            return [];
    }
};
