import React, { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Row, Col, Input, Space, Button, InputRef, Modal } from "antd";
import { AuditOutlined, DeleteOutlined, MessageOutlined, SearchOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { formatDateString } from "../../date/DateOp";
import { deleteOrderForm, listOrders } from "../../../common/api/OrderFormClient";
import {
    Filter,
    FilterGroupType,
    FilterOperand,
    Pagination,
    Sort,
    sortOrderTranslate,
} from "../../../types/DataFetchingTypes";
import { getVendors } from "../../../common/api/VendorClient";
import { Vendor } from "../../../types/VendorTypes";
import { getAllOrderTags, getTags } from "../../../common/api/TagClient";
import { ResourceType } from "../../../types/ResourceType";
import { Tag as TagType, TaggedResource } from "../../../types/TagTypes";
import { getMostRecentNotificationsForResource } from "../../../common/api/NotificationsClient";
import {
    MostRecentNotificationForResource,
    NotificationStatus,
} from "../../../types/NotificationTypes";
import { notificationFilterStatus, statusToTag } from "../../notifications/NotificationTranslation";
import { orderFormFilterStatus, orderStatusToTag } from "../OrderStatus";
import { OrderForm } from "../../../types/OrderFormTypes";
import { SortFilter } from "../../../common/CommonInterfaces";
import { handleError } from "../../../common/ErrorHandling";
import { SimulationScore } from "../../dashboard-v2/components/SimulationScore";
import { NotificationModal } from "./NotificationModal";
import { convertTagListToFilter } from "../../tag/TagTranslation";
import { roundToTwoDecimals } from "../../../common/Number";

const { Column } = Table;

interface OrderFormListProps {
    expired?: boolean;
    showNotify?: boolean;
    showDelete?: boolean;
    vendorKeys?: string[];
    refresh?: number;
}

export const OrderFormList: React.FC<OrderFormListProps> = ({
    expired,
    showNotify,
    showDelete,
    vendorKeys = [],
    refresh,
}) => {
    const [data, setData]: any[] = useState([]);
    const [pagination, setPagination] = useState({
        pages: 0,
        current: 1,
        total: 0,
        pageSize: 10,
    } as Pagination);

    const [sortFilter, setSortFilter] = useState({ sort: [], filter: [] } as SortFilter);
    const [loading, setLoading]: any[] = useState(true);
    const [vendors, setVendors] = useState({} as Map<string, Vendor>);
    const [tags, setTags] = useState({} as Map<number, TaggedResource>);
    const [reload, setReload]: any[] = useState(0);
    const [notificationsMap, setNotificationsMap] = useState(
        {} as Map<number, MostRecentNotificationForResource>
    );
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [orderFormId, setOrderFormId] = useState(-1);
    const [notificationFilter, setNotificationFilter] = useState([] as NotificationStatus[]);
    const [tagFilter, setTagFilter] = useState([] as string[]);
    const [allOrderTags, setAllOrderTags] = useState([] as TagType[]);

    const searchRef = createRef<InputRef>();

    const handleTableChange = async (pagination: any, filters_: any, sorter: any) => {
        setPagination(pagination);

        const fs = { filter: [], sort: [] } as SortFilter;

        if (sorter.columnKey !== undefined) {
            fs.sort = [
                { attribute: sorter.columnKey, order: sortOrderTranslate(sorter.order) } as Sort,
            ];
        }
        if (filters_.status) {
            const filters1 = filters_.status.map((v: string) => {
                return {
                    attribute: "status",
                    operand: FilterOperand.EQUALS,
                    value: v,
                } as Filter;
            });

            const groups = {
                groupId: "status",
                groupType: FilterGroupType.OR,
                filter: filters1,
            };

            fs.filter.push(groups);
        }
        if (filters_.name) {
            const names = filters_.name.map((v: string) => {
                return {
                    attribute: "name",
                    operand: FilterOperand.SIMILAR,
                    value: v,
                } as Filter;
            });
            const nameSearch = {
                groupId: "name",
                groupType: FilterGroupType.AND,
                filter: names,
            };
            fs.filter.push(nameSearch);
        }

        if (filters_.notifications) {
            setNotificationFilter(filters_.notifications);
        } else {
            setNotificationFilter([]);
        }

        if (filters_.tags) {
            setTagFilter(filters_.tags);
        } else {
            setTagFilter([]);
        }

        setSortFilter(fs);
        setReload(reload + 1);
    };

    const handleNameSearch = async (_selectedKeys: any, confirm: any) => {
        confirm({ closeDropdown: true });
    };

    const handleReset = (clearFilter: any) => {
        clearFilter();
    };

    const handleDelete = (orderFormId: number) => {
        Modal.confirm({
            title: "Remove Order Form?",
            content: "Are you sure you want to remove the order form?",
            onOk: async () => {
                await deleteOrderForm(orderFormId);
                setReload(reload + 1);
            },
            okText: "Remove",
        });
    };

    const configureNotification = (orderFormId: number) => {
        setOrderFormId(orderFormId);
        setShowNotificationModal(true);
    };

    const getAllTags = async () => {
        const data = await getAllOrderTags(expired);
        setAllOrderTags(data);
    };

    useEffect(() => {
        setReload((pre: number) => pre + 1);
    }, [refresh]);

    useEffect(() => {
        setLoading(true);
        listOrders(
            pagination,
            sortFilter.sort,
            sortFilter.filter,
            expired,
            vendorKeys,
            notificationFilter,
            tagFilter
        )
            .then(async (pager) => {
                if (pager.data.length > 0) {
                    const [vendors, tagged, notifications] = await Promise.all([
                        getVendors(pager.data.map((v) => v.vendorKey)),
                        getTags(
                            pager.data.map((v) => v.id),
                            ResourceType.OrderForm
                        ),
                        getMostRecentNotificationsForResource(
                            pager.data.map((v) => v.id),
                            ResourceType.OrderForm
                        ),
                    ]);
                    const vendorMap = new Map(vendors.map((v) => [v.vendorKey, v]));
                    const _notificationsMap = new Map(
                        notifications.map((v) => [v.resource.resourceId, v])
                    );
                    setNotificationsMap(_notificationsMap);
                    setVendors(vendorMap);
                    setTags(tagged);
                }

                setData(pager.data);
                setPagination(pager.pagination);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setLoading(false);
            });

        getAllTags();
    }, [reload]);

    return (
        <div className="contract-panel" style={{ paddingTop: 10 }}>
            <Row key="contentTable">
                <Col span={24}>
                    <Table
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        rowKey="id"
                        onChange={handleTableChange}
                    >
                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                            sorter={true}
                            render={(value, row: OrderForm) => {
                                return <Link to={"/app/order/" + row.id + ""}>{value}</Link>;
                            }}
                            filterIcon={<SearchOutlined />}
                            onFilter={(_value, _record) => {
                                return true;
                            }}
                            filterDropdown={({
                                setSelectedKeys,
                                selectedKeys,
                                confirm,
                                clearFilters,
                            }) => {
                                return (
                                    <div style={{ padding: 8 }}>
                                        <Input
                                            ref={searchRef}
                                            placeholder={`Search `}
                                            value={selectedKeys[0]}
                                            onChange={(e) =>
                                                setSelectedKeys(
                                                    e.target.value ? [e.target.value] : []
                                                )
                                            }
                                            onPressEnter={() => {
                                                handleNameSearch(selectedKeys, confirm);
                                            }}
                                            style={{ marginBottom: 8, display: "block" }}
                                        />
                                        <Space>
                                            <Button
                                                type="primary"
                                                onClick={() =>
                                                    handleNameSearch(selectedKeys, confirm)
                                                }
                                                icon={<SearchOutlined />}
                                                size="small"
                                                style={{ width: 90 }}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                onClick={() => handleReset(clearFilters)}
                                                size="small"
                                                style={{ width: 90 }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                type="link"
                                                size="small"
                                                onClick={() => {
                                                    confirm({ closeDropdown: true });
                                                }}
                                            >
                                                Close
                                            </Button>
                                        </Space>
                                    </div>
                                );
                            }}
                            onFilterDropdownVisibleChange={(visible) => {
                                if (visible) {
                                    setTimeout(() => searchRef.current?.focus(), 100);
                                }
                            }}
                        />

                        <Column
                            title="Vendor"
                            dataIndex="vendorKey"
                            key="vendorKey"
                            sorter={true}
                            render={(value) => {
                                const vendor = vendors.get(value);
                                return (
                                    <Link to={`/app/vendor/${vendor?.vendorKey}`}>
                                        {vendor?.name}
                                    </Link>
                                );
                            }}
                        />

                        <Column
                            title="Renewal date"
                            dataIndex="expirationDate"
                            key="expirationDate"
                            sorter={true}
                            render={(value) => {
                                return formatDateString(value);
                            }}
                        />
                        <Column
                            title="Value"
                            dataIndex="value"
                            key="value"
                            sorter={true}
                            render={(value) => {
                                return (
                                    <NumberFormat
                                        value={value}
                                        thousandSeparator={true}
                                        displayType={"text"}
                                        prefix={"$"}
                                    />
                                );
                            }}
                        />
                        <Column
                            title="Contract Score"
                            dataIndex="score"
                            key="score"
                            sorter={true}
                            render={(value, row: OrderForm) => {
                                return (
                                    <Space>
                                        <Link to={`/app/order/score/${row.id}`}>
                                            <AuditOutlined />
                                        </Link>
                                        <SimulationScore score={roundToTwoDecimals(value)} />
                                    </Space>
                                );
                            }}
                        />

                        <Column
                            title="Status"
                            dataIndex="status"
                            key="status"
                            filters={orderFormFilterStatus}
                            render={(value) => {
                                return orderStatusToTag(value);
                            }}
                        />

                        <Column
                            title="Notifications"
                            dataIndex="notifications"
                            key="notifications"
                            filters={notificationFilterStatus}
                            render={(_value, row: OrderForm) => {
                                const notification = notificationsMap.get(row.id);
                                if (notification !== undefined) {
                                    return statusToTag(notification.notification.status);
                                }
                                return statusToTag(undefined);
                            }}
                        />

                        <Column
                            title="Tags"
                            dataIndex="tags"
                            key="tags"
                            filters={convertTagListToFilter(allOrderTags)}
                            render={(_value, row: OrderForm) => {
                                return (
                                    <>
                                        {tags.get(row.id)?.tags.map((t, idx) => (
                                            <Tag
                                                key={`${row.id}-${idx}-${t.name}-tags-${row.id}-${t.color}`}
                                                color={t.color}
                                            >
                                                {t.name}
                                            </Tag>
                                        ))}
                                    </>
                                );
                            }}
                        />
                        {showNotify && (
                            <Column
                                dataIndex="id"
                                key="notify"
                                render={(value: number) => {
                                    return (
                                        <Button
                                            type="link"
                                            icon={<MessageOutlined />}
                                            onClick={() => configureNotification(value)}
                                        ></Button>
                                    );
                                }}
                            ></Column>
                        )}
                        {showDelete && (
                            <Column
                                dataIndex="id"
                                key="delete"
                                render={(value: number) => {
                                    return (
                                        <Button
                                            type="link"
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleDelete(value)}
                                        ></Button>
                                    );
                                }}
                            ></Column>
                        )}
                    </Table>
                </Col>
            </Row>
            <NotificationModal
                open={showNotificationModal}
                resourceId={orderFormId}
                onOk={() => setShowNotificationModal(false)}
                onCancel={() => setShowNotificationModal(false)}
            />
        </div>
    );
};
