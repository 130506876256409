import React from "react";
import { Modal } from "antd";
import { Negotiation } from "../../types/NegotiationTypes";
import { deleteNegotiation } from "../../common/api/NegotiationsClient";

interface NegotiationRemoveModalProps {
    open: boolean;
    value?: Negotiation;
    onRemove?: () => void;
    onCancel?: () => void;
}

export const NegotiationRemoveModal: React.FC<NegotiationRemoveModalProps> = ({
    open,
    value,
    onRemove,
    onCancel,
}) => {
    const handleRemove = async () => {
        if (value) {
            await deleteNegotiation(value);
            onRemove && onRemove();
        }
    };
    return (
        <Modal
            open={open}
            title="Remove negotiation?"
            okText="Remove"
            onOk={handleRemove}
            onCancel={onCancel}
        >
            Are you sure you want to remove the negotiation?
        </Modal>
    );
};
