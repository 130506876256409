import React from "react";
import { Col, Row } from "antd";
import { SupplierOverviewCard, SupplierOverviewCardProps } from "./SupplierOverviewCard";

interface SupplierOverviewsContainerProps {
    overviews: SupplierOverviewCardProps[];
}
export const SupplierOverviewsContainer: React.FC<SupplierOverviewsContainerProps> = ({
    overviews,
}) => {
    return (
        <Row gutter={16}>
            {overviews.map((item) => (
                <Col span={8} key={item.title}>
                    <SupplierOverviewCard {...item}></SupplierOverviewCard>
                </Col>
            ))}
        </Row>
    );
};
