import { Button, Card, List, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { PlusSquareOutlined, MinusOutlined, DeleteOutlined } from "@ant-design/icons";
import { getGroups } from "../../common/api/UserClient";
import { UGType } from "../../types/UserGroup";
import { UserGroupSelectionModal } from "../user/UserGroupSelectionModal";
import Avatar from "../../common/components/Avatar";
import { handleError } from "../../common/ErrorHandling";
import {
    addGroupToOrder,
    getOrderGroups,
    removeGroupFromOrder,
} from "../../common/api/OrderFormClient";
interface OrderFormGroupsArgs {
    orderFormId: number;
    disabled?: boolean;
}
export const OrderFormGroups: React.FC<OrderFormGroupsArgs> = ({ orderFormId, disabled }) => {
    const [useRecipients, setUseRecipients] = useState([] as number[]);
    const [members, setMembers] = useState([] as any[]);
    const [showUGSelectionModal, setShowUGSelectionModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!orderFormId) {
            return;
        }
        setLoading(true);

        const fn = async () => {
            const groupIds = await getOrderGroups(orderFormId);
            setUseRecipients(groupIds);
        };

        try {
            fn();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const groups = await getGroups(useRecipients);
                setMembers(groups);
            } catch (error: any) {
                handleError(error);
            }
        })().finally(() => {
            setLoading(false);
        });
    }, [useRecipients]);

    const selectRecipientModal = showUGSelectionModal ? (
        <UserGroupSelectionModal
            blackList={useRecipients.map((v) => {
                return { id: v, type: UGType.GROUP };
            })}
            onSelect={(selection) => {
                const groupId = selection.id;
                const fn = async () => {
                    const added = await addGroupToOrder(orderFormId, [groupId]);
                    setUseRecipients(useRecipients.concat(added.values));
                };
                setLoading(true);
                try {
                    fn();
                } catch (error: any) {
                    handleError(error);
                } finally {
                    setLoading(false);
                    setShowUGSelectionModal(false);
                }
            }}
            onCancel={() => {
                setShowUGSelectionModal(false);
            }}
        />
    ) : (
        <></>
    );

    const removeRecipient = (recipientId: number) => {
        setLoading(true);
        const fn = async () => {
            const removed = await removeGroupFromOrder(orderFormId, [recipientId]);
            const filtered = useRecipients.filter((r) => removed.values.indexOf(r) === -1);
            setUseRecipients(filtered);
        };
        try {
            fn();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
            setShowUGSelectionModal(false);
        }
    };

    const panel = (
        <Skeleton loading={loading}>
            <Card
                size={"small"}
                title={<div>Groups with Access</div>}
                extra={
                    disabled
                        ? [<MinusOutlined />]
                        : [
                              <Button
                                  type={"dashed"}
                                  size={"small"}
                                  onClick={() => {
                                      setShowUGSelectionModal(true);
                                  }}
                              >
                                  <PlusSquareOutlined key="addRecipient" />
                              </Button>,
                          ]
                }
            >
                <List
                    size="small"
                    style={{ minHeight: "350px" }}
                    dataSource={members}
                    renderItem={(item) => {
                        let meta = <></>;
                        if (item.email) {
                            meta = (
                                <List.Item.Meta
                                    avatar={<Avatar email={item.email} />}
                                    title={item.name}
                                    description={item.email}
                                />
                            );
                        } else {
                            meta = (
                                <List.Item.Meta
                                    avatar={<Avatar email={item.name} />}
                                    title={item.name}
                                    description={item.description}
                                />
                            );
                        }
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        disabled={disabled}
                                        size={"small"}
                                        onClick={() => {
                                            removeRecipient(item.id);
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Button>,
                                ]}
                            >
                                {meta}
                            </List.Item>
                        );
                    }}
                ></List>
            </Card>
        </Skeleton>
    );

    return (
        <>
            {selectRecipientModal}
            {panel}
        </>
    );
};
