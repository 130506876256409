import { Group, GroupUpdateRequest, RegisterRequest, User } from "../../types/UserGroup";
import { safeGET, safePOST, safePUT } from "./SafeClient";

export function getMe(abort?: any): Promise<User> {
    return safeGET("/api/user/me", abort);
}

export function getUsers(userIds: number[], abort?: any): Promise<User[]> {
    const idq = userIds.map((v) => `userId[]=${v}`).join("&");
    return safeGET(`/api/user/m?${idq}`, abort);
}

export function listUsers(abort?: any): Promise<User[]> {
    return safeGET(`/api/user`, abort);
}
export function getGroups(groupIds: number[], abort?: any): Promise<Group[]> {
    const idq = groupIds.map((v) => `groupId[]=${v}`).join("&");
    return safeGET(`/api/group/m?${idq}`, abort);
}

export function listGroups(abort?: any): Promise<Group[]> {
    return safeGET(`/api/group`, abort);
}

export function createGroup(group: Group): Promise<Group> {
    return safePOST(`/api/group`, group);
}
export function loadUserGroupSuggestion(
    match: string,
    type: "u" | "g" | "ug",
    abort?: any
): Promise<any> {
    return safeGET(`/api/ug/s?q=${match}&t=${type}`, abort);
}

export function createUser(user: User, abort?: any): Promise<User> {
    return safePOST("/api/user", user, abort);
}

export function updateUser(user: User): Promise<User> {
    return safePUT(`/api/user/${user.id}`, user);
}

export function updateGroup(gur: GroupUpdateRequest, abort?: any): Promise<boolean> {
    return safePUT(`/api/group/${gur.group.id}`, gur, abort);
}

export function getUserInvite(inviteId: string, abort?: any): Promise<User> {
    return safeGET(`/api/user/register/${inviteId}`, abort);
}

export function registerUser(
    inviteId: string,
    request: RegisterRequest,
    abort?: any
): Promise<boolean> {
    return safePUT(`/api/user/register/${inviteId}`, request, abort);
}

export function getOrganization(abort?: any): Promise<any> {
    return safeGET(`/api/org`, abort);
}

export function impersonateUser(userId: number, abort?: any): Promise<any> {
    return safePOST(`/login-as-user/${userId}`, abort);
}
export function impersonateOrgBot(orgId: number, abort?: any): Promise<any> {
    return safePOST(`/login-as-org-bot/${orgId}`, abort);
}
