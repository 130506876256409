import { List, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { getUsers } from "../../../common/api/UserClient";
import { handleError } from "../../../common/ErrorHandling";
import Avatar from "../../../common/components/Avatar";
import { User } from "../../../types/UserGroup";
import { UserGroupSelectionModal } from "../UserGroupSelectionModal";

interface UserLinePanelArgs {
    userId: number;
    enableClickSelect: boolean;
    onSelect(user: User): void;
}

const UserLinePanel: React.FC<UserLinePanelArgs> = ({ userId, enableClickSelect, onSelect }) => {
    const [user, setUser] = useState({} as User);
    const [loading, setLoading] = useState(true);
    const [showSelector, setShowSelector] = useState(false);
    useEffect(() => {
        setLoading(true);
        const fn = async () => {
            try {
                if (userId === undefined) {
                    return;
                }
                const _user = await getUsers([userId]);
                setUser(_user[0]);
            } catch (error: any) {
                handleError(error);
            } finally {
                setLoading(false);
            }
        };
        fn();
    }, [userId]);

    const line = (
        <List.Item.Meta
            avatar={<Avatar email={user.email} />}
            title={user.name}
            description={user.email}
        />
    );
    const content = enableClickSelect ? (
        <a
            href="http://example.com"
            onClick={(e) => {
                setShowSelector(true);
                e.preventDefault();
            }}
        >
            {line}
        </a>
    ) : (
        line
    );

    return (
        <Skeleton loading={loading}>
            {showSelector ? (
                <UserGroupSelectionModal
                    type="u"
                    onSelect={(user: any) => {
                        setUser(user as User);
                        onSelect(user as User);
                        setShowSelector(false);
                    }}
                    onCancel={() => {
                        setShowSelector(false);
                    }}
                />
            ) : (
                ""
            )}
            {content}
        </Skeleton>
    );
};

export default UserLinePanel;
