import { Card, Checkbox, Col, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import {
    getNotificationsSettingsForResource,
    updateNotificationsSettingsForResource,
} from "../../common/api/NotificationsClient";
import { handleError } from "../../common/ErrorHandling";
import { ResourceNotificationSettings } from "../../types/NotificationTypes";
import { Resource } from "../../types/ResourceType";
import { NotificationArgumentInterface } from "./NotificationArgumentInterface";

export const NofiticationResourceSettingsPanel: React.FC<NotificationArgumentInterface> = ({
    resourceId,
    resourceType,
    disabled,
}) => {
    const [resource] = useState({
        resourceId: resourceId,
        resourceType: resourceType,
    } as Resource);
    const [settings, setSettings] = useState({} as ResourceNotificationSettings);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const ac = new AbortController();
        try {
            getNotificationsSettingsForResource(resource)
                .then((settings_) => {
                    setSettings(settings_);
                })
                .catch((error: any) => {
                    handleError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error: any) {
            handleError(error);
            setLoading(false);
        }
        return () => {
            setLoading(false);
            setSettings({});
            ac.abort();
        };
    }, []);

    const doUpdateSettings = (_settings: any) => {
        updateNotificationsSettingsForResource(resource, _settings).then((updatedSettings) => {
            setSettings(updatedSettings);
        });
    };
    return (
        <Skeleton loading={loading}>
            <Card
                size={"small"}
                title={<div>Notifications Settings</div>}
                style={{ minHeight: 378 }}
            >
                <Row gutter={[8, 10]}>
                    <Col span={24}>
                        <Checkbox
                            disabled={disabled}
                            checked={settings.notifyOnMigration}
                            onChange={(e) => {
                                doUpdateSettings({ notifyOnMigration: e.target.checked });
                            }}
                        >
                            migration notification
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            disabled={disabled}
                            checked={settings.notify30Days}
                            onChange={(e) => {
                                doUpdateSettings({ notify30Days: e.target.checked });
                            }}
                        >
                            30 days before expiration
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            disabled={disabled}
                            checked={settings.notify60Days}
                            onChange={(e) => {
                                doUpdateSettings({ notify60Days: e.target.checked });
                            }}
                        >
                            60 days before expiration
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            disabled={disabled}
                            checked={settings.notify90Days}
                            onChange={(e) => {
                                doUpdateSettings({ notify90Days: e.target.checked });
                            }}
                        >
                            90 days before expiration
                        </Checkbox>
                    </Col>

                    <Col span={24}>
                        <hr />
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            disabled={disabled}
                            checked={settings.notifyGroups}
                            onChange={(e) => {
                                doUpdateSettings({ notifyGroups: e.target.checked });
                            }}
                        >
                            Send notification to user groups.
                        </Checkbox>
                    </Col>
                </Row>
            </Card>
        </Skeleton>
    );
};
