import React from "react";
import { Modal } from "antd";
import { NegotiationLeverDetail } from "../../types/NegotiationTypes";
import { deleteLever } from "../../common/api/NegotiationsClient";

interface NegotiationLeverRemoveModalProps {
    value?: NegotiationLeverDetail;
    onRemove?: () => void;
    onCancel?: () => void;
}

export const NegotiationLeverRemoveModal: React.FC<NegotiationLeverRemoveModalProps> = ({
    value,
    onRemove,
    onCancel,
}) => {
    const handleLeverRemove = async () => {
        if (value) {
            await deleteLever(value);
            onRemove && onRemove();
        }
    };
    return (
        <Modal
            visible={true}
            title="Remove lever?"
            okText="Remove"
            onOk={handleLeverRemove}
            onCancel={onCancel}
        >
            Are you sure you want to remove the negotiation lever - {value?.lever.name}?
        </Modal>
    );
};
