import React from "react";
import { Link } from "react-router-dom";
import { BreadcrumbsBar } from "../../../common/components/BreadcrumbsBar";

interface OrderFormScoreHeaderProps {
    orderFormId: number;
    orderFormName: string;
}
export const OrderFormScoreHeader: React.FC<OrderFormScoreHeaderProps> = ({
    orderFormId,
    orderFormName,
}) => {
    const breadcrumbs = [
        {
            name: "Order forms",
            path: "/app/order/new",
        },
        {
            name: orderFormName,
            path: `/app/order/score/${orderFormId}`,
        },
    ];

    return (
        <>
            <BreadcrumbsBar breadcrumbs={breadcrumbs} />
            <div>
                <span>Past contract insights - </span>
                <Link to={`/app/order/${orderFormId}`}>{orderFormName}</Link>
            </div>
        </>
    );
};
