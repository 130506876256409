import React from "react";
import { Col, Row, Space } from "antd";
import { roundToTwoDecimals } from "../../common/Number";

export interface NegotiationDealScoreProps {
    overallScore: number;
    overallGrade: string;
    averageScore: number;
}

export const NegotiationDealScore: React.FC<NegotiationDealScoreProps> = ({
    overallScore,
    overallGrade,
    averageScore,
}) => {
    return (
        <Row style={{ borderRadius: 4, background: "#EBF8FF", padding: "8px 16px", marginTop: 20 }}>
            <Col flex={1}>Deal scorecard</Col>
            <Col>
                <Space size={26}>
                    <div>
                        <span>Highest possible score: </span>
                        <span style={{ color: "#FF7A1A" }}>100%</span>
                    </div>
                    <div>
                        <span>Overall grade: </span>
                        <span style={{ color: "#FF7A1A" }}>
                            {overallScore}% ({overallGrade})
                        </span>
                        <span style={{ color: "#FF7A1A" }}> - Above average by </span>
                        <span style={{ color: "#FF7A1A" }}>
                            {roundToTwoDecimals(overallScore - averageScore)}%
                        </span>
                    </div>
                </Space>
            </Col>
        </Row>
    );
};
