import { UGType } from "./UserGroup";
import { Resource } from "./ResourceType";

export const NotificationType = "Nofitication";

export const DefaultNotificationDays = 30;

export enum NotificationStatus {
    SNOOZED = "snoozed",
    SCHEDULED = "scheduled",
    ACKNOWDELGED = "acknowledged",
    SENT = "sent",
    DISABLED = "disabled",
}

export enum NotificationChannel {
    EMAIL = "email",
    SMS = "SMS",
    EMAIL_AND_SMS = "email_and_sms",
    NONE = "none",
}

export interface NotificationRecipient {
    recipientId: number;
    recipientType: UGType;
}

export interface Notification {
    id: number;
    resource: Resource;
    status: NotificationStatus;
    notificationDate?: Date;
    creationDate: Date;
    nextNotificationDate?: Date;
    enactedBy?: number;
    enactedReason?: string;
    message: string;
    //this is an ugly fix for data augmentation. This property will always come empty from the server
    order?: any;
}

export interface ResourceNotificationSettings {
    canSnooze?: boolean;
    retryHours?: number;
    notifyDays?: number;
    notifyGroups?: boolean;
    notifyOnMigration?: boolean;
    notify30Days?: boolean;
    notify60Days?: boolean;
    notify90Days?: boolean;
}

export enum NofiticationUpdateOperation {
    ADD = "add",
    REMOVE = "remove",
}

export interface UpdateRecipientsRequest {
    operation: NofiticationUpdateOperation;
    recipients: NotificationRecipient[];
}

export interface NotificationForResource {
    settings: ResourceNotificationSettings;
    notifications: Notification[];
    recipients: NotificationRecipient[];
}

export interface UserNotificationPreferences {
    notificationChannel: NotificationChannel;
    phoneNumber: string;
    notificationEmail: string;
}

export type CountMap = {
    [key in NotificationStatus]: number;
};

export interface MostRecentNotificationForResource {
    resource: Resource;
    notification: Notification;
}

export function notificationSorter(a: Notification, b: Notification) {
    if (a.status === b.status) {
        let adate = a.notificationDate;
        let bdate = b.notificationDate;
        if (bdate !== undefined && adate !== undefined) {
            return bdate.getTime() - adate.getTime();
        }
        return 0;
    }
    const statuses = Object.values(NotificationStatus);
    return statuses.indexOf(a.status) - statuses.indexOf(b.status);
}
