import { graphic, EChartsOption } from "echarts";
import { convertNumberToCurrency, formatNumber } from "../../../common/Number";
import { FormattedCumulativeOrderValue } from "../../../types/DashboardTypes";

enum SeriesName {
    WithChange = "Projected renewal - With changes",
    NoAction = "Projected renewal - No action",
    Current = "Current",
}

const getSymbolSize = (value: number, params: any) => {
    if (!value) return 0;
    if (!params.name) return 5;
    return 10;
};

interface MarkLine {
    value: number;
    label: string;
}

export interface CostChartOptionProps {
    xAxisLables: string[];
    withChangeData: number[];
    currentData: number[];
    noActionData: number[];
    markLins: MarkLine[];
    quarterData: FormattedCumulativeOrderValue[];
}

export function getCostChartOption(props: CostChartOptionProps): EChartsOption {
    const lastXAxisLable = [...props.xAxisLables].pop();
    return {
        backgroundColor: "#141E2E",
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
            formatter: (params: any) => {
                if (params.componentType === "markLine") {
                    return "";
                }
                let suffix = "";
                if (params.seriesName !== SeriesName.Current) {
                    const symbol = params.seriesName === SeriesName.WithChange ? "-" : "+";
                    const color =
                        params.seriesName === SeriesName.WithChange ? "#53C41A" : "#F5222D";
                    const diff =
                        params.seriesName === SeriesName.WithChange ? "decrease" : "increase";
                    suffix = `<span style="color:${color};font-weight:bold"> ${symbol}25% ${diff}</span>`;
                } else if (params.name) {
                    const quarter = props.quarterData.find((q) => q.xAxis === params.name);
                    if (quarter) {
                        const pre =
                            props.quarterData.find((q) => q.quarter === quarter.quarter - 1) ||
                            ({
                                cumulativeTotal: 0,
                                cumulativeOrderCount: 0,
                            } as unknown as FormattedCumulativeOrderValue);

                        suffix += `<div>QoQ value change: <span style="color:#52C41A;font-weight:bold">${convertNumberToCurrency(
                            quarter.cumulativeTotal - pre.cumulativeTotal
                        )}</span></div><div>QoQ count change: <span style="color:#52C41A;font-weight:bold">${
                            quarter.cumulativeOrderCount - pre.cumulativeOrderCount
                        }</span></div>`;
                    }
                }

                return `<span style="color:#000">${
                    params.seriesName
                }</span><br /><span style="color:#52C41A;font-weight:bold">${formatNumber(
                    params.value
                )}</span>${suffix}`;
            },
        },
        grid: {
            left: 30,
            right: 110,
            top: 30,
            bottom: 34,
            containLabel: true,
        },
        xAxis: [
            {
                type: "category",
                boundaryGap: false,
                data: props.xAxisLables,
                axisLabel: {
                    color: "#fff",
                    fontWeight: "bold",
                },
                axisLine: {
                    lineStyle: {
                        color: "#385380",
                    },
                },
                axisTick: {
                    show: false,
                },
                name: "Renewal period",
                nameLocation: "middle",
                nameGap: 28,
                nameTextStyle: {
                    color: "#fff",
                    fontWeight: "bold",
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#385380",
                    },
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                name: "Cumulative total cost",
                nameLocation: "middle",
                nameGap: 8,
                nameTextStyle: {
                    color: "#fff",
                    fontWeight: "bold",
                },
            },
        ],
        series: [
            {
                name: SeriesName.WithChange,
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                    width: 3,
                    type: [10, 4],
                },
                symbol: "circle",
                symbolSize: getSymbolSize,
                itemStyle: { color: "#52C41A" },
                areaStyle: {
                    opacity: 0.8,
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "rgba(82, 196, 26, 0.24)",
                        },
                        {
                            offset: 1,
                            color: "rgb(0, 0, 0)",
                        },
                    ]),
                },
                data: props.withChangeData,
            },
            {
                name: SeriesName.Current,
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                    width: 3,
                    color: "#FFB904",
                },
                symbol: "circle",
                symbolSize: getSymbolSize,
                itemStyle: { color: "#FFB904" },
                areaStyle: {
                    opacity: 0.8,
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "rgba(255, 184, 4, 0.24)",
                        },
                        {
                            offset: 1,
                            color: "rgb(0,0,0)",
                        },
                    ]),
                },
                data: props.currentData,
            },
            {
                name: SeriesName.NoAction,
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                    width: 3,
                    type: [10, 4],
                },
                symbol: "circle",
                symbolSize: getSymbolSize,
                itemStyle: {
                    color: "#F03FFF",
                },
                areaStyle: {
                    opacity: 0.8,
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "rgba(243, 116, 254, 0.24)",
                        },
                        {
                            offset: 1,
                            color: "rgb(0, 0, 0)",
                        },
                    ]),
                },
                data: props.noActionData,
            },
            {
                type: "line",
                itemStyle: {
                    color: "#4661A1",
                },
                markLine: {
                    symbol: "none",
                    silent: false,
                    label: {
                        position: "start",
                        offset: [-20, 0],
                        color: "#4661A1",
                        fontWeight: "bold",
                        lineHeight: 18,
                        formatter: (params: any) => {
                            return [
                                `${params.name} Budget`,
                                `{value|${formatNumber(params.value)}}`,
                            ].join("\n");
                        },
                        rich: {
                            value: {
                                color: "#fff",
                                fontWeight: "bolder",
                            },
                        },
                    },
                    data: props.markLins.map((item) => [
                        {
                            name: item.label,
                            x: "10%",
                            yAxis: item.value,
                            value: item.value,
                        },

                        {
                            name: item.label,
                            xAxis: lastXAxisLable,
                            yAxis: item.value,
                            value: item.value,
                        },
                    ]),
                },
            },
        ],
    };
}
