import React from "react";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { BreadcrumbsBar } from "../../common/components/BreadcrumbsBar";

interface SupplierDetailTopPanelProps {
    supplierName: string;
    supplierId: number;
}

export const SupplierDetailTopPanel: React.FC<SupplierDetailTopPanelProps> = ({
    supplierName,
    supplierId,
}) => {
    const breadcrumbs = [
        {
            name: "Buyer briefs",
            path: "/app/supplier",
        },
        {
            name: `${supplierName}`,
            path: `/app/supplier/${supplierId}`,
        },
    ];

    return (
        <div>
            <BreadcrumbsBar breadcrumbs={breadcrumbs} />
            <Link to="/app/supplier">
                <Space style={{ marginTop: 16 }}>
                    <ArrowLeftOutlined />
                    Back to Buyer briefs
                </Space>
            </Link>
        </div>
    );
};
