import React from "react";
import { Avatar } from "antd";

const H_RANGE = [0, 360];
const S_RANGE = [50, 75];
const L_RANGE = [25, 60];

type HSL = [number, number, number];

export function normalizeHash(hash: number, min: number, max: number) {
    return Math.floor((hash % (max - min)) + min);
}

export function generateHSL(name: string): HSL {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, H_RANGE[0], H_RANGE[1]);
    const s = normalizeHash(hash, S_RANGE[0], S_RANGE[1]);
    const l = normalizeHash(hash, L_RANGE[0], L_RANGE[1]);
    return [h, s, l];
}

export function converHsltoString(hsl: HSL) {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
}

export function getHashOfString(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
}

export function getInitials(str: string, separator = " ") {
    return str.split(separator).reduce((pre, cur) => pre + cur[0], "");
}

export function getHslColor(str: string) {
    return converHsltoString(generateHSL(str));
}

interface NameAvatarProps {
    name: string;
}

export const NameAvatar: React.FC<NameAvatarProps> = ({ name }) => {
    return (
        <Avatar
            shape="square"
            style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                backgroundColor: getHslColor(name),
            }}
        >
            {getInitials(name)}
        </Avatar>
    );
};
