import React, { useState } from "react";
import { Button } from "antd";
import { FileMarkdownFilled } from "@ant-design/icons";
import { OrderFormNewModal } from "../OrderFormNewModal";

export const OrderFormCreateButton = (props: {}) => {
    const [showCreationForm, setShowCreationForm] = useState(false);

    return (
        <>
            <OrderFormNewModal
                showForm={showCreationForm}
                onClose={() => setShowCreationForm(false)}
            />
            <Button
                type="primary"
                style={{
                    width: "100%",
                    background: "#fafafa",
                    fontSize: 36,
                    padding: 28,
                    height: "auto",
                }}
                icon={<FileMarkdownFilled style={{ display: "block", lineHeight: 0 }} />}
                onClick={() => {
                    setShowCreationForm(true);
                }}
                block
                ghost
            >
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        marginTop: 8,
                    }}
                >
                    <div>Click here to create individual order forms.</div>
                    <div style={{ fontSize: 12, color: "#666666", whiteSpace: "break-spaces" }}>
                        Manually fill the required order form information. Once filled, you will be
                        redirected to the form for review and/or revision.{" "}
                    </div>
                </div>
            </Button>
        </>
    );
};
