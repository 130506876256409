import { OrderForm, OrderFormStatus } from "../../types/OrderFormTypes";
import React from 'react';
import { Alert, Button, Col, Popover, Row, Steps } from "antd";
import { StopOutlined, FileDoneOutlined, WarningOutlined, ClockCircleOutlined, ScheduleOutlined, CalendarOutlined } from '@ant-design/icons';
import { formatDate, momentDate } from "../date/DateOp";


interface OrderFormStatusPanelArgument {
    order: OrderForm,
    disabled: boolean,
    onUpdate: (OrderFormStatus: OrderFormStatus) => void
}

export const OrderFormStatusPanel: React.FC<OrderFormStatusPanelArgument> = ({ order, disabled, onUpdate }) => {

    const status = order.status;
    const stepStatus = status === OrderFormStatus.WILL_NOT_RENEW ? "error" : "process";
    const step = (status === OrderFormStatus.WILL_NOT_RENEW || status === OrderFormStatus.RENEWED) ? 2 : (status === OrderFormStatus.OVERDUE || status === OrderFormStatus.RENEWAL_SCHEDULED) ? 1 : 0
    const expDate = formatDate(momentDate(new Date(order.expirationDate!)));

    const initialStep = status === OrderFormStatus.EXPIRATION_NOT_SET ? {
        content: <Popover content={<Row style={{ width: 200 }} gutter={[4, 4]}>
            <Col span={24}><Alert
                message="You have not set the expiration date for your order. Please, set your order expiration date to receive order expiration notification and not miss a renewal."
                type="warning"
            />

            </Col>
        </Row>} title={"Order expiration date not set"}><WarningOutlined /></Popover>
    } : {
        content: <Popover content={<Row style={{ width: 200, textAlign: "justify" }} gutter={[4, 4]}>
            <Col span={24}>
                You have set the expiration date for your order. You will receive a notification prior to your renewal date.
            </Col>
        </Row>} title={"Order expiration date set"}><CalendarOutlined /></Popover>
    };
    const scheduled = order.status === OrderFormStatus.EXPIRATION_NOT_SET ? {
        content: <Popover content={<Row style={{ width: 200, textAlign: "justify" }} gutter={[4, 4]}>
            <Col span={24}>
                Actions will be available here once you set a renewal date.
            </Col>
        </Row>} title={"Order renewal"}><ScheduleOutlined /></Popover>
    } : order.status === OrderFormStatus.RENEWAL_SCHEDULED ? {
        content: <Popover content={
            <>
                <Row style={{ width: 250, textAlign: "justify" }} gutter={[4, 4]}>
                    <Col span={24}>
                        Order expires in {expDate}. Please, take an action prior to that date. If you renewed your order, click "Renew". If you will not renew the order click "Will not Renew"
                    </Col>
                    <Col span={24}>
                        <Button disabled={disabled} style={{ width: "100%" }} size={"small"} type="primary" onClick={() => onUpdate(OrderFormStatus.RENEWED)}>Renew</Button>
                    </Col>
                    <Col span={24}>
                        <Button disabled={disabled} style={{ width: "100%" }} size={"small"} danger onClick={() => onUpdate(OrderFormStatus.WILL_NOT_RENEW)}>Will not renew</Button>
                    </Col>
                </Row>
            </>
        } title={"Renewal Scheduled"}><ScheduleOutlined /></Popover>
    } : order.status === OrderFormStatus.OVERDUE ? {
        content: <Popover content={
            <>
                <Row style={{ width: 250, textAlign: "justify" }} gutter={[4, 4]}>
                    <Col span={24}>
                        Order expired in {expDate}. Please, take an action prior to that date. If you renewed your order, click "Renew". If you will not renew the order click "Will not Renew"
                    </Col>
                    <Col span={24}>
                        <Button disabled={disabled} style={{ width: "100%" }} size={"small"} type="primary" onClick={() => onUpdate(OrderFormStatus.RENEWED)}>Renew</Button>
                    </Col>
                    <Col span={24}>
                        <Button disabled={disabled} style={{ width: "100%" }} size={"small"} danger onClick={() => onUpdate(OrderFormStatus.WILL_NOT_RENEW)}>Will not renew</Button>
                    </Col>
                </Row>
            </>
        } title={"Renewal Overdue"}><ClockCircleOutlined /></Popover>
    } : {
        content: <Popover content={<Row style={{ width: 200, textAlign: "justify" }} gutter={[4, 4]}>
            <Col span={24}>
                You have processed this order successfully. No furthe action is required.
            </Col>
        </Row>} title={"Order processed"}><ScheduleOutlined /></Popover>
    }

    const finalStep = status === OrderFormStatus.RENEWED ? {
        content: <Popover content={<Row style={{ width: 200, textAlign: "justify" }} gutter={[4, 4]}>
            <Col span={24}>
                Order successfully renewed.
            </Col>
            <Col span={24}>
                        <Button disabled={disabled} style={{ width: "100%" }} size={"small"} type={"dashed"} danger onClick={() => onUpdate(OrderFormStatus.RENEWAL_SCHEDULED)}>Reset</Button>
                    </Col>
        </Row>} title={"Order processed"}><FileDoneOutlined /></Popover>
    } : status === OrderFormStatus.WILL_NOT_RENEW ? {
        satus: "error",
        text: `This order will not be renewed.`,
        title: "Will not renew",
        content: <Popover content={
            <>
                <Row style={{ width: 200 }} gutter={[4, 4]}>
                    <Col span={24}>
                        You marked this order as not "renewable". If you would like to revert this decision, click the reset button.
                    </Col>
                    <Col span={24}>
                        <Button disabled={disabled} style={{ width: "100%" }} size={"small"} type={"dashed"} danger onClick={() => onUpdate(OrderFormStatus.RENEWAL_SCHEDULED)}>Reset</Button>
                    </Col>
                </Row>
            </>
        } title={"Not renew"}><StopOutlined /></Popover>,
        icon: <StopOutlined />
    } : {
        content: <FileDoneOutlined />
    };


    return <Steps size="small" current={step} status={stepStatus}>
        <Steps.Step icon={initialStep.content} />
        <Steps.Step icon={scheduled.content} />
        <Steps.Step icon={finalStep.content} />
    </Steps>
}
