import { safeGET, safePOST, safePUT } from "./SafeClient";
import { FeatureFlag } from "../../types/FeatureFlagTypes";

export async function getFlags(abort?: any): Promise<FeatureFlag[]> {
    return safeGET(`/api/admin/flag`, abort);
}

export async function createFlag(flag: Omit<FeatureFlag, "id">, abort?: any): Promise<FeatureFlag> {
    return safePOST(`/api/admin/flag/${flag.flagType}`, flag, abort);
}

export async function updateFlag(flag: FeatureFlag, abort?: any): Promise<FeatureFlag> {
    return safePUT(`/api/admin/flag/${flag.flagType}/${flag.id}`, flag, abort);
}
