import { RemoteLocation } from "./DataFetchingTypes";

export interface Document {
    id: number;
    /*
     * document name, usually the upload name
     */
    name: string;
    /**
     * document unique identifier, in this case the sha1
     */
    documentSerialId: string;
    uploadDate: Date;
    objectLocation: RemoteLocation;
    pageCount: number;
    images?: DocumentPageImage[];
}

export interface DocumentArchive {
    id: number;
    filename: string;
    uploadDate: Date;
    uploader: number;
    uploadId: number;
    status: ArchiveStatus;
    files?: DocumentDataInstance[];
}

export enum UploadType {
    PDF = "pdf",
    ZIP = "zip",
}

export enum ArchiveStatus {
    processing = "Processing",
    complete = "Complete",
    failure = "Fail",
}

export interface DocumentPageImage {
    objectLocation: RemoteLocation;
    page: number;
}

export interface AnnotationValue {
    pageNumber: number;
    attributeName: string;
    attributeLabel: string;
    attributeValue: any;
}

export interface AnnotationSKU {
    annotationValues: AnnotationValue[];
}

export interface DocumentDataInstance {
    orderFormId: number;
    documentId: number;
    filename: string;
    creationDate: Date;
    processed: boolean;
    shouldProcess: boolean;
    /**
     file id, usually the file sha1
    **/
    documentSerialId: string;
    /**
     id of the associated archive if any
    **/
    archiveId?: number;
    uploadId?: number;
    attributes?: AnnotationValue[];
    skus?: AnnotationSKU[];
}

export const reviewableAttributes = {
    expirationDate: "date",
    orderStartDate: "date",
    orderEndDate: "date",
    value: "number",
    autoRenewal: "boolean",
    vendorKey: "string",
};

export interface DocumentUpload {
    id: number;
    uploadType: UploadType;
    uploadDate: Date;
}
/**
transient objects
**/
export interface GetUploadDocumentResponse {
    upload: DocumentUpload;
    files: DocumentArchive[] | DocumentDataInstance[];
}

export interface DocumentUploadResponsePart {
    filename: string;
    orderFormId: number;
}

export interface DocumentUploadResponse {
    files: DocumentUploadResponsePart[];
    uploadId: number;
}

export interface DocumentArchiveUploadResponsePart {
    filename: string;
    archiveId: number;
}

export interface DocumentArchiveUploadResponse {
    files: DocumentArchiveUploadResponsePart[];
    uploadId: number;
}

export const DocumentType = "Document";
export const DocumentArchiveType = "DocumentArchive";
export const DocumentPageTranscriptType = "DocumentPageTranscriptType";
export const DocumentDataInstanceType = "DocumentDataInstanceType";
