import { Errors } from "../../types/Error";

// new prod: "https://api.infinityloop.ai/"
const API_URI = process.env.REACT_APP_API_URI || "/";

export function geturl(uripath: string) {
    let updateUri = uripath.replace(/^\//, "");
    return API_URI + updateUri;
}

export async function deserializeAndValidate(response: Response) {
    if (response.status !== 200 && response.status !== 304) {
        let errorBody;
        try {
            errorBody = await response.json();
        } catch (error: any) {
            throw Errors.SERVER_ERROR.createNew(response.statusText);
        }
        if (errorBody.isILError) {
            throw errorBody;
        } else {
            throw Errors.SERVER_ERROR.createNew(JSON.stringify(errorBody));
        }
    }
    return response.json();
}

export async function safeGET<T>(url: string, abort?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        try {
            fetch(geturl(url), { credentials: "include", ...abort })
                .then(deserializeAndValidate)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (error: any) {
            reject(error);
        }
    });
}

function safeOPWithData<T>(url: string, data: any, method: string, abort?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        try {
            fetch(geturl(url), {
                method: method,
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                ...abort,
            })
                .then(deserializeAndValidate)
                .then((obj) => resolve(obj))
                .catch((error) => reject(error));
        } catch (error: any) {
            reject(error);
        }
    });
}

export async function safePOST<T>(url: string, data: any, abort?: any): Promise<T> {
    return safeOPWithData(url, data, "POST", abort);
}
export async function safePUT<T>(url: string, data: any, abort?: any): Promise<T> {
    return safeOPWithData(url, data, "PUT", abort);
}

export async function safeDELETE<T>(url: string, data: any, abort?: any): Promise<T> {
    return safeOPWithData(url, data, "DELETE", abort);
}
