import { FilterGroup, PageRequest, PagerObject, Sort } from "../../types/DataFetchingTypes";
import {
    NegotiationInsigtsType,
    NegotiationLever,
    AverageSimulationScore,
    NegotiationLeverDetail,
    CommonLever,
} from "../../types/NegotiationTypes";
import { Negotiation } from "../../types/NegotiationTypes";
import { TimeQuarter } from "../../types/TimeTypes";
import { safeDELETE, safeGET, safePOST, safePUT } from "./SafeClient";

export function listNegotiations(
    pageRequest: PageRequest,
    sorter: Sort[],
    filter: FilterGroup[],
    quarter?: TimeQuarter,
    vendorKeys?: string[],
    abort?: any
): Promise<PagerObject<Negotiation[]>> {
    const sorting = sorter
        .map((v) => `sort[]=${v.attribute}&order[]=${v.order}`)
        .reduce((p, v) => `${p}&${v}`, "");
    const paging = `current=${pageRequest.current}&pageSize=${pageRequest.pageSize}`;
    const filtering = filter
        .map((v) => {
            return v.filter
                .map((fv) => {
                    return `filter[]=${fv.attribute}&operand[]=${fv.operand}&value[]=${fv.value}&group[]=${v.groupId}&gop[]=${v.groupType}`;
                })
                .join("&");
        })
        .reduce((p, v) => `${p}&${v}`, "");
    let quarterQuery = "";
    if (quarter) {
        quarterQuery = `&year=${quarter.year}&quarter=${quarter.quarter}`;
    }
    let vendorQuery = "";
    if (vendorKeys?.length) {
        vendorQuery = vendorKeys.map((v) => `vendorKey[]=${v}`).reduce((p, v) => `${p}&${v}`, "");
    }
    return safeGET(
        `/api/negotiation?${paging}${sorting}${filtering}${vendorQuery}${quarterQuery}`,
        abort
    );
}

export function getInsights(vendorKeys: string[], abort?: any): Promise<NegotiationInsigtsType> {
    const query = vendorKeys.map((v) => `vendorKey[]=${v}`).reduce((p, v) => `${p}&${v}`, "");
    return safeGET(`/api/negotiation/insights?${query}`, abort);
}

export function createNegotiation(negotiation: Negotiation, abort?: any): Promise<Negotiation> {
    return safePOST("/api/negotiation", negotiation);
}

export function getNegotiation(negotiationId: number, abort?: any): Promise<Negotiation> {
    return safeGET(`/api/negotiation/${negotiationId}`, abort);
}

export function updateNegotiation(negotiation: Negotiation, abort?: any): Promise<Negotiation> {
    return safePUT(`/api/negotiation/${negotiation.id}`, negotiation, abort);
}

export function deleteNegotiation(negotiation: Negotiation, abort?: any): Promise<Negotiation> {
    return safeDELETE(`/api/negotiation/${negotiation.id}`, negotiation, abort);
}

export function getLevers(negotiationId: number, abort?: any): Promise<NegotiationLeverDetail[]> {
    return safeGET(`/api/negotiation/${negotiationId}/lever`, abort);
}

export function addLever(
    negotiationId: number,
    lever: NegotiationLever,
    abort?: any
): Promise<NegotiationLever> {
    return safePOST(`/api/negotiation/${negotiationId}/lever`, lever, abort);
}

export function updateLever(lever: NegotiationLever, abort?: any): Promise<NegotiationLever> {
    return safePUT(`/api/negotiation/${lever.negotiationId}/lever/${lever.id}`, lever, abort);
}

export function deleteLever(lever: NegotiationLever, abort?: any): Promise<NegotiationLever> {
    return safeDELETE(`/api/negotiation/${lever.negotiationId}/lever/${lever.id}`, lever, abort);
}

export function getAverageSimulationScore(
    negotiationId: number,
    abort?: any
): Promise<AverageSimulationScore> {
    return safeGET(`/api/negotiation/${negotiationId}/lever/average`, abort);
}

export function createNegotiationWithLevers(
    negotiation: Negotiation,
    levers: CommonLever[],
    abort?: any
): Promise<Negotiation> {
    return safePOST("/api/negotiation/with-levers", { negotiation, levers }, abort);
}
