import React from "react";
import { Row, Col } from "antd";
import { VendorSelectInput } from "../vendor/VendorInput";
import { Vendor } from "../../types/VendorTypes";
import { NegotiationInsightsContainer } from "./NegotiationInsightsContainer";

interface NegotiationInsightsProps {
    onSelect?: (vendors: Vendor[]) => void;
    vendors?: Vendor[];
}

export const NegotiationInsights: React.FC<NegotiationInsightsProps> = ({ onSelect, vendors }) => (
    <div>
        <Row align="middle">
            <Col span={18}>Insights - Opportunity</Col>
            <Col span={6}>
                <VendorSelectInput onSelect={onSelect} />
            </Col>
        </Row>
        <NegotiationInsightsContainer vendors={vendors} />
    </div>
);
