import { Button, Col, Popover, Row } from "antd";
import React, { useState } from "react";
import { listNotificationsForUserJointOrders } from "../../common/api/NotificationsClient";
import { Notification, NotificationStatus } from "../../types/NotificationTypes";
import { useUserState } from "../../common/UserContext";
import MainLayout from "../navigation/MainLayout";
import { SortableFilteringTable } from "../../common/components/SortableFilteringTable";
import { Link } from "react-router-dom";
import { notificationFilterStatus, statusToTag } from "./NotificationTranslation";
import { formatDate, momentDate } from "../date/DateOp";
import NumberFormat from "react-number-format";
import { SortFilterPaged } from "../../common/CommonInterfaces";
import {
    CheckCircleOutlined,
    FieldTimeOutlined,
    CalendarOutlined,
} from "@ant-design/icons";
import {
    Filter,
    FilterGroup,
    FilterGroupType,
    FilterOperand,
    Sort,
} from "../../types/DataFetchingTypes";
import { EditSnoozeModal, NotificationAcknowledgeModal, SnoozeModal } from "./NotificationModal";
import { User } from "../../types/UserGroup";

const columns = [
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        filteredValue: [NotificationStatus.SENT, NotificationStatus.SNOOZED],
        filters: notificationFilterStatus,
        sorter: true,
        // align: "center",
        render: (value: any, row: Notification) => {
            const tags = statusToTag(row.status);
            const reason =
                row.enactedReason && row.enactedReason.length > 0
                    ? `Reason: ${row.enactedReason}`
                    : undefined;
            const titleDetails = reason ? <Popover content={reason}>{tags}</Popover> : tags;
            return titleDetails;
        },
    },
    {
        title: "Date",
        dataIndex: "notificationDate",
        key: "notificationDate",
        sorter: true,
        // align: "center",
        render: (value: any, row: Notification) => {
            if (
                row.nextNotificationDate &&
                momentDate(row.nextNotificationDate) !== undefined &&
                momentDate(row.nextNotificationDate)!.isValid()
            ) {
                return formatDate(momentDate(row.nextNotificationDate));
            }
            return formatDate(momentDate(value));
        },
    },
    {
        title: "Message",
        dataIndex: "message",
        key: "message",
        ellipsis: true,
        sorter: true,
        render: (value: any, row: Notification) => {
            return value;
        },
    },
    {
        title: "Order",
        dataIndex: "order",
        key: "order",
        sorter: false,
        render: (value: any, row: Notification) => {
            return (
                <Link to={"/app/order/" + row.order.orderForm.id + ""}>
                    {row.order.orderForm.name}
                </Link>
            );
        },
    },
    {
        title: "Value",
        dataIndex: "value",
        key: "value",
        //align: "center",
        sorter: false,
        render: (value: any, row: Notification) => {
            return (
                <NumberFormat
                    value={row.order.orderForm.value}
                    thousandSeparator={true}
                    displayType={"text"}
                    prefix={"$"}
                />
            );
        },
    },
];

const initialFilter = {
    filter: [
        {
            groupId: "status",
            filter: [
                {
                    attribute: "status",
                    operand: FilterOperand.EQUALS,
                    value: NotificationStatus.SENT,
                } as Filter,
                {
                    attribute: "status",
                    operand: FilterOperand.EQUALS,
                    value: NotificationStatus.SNOOZED,
                } as Filter,
            ],
            groupType: FilterGroupType.OR,
        } as FilterGroup,
    ] as FilterGroup[],
    sort: [] as Sort[],
    pager: {
        pagination: {
            current: 1,
            pageSize: 10,
        },
    },
} as SortFilterPaged<Notification>;

type ModalType = "none" | "acknowledge" | "snooze" | "edit";

export const NotificationsView: React.FC = () => {
    const { me } = useUserState();
    const [selectedNotification, setSelectedNotification] = useState({} as Notification);
    const [showModal, setShowModal] = useState("none" as ModalType);
    const [reloader, setReloader] = useState(() => () => {});

    const handleAcknowledge = (notification: Notification) => {
        setShowModal("acknowledge");
        setSelectedNotification(notification);
    };

    const handleSnooze = (notification: Notification) => {
        setShowModal("snooze");
        setSelectedNotification(notification);
    };

    const handleReschedule = (notification: Notification) => {
        setShowModal("edit");
        setSelectedNotification(notification);
    };

    const handleUpdate = (notification: Notification | undefined) => {
        setShowModal("none");
        if (reloader !== undefined) {
            reloader();
        }
    };

    const rowAction = {
        title: "",
        dataIndex: "action",
        key: "action",
        sorter: false,
        render: (value: any, row: Notification) => {
            if (row.status === NotificationStatus.SENT) {
                return (
                    <>
                        <Button onClick={() => handleAcknowledge(row)} className={"ant-tag-green"}>
                            <CheckCircleOutlined />
                        </Button>
                        &nbsp;
                        <Button onClick={() => handleSnooze(row)} className={"ant-tag-yellow"}>
                            <FieldTimeOutlined />
                        </Button>
                    </>
                );
            } else if (row.status === NotificationStatus.SNOOZED) {
                return (
                    <Button onClick={() => handleReschedule(row)} className={"ant-tag-orange"}>
                        <CalendarOutlined />
                    </Button>
                );
            }
            return <></>;
        },
    };

    const modal =
        showModal === "acknowledge" ? (
            <NotificationAcknowledgeModal
                notification={selectedNotification}
                user={me as User}
                onComplete={handleUpdate}
            />
        ) : showModal === "snooze" ? (
            <SnoozeModal
                notification={selectedNotification}
                user={me as User}
                onComplete={handleUpdate}
            />
        ) : showModal === "edit" ? (
            <EditSnoozeModal
                notification={selectedNotification}
                user={me as User}
                onComplete={handleUpdate}
            />
        ) : (
            <></>
        );
    const content = (
        <div style={{ paddingTop: "10px" }}>
            {modal}
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <SortableFilteringTable
                        load={listNotificationsForUserJointOrders}
                        columns={columns.concat([rowAction])}
                        startingFilter={initialFilter}
                        onLoad={(data, pager, reload) => {
                            setReloader(() => () => {
                                reload();
                            });
                        }}
                    />
                </Col>
            </Row>
        </div>
    );

    return <MainLayout mainPanel={content} selected="notifications" collapsed={true}></MainLayout>;
};
