import { PagerObject } from "../../types/DataFetchingTypes";
import {
    MostRecentNotificationForResource,
    Notification,
    NotificationRecipient,
    ResourceNotificationSettings,
    UpdateRecipientsRequest,
    UserNotificationPreferences,
} from "../../types/NotificationTypes";
import { Resource, ResourceType } from "../../types/ResourceType";
import { SortFilterPaged } from "../CommonInterfaces";
import { getOrderForms } from "./OrderFormClient";
import { safeDELETE, safeGET, safePOST, safePUT } from "./SafeClient";

export function getMostRecentNotificationsForResource(
    ids: number[],
    type: ResourceType,
    abort?: any
): Promise<MostRecentNotificationForResource[]> {
    const idq = ids.map((v) => `resourceId[]=${v}&resourceType[]=${type}`).join("&");
    return safeGET(`/api/notification/recent?${idq}`, abort);
}

export function listNotificationsForUserJointOrders(
    pager: SortFilterPaged<Notification>,
    abort?: any
): Promise<PagerObject<Notification[]>> {
    return new Promise<PagerObject<Notification[]>>((resolve, reject) => {
        try {
            listNotificationsForUser(pager, abort).then((pagination) => {
                const oids = pagination.data
                    .filter((v) => v.resource.resourceType === ResourceType.OrderForm)
                    .map((v) => v.resource.resourceId);
                if (oids.length === 0) {
                    resolve(pagination);
                    return;
                }
                getOrderForms(oids).then((forms) => {
                    pagination.data.forEach((v) => {
                        v.order = forms.filter((o) => o.orderForm.id === v.resource.resourceId)[0];
                    });
                    resolve(pagination);
                });
            });
        } catch (error: any) {
            reject(error);
        }
    });
}

export function listNotificationsForUser(
    pager: SortFilterPaged<Notification>,
    abort?: any
): Promise<PagerObject<Notification[]>> {
    const sorting = pager.sort.map((v) => `sort[]=${v.attribute}&order[]=${v.order}`).reduce((p, v) => `${p}&${v}`, "");
    const paging = `current=${pager.pager.pagination.current}&pageSize=${pager.pager.pagination.pageSize}`;
    const filtering = pager.filter
        .map((v) => {
            return v.filter
                .map((fv) => {
                    return `filter[]=${fv.attribute}&operand[]=${fv.operand}&value[]=${fv.value}&group[]=${v.groupId}&gop[]=${v.groupType}`;
                })
                .join("&");
        })
        .reduce((p, v) => `${p}&${v}`, "");
    return safeGET(`/api/notification/user/?${paging}${sorting}${filtering}`, abort);
}

export function getNotificationsSettingsForResource(
    resource: Resource,
    abort?: any
): Promise<ResourceNotificationSettings> {
    return safeGET(`/api/notification/${resource.resourceType}/${resource.resourceId}/settings`, abort);
}

export function getRecipientsForResource(resource: Resource, abort?: any): Promise<NotificationRecipient[]> {
    return safeGET(`/api/notification/${resource.resourceType}/${resource.resourceId}/recipients`, abort);
}

export function getNotificationsForResource(resource: Resource, abort?: any): Promise<Notification[]> {
    return safeGET(`/api/notification/${resource.resourceType}/${resource.resourceId}`, abort);
}

export function updateNotificationsSettingsForResource(
    resource: Resource,
    settings: ResourceNotificationSettings,
    abort?: any
): Promise<ResourceNotificationSettings> {
    return safePUT(`/api/notification/${resource.resourceType}/${resource.resourceId}/settings`, settings, abort);
}

export function addNotification(resource: Resource, notification: Notification, abort?: any): Promise<Notification> {
    return safePOST(`/api/notification/${resource.resourceType}/${resource.resourceId}`, notification, abort);
}

export function updateNotification(resource: Resource, notification: Notification, abort?: any): Promise<Notification> {
    return safePUT(`/api/notification/${resource.resourceType}/${resource.resourceId}`, notification, abort);
}

export function deleteNotification(resource: Resource, notification: Notification, abort?: any): Promise<Notification> {
    return safeDELETE(
        `/api/notification/${resource.resourceType}/${resource.resourceId}/${notification.id}`,
        notification,
        abort
    );
}

export function updateNotificationsRecipients(
    resource: Resource,
    recipient: UpdateRecipientsRequest,
    abort?: any
): Promise<NotificationRecipient[]> {
    return safePUT(`/api/notification/${resource.resourceType}/${resource.resourceId}/recipients`, recipient, abort);
}

export function getPreferencesForUser(userId: number): Promise<UserNotificationPreferences> {
    return safeGET(`/api/notification/user/${userId}/preferences`);
}

export function updatePreferencesForUser(
    userId: number,
    preferences: UserNotificationPreferences
): Promise<UserNotificationPreferences> {
    return safePUT(`/api/notification/user/${userId}/preferences`, preferences);
}
