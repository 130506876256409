import { FilePdfFilled, FileZipFilled } from "@ant-design/icons";
import { Col, message, Row, Space, Typography } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import React from "react";
import { geturl } from "../../common/api/SafeClient";
import { CustomDragger } from "../../common/components/Dragger";
import { handleError } from "../../common/ErrorHandling";
import { OrderFormCreateButton } from "./components/OrderFormCreateButton";

interface OrderFormAddTopPanelProps {
    style?: React.CSSProperties;
    onUpload?: (status?: UploadChangeParam<UploadFile>) => void;
    showUpload?: boolean;
}

export const OrderFormAddTopPanel: React.FC<OrderFormAddTopPanelProps> = ({
    style,
    onUpload,
    showUpload = true,
}) => {
    const onChangeUpload = (status: UploadChangeParam<UploadFile>) => {
        if (
            (
                status.fileList.map((value) => {
                    return value.status === "done";
                }) as boolean[]
            ).reduce((prev, current) => {
                return prev && current;
            })
        ) {
            onUpload?.(status);
            message.success("Files successfully uploaded");
            return;
        }

        const file = status.file;
        if (file.error) {
            handleError(
                file.response,
                `Upload failed`,
                <Space direction="vertical">
                    <span style={{ whiteSpace: "nowrap" }}>
                        File: <Typography.Text italic>{file.name}</Typography.Text>
                    </span>
                    <span>
                        Please{" "}
                        <Typography.Link onClick={() => window.location.reload()}>
                            refresh the page
                        </Typography.Link>{" "}
                        and try again.
                    </span>
                </Space>,
                { width: "auto" }
            );
        }
    };

    const uploadPDF = (
        <CustomDragger
            accept=".pdf"
            multiple={true}
            maxCount={10}
            action={geturl("/api/orderform/pdf")}
            showUploadList={false}
            onChange={onChangeUpload}
            icon={<FilePdfFilled />}
            title="Click or drag PDF order forms."
            description="Files will be analyzed by our automated form detection algorithms and validated by our staff."
            withCredentials
        ></CustomDragger>
    );

    const bulkUpload = (
        <CustomDragger
            accept=".zip"
            multiple={true}
            maxCount={10}
            action={geturl("/api/orderform/zip")}
            showUploadList={false}
            onChange={onChangeUpload}
            icon={<FileZipFilled />}
            title="
                    Click or drag ZIP files containing multiple PDF order forms.
                "
            description="
                    Files will be analyzed by our automated form detection algorithms and validated
                    by our staff.
                "
            withCredentials
        ></CustomDragger>
    );
    const actions = [
        {
            name: "uploadPDF",
            action: uploadPDF,
            isUpload: true,
        },
        {
            name: "bulkUpload",
            action: bulkUpload,
            isUpload: true,
        },
        {
            name: "createButton",
            action: <OrderFormCreateButton />,
            isUpload: false,
        },
    ];
    const filteredActions = showUpload ? actions : actions.filter((item) => !item.isUpload);

    const span = 24 / filteredActions.length;
    return (
        <Row gutter={16} style={style}>
            {filteredActions.map((item) => (
                <Col span={span} key={item.name}>
                    {item.action}
                </Col>
            ))}
        </Row>
    );
};
